import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import Tv from '../studio-pautty/Tv';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftShort, BsCheckCircleFill, BsRepeat, BsShare } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import ButtonShared from '../utils/ButtonShared';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { decryptData, encryptData } from '../crypto/pauttyCrypro';

const Pauta = () => {
  // es dispositivo movil
  // const [isDispositivoMovil] = useState(/Mobi/.test(navigator.userAgent));
    let { post } = useParams();
    const [itemPostScreen, setItemPostScreen] = useState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [statusPauta, setStatusPauta] = useState(searchParams.get("status"));
    const [fullScreenStatus, setFullScreenStatus] = useState(false);


    useEffect(() => {
      getPostScreensById();
    }, []);

    const getPostScreensById = () => {
      setLoading(true);
      let dataHeaderCrypto = encryptData('/api/v1/post-screen/' + post);
      fetch(config.urlApi + '/api/v1/post-screen/' + post, {
        method: "GET",
        headers: {
          'X-Api-Pautty-0': dataHeaderCrypto[0],
          'X-Api-Pautty-1': dataHeaderCrypto[1]
        }
      })
        .then((response) => response.json())
        .then(data => {
          var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
          dataPayload = JSON.parse(dataPayload);
          if (dataPayload.status === 404) {
            showToastError(dataPayload.message);
            navigate('/not-found/404');
          } else {
            if (!dataPayload.status) {
              setItemPostScreen(dataPayload);
              setLoading(false);
            } else {
              showToastError(dataPayload.message);
            }
          }
        })
        .catch(e => {
          showToastError("Inténtalo más tarde. ");
          setLoading(true);
        });
    }

    const [showModalSharing, setShowModalSharing] = useState(false);
    const [idPautaSharingCurrent, setIdPautaSharingCurrent] = useState();
    const [titlePautaSharingCurrent, setTitlePautaSharingCurrent] = useState("");

    const handleOpenModalSharing = (id, title_pauta) => {
      setIdPautaSharingCurrent(id);
      setTitlePautaSharingCurrent(title_pauta);
      setShowModalSharing(true)
    };

    const handleCloseModalSharing = () => setShowModalSharing(false);

    const showToastError = (error) => {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 5000,
        rtl: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    }

    const toggleFullScreen = (elem, status) => {
      setFullScreenStatus(status);

      if (status) {
        if (elem.requestFullScreen) {
          elem.requestFullScreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }


    const StatusComponent = ({status_id}) => {
      
      let animatedStyle = 'animate__animated animate__backInLeft';
      return   <div className='tabs-menu-medios tabs-menu-medios-pautas scroll-status-pauta' style={{ width: window.screen.width-20 }}>

                  <label className={status_id==='0'?'active ':''+animatedStyle}>
                      {parseInt(status_id)>0&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      {config.list_status_pauta[0].name}
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='1'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)>1&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      {config.list_status_pauta[1].name}
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='2'?'active '+animatedStyle:''+animatedStyle}>
                    {parseInt(status_id)>2&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      {config.list_status_pauta[2].name}
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='3'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)>3&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      {config.list_status_pauta[3].name}
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='4'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)>4&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      {config.list_status_pauta[4].name}
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='5'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)>5&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      {config.list_status_pauta[5].name}
                      {' '}<FaLongArrowAltRight />
                    </label>  
                    <label className={status_id==='6'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)===6&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      {config.list_status_pauta[6].name}
                      {' '}<FaLongArrowAltRight />
                    </label>                  
                    <label className={status_id==='7'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)===7&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      {config.list_status_pauta[7].name}
                    </label>                  
              </div>
    }
   
    return (
        <div style={{ background: '#C8C7C7', minHeight: '100vh', justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>


          {statusPauta==='true'&&
            <button className="top-to-breadcrumb-show" onClick={() => {
                    navigate(-1);
                }}>
                <BsArrowLeftShort size={25} />
            </button>
          }


                      
          {itemPostScreen ?
              <div style={{ margin: 'auto', position: 'relative' }} onClick={()=>toggleFullScreen(document.body, !fullScreenStatus)}>
                  
                {/* <Tv preview={true} template={itemPostScreen.option_style} elements={itemPostScreen.elements_data} itemPostScreen={itemPostScreen} widthPreview={itemPostScreen.width_screen_active} heightPreview={itemPostScreen.height_screen_active} ></Tv> */}
                <Tv preview={true} template={itemPostScreen.option_style} elements={itemPostScreen.elements_data} itemPostScreen={itemPostScreen} widthPreview={window.screen.width} heightPreview={window.screen.height} isMovilView={/Mobi/.test(navigator.userAgent)}></Tv>
              </div>
            :
            <SpinnerLoadingFixed />
          }
          
          
          {(!loading && statusPauta==='true') &&
              <button onClick={()=>handleOpenModalSharing(itemPostScreen.post_screen_id, itemPostScreen.name)} style={{ color: '#292929', background: '#eaeaea', position: 'absolute', right: 10, top: 10, zIndex: 9999 }} className='btn btn-default button-top-editor'> Compartir {' '} <BsShare color='#292929' size={25} /> </button>
            }
          

          {itemPostScreen &&
              <div>
                {statusPauta==='true'&&
                    <div className='show-status-pauta'>

                      <div className='content-update-status-button'>
                        <button onClick={()=>getPostScreensById()}><BsRepeat /> Actualizar</button>
                      </div>

                      <StatusComponent status_id={itemPostScreen.status_pauta}  />
                    </div>
                  }
              </div>      
          }

          <Modal
            show={showModalSharing}
            onHide={handleCloseModalSharing}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Compartir en redes sociales</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ButtonShared id_link={idPautaSharingCurrent} title={titlePautaSharingCurrent} type_shared={'pauta'} />
            </Modal.Body>
            </Modal>


          <ToastContainer />
        </div>
    )
}

export default Pauta;