import React, { useState, useEffect, useRef } from 'react';
import { config } from '../configPautty';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from '../crypto/pauttyCrypro';

const Confirm = () => {
  const [cookies, setCookies] = useCookies(['user']);
  const [phone, setPhone] = useState(cookies.phone);
  const [countryCodePhone] = useState(cookies.countryCode);
  const [typeLogin] = useState(cookies.typeLogin);
  const [counter, setCounter] = useState(cookies.SecondsOTP);
  const [countAttempt, setCountAttempt] = useState(1);
  const focusValue1 = useRef(null);
  const focusValue2 = useRef(null);
  const focusValue3 = useRef(null);
  const focusValue4 = useRef(null);
  const [value1, setSetValue1] = useState("");
  const [value2, setSetValue2] = useState("");
  const [value3, setSetValue3] = useState("");
  const [value4, setSetValue4] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.access_token !== undefined) {
      navigate('/');
    }
    if (focusValue1) {
      focusValue1.current.focus(); 
    }
  }, [cookies, navigate]);

  const showToastError = (error) => {
    if (focusValue1) {
      focusValue1.current.focus(); 
    }
    setSetValue1('');setSetValue2('');setSetValue3('');setSetValue4('');
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      rtl: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    });
  }

  const handleChange1 = (event) => {
    if (event.target.value.length <= 1) {
      setSetValue1(event.target.value);
      focusValue2.current.focus();
    }
  }
  const handleChange2 = (event) => {
    if (event.target.value.length <= 1) {
      setSetValue2(event.target.value);
      focusValue3.current.focus();
    }
  }
  const handleChange3 = (event) => {
    if (event.target.value.length <= 1) {
      setSetValue3(event.target.value);
      focusValue4.current.focus();
    }
  }
  const handleChange4 = (event) => {
    if (event.target.value.length <= 1) {
      setSetValue4(event.target.value);
    }
  }

  const handleChangeOnKey = (event) => {
    if ((value1 !== undefined && value2 !== undefined && value3 !== undefined && value4 !== undefined) && (value1 !== '' && value2 !== '' && value3 !== '' && value4 !== '')) {
      handleSubmitLoginConfirm(event);
    }
  }

  const handleSubmitLoginConfirm = event => {
    event.preventDefault();
    setPhone(cookies.phone);
    setDisabledButton(true);
    setSpinnerLoading(true);

    let dataConfirm = { 
      phone: cookies.phone,
      country: cookies.countryCode, 
      type_login: cookies.typeLogin, 
      code_active: value1+""+value2+""+value3+""+value4
    }

    let dataHeaderCrypto = encryptData('/api/v1/confirm-login/', JSON.stringify(dataConfirm));

    fetch(config.urlApi + '/api/v1/confirm-login/', {
      method: 'POST',
      headers: {
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      },
      body: JSON.stringify({payload: dataHeaderCrypto[2]})
    })
      .then((response) => response.json())
      .then(data => {
        var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
        dataPayload = JSON.parse(dataPayload);
        if (dataPayload.status >= 400) {
          showToastError(dataPayload.message);
          setSpinnerLoading(false);
          setCountAttempt(countAttempt+1);
          if(countAttempt===3){
            navigate('/login');
          }
        } else {
          var today = new Date();
          var expire = new Date(today.getTime() + 90 * 24 * 3600 * 7000);
          setCookies('access_token', dataPayload.access_token, { path: '/', expires: expire });
          setCookies('phone', dataPayload.phone, { path: '/', expires: expire });
          setCookies('username', dataPayload.username, { path: '/', expires: expire });
          setCookies('name', dataPayload.name, { path: '/', expires: expire });
          setCookies('last_name', dataPayload.last_name, { path: '/', expires: expire });
          setCookies('url_photo', 'https://statics-gridwel.s3.us-east-2.amazonaws.com/'+dataPayload.url_photo, { path: '/', expires: expire });

          setCookies('web', dataPayload.web, { path: '/', expires: expire });
          setCookies('facebook', dataPayload.facebook, { path: '/', expires: expire });
          setCookies('whatsapp', dataPayload.whatsapp, { path: '/', expires: expire });
          setCookies('instagram', dataPayload.instagram, { path: '/', expires: expire });
          setCookies('twitter', dataPayload.twitter, { path: '/', expires: expire });
          setCookies('tiktok', dataPayload.tiktok, { path: '/', expires: expire });
          setCookies('youtube', dataPayload.youtube, { path: '/', expires: expire });
          setCookies('tab_current', 'influencer', { path: '/', expires: expire });
          setCookies('tab_nav_bussiness', 'person', { path: '/' });

            if (cookies.pathBefore!=='' && cookies.pathBefore!==undefined) {
              navigate(cookies.pathBefore);
            }else{
              navigate('/');
            }
        }
      })
      .catch(e => {
        // showToastError("Inténtalo más tarde. "+e);
        setSpinnerLoading(false);
        return e;
      });
  }

  useEffect(() => {
    if (cookies.phone === undefined) {
      navigate('/login');
    }

    const intervalTimeSeconds = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 0.5);
        setCookies('SecondsOTP', counter, { path: '/' });
      } else {
        clearInterval(intervalTimeSeconds);
        navigate('/login');
      }
    }, 500);
    if ((value1 !== undefined && value2 !== undefined && value3 !== undefined && value4 !== undefined) && (value1 !== '' && value2 !== '' && value3 !== '' && value4 !== '')) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
    return () => clearInterval(intervalTimeSeconds);
  }, [counter, setCookies, setCounter, value1, value2, value3, value4, navigate, cookies]);

  return (<div className="content-login">
    <div className="capa-fondo"></div>
    <div className="login-wrapper d-flex align-items-center justify-content-center text-center login-form">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-9 col-md-7">
            <div className="text-start px-4">
              <h5 className="mb-1 text-login-title text-center">Validación</h5>
              <p className="mb-4 text-center">Queremos validar tu identidad. Intentos {4-countAttempt}</p>
            </div>

            <div className="otp-verify-form mt-5 px-4">
              <form onSubmit={handleSubmitLoginConfirm}>
                <div className="d-flex justify-content-between mb-5">
                  <input onChange={handleChange1} value={value1} ref={focusValue1} onKeyUp={handleChangeOnKey} className="single-otp-input form-control" style={{ textAlign: "center" }} type="number" placeholder="-" max={9} maxLength={1} />
                  <input onChange={handleChange2} value={value2} ref={focusValue2} onKeyUp={handleChangeOnKey} className="single-otp-input form-control" style={{ textAlign: "center" }} type="number" placeholder="-" max={9} maxLength={1} />
                  <input onChange={handleChange3} value={value3} ref={focusValue3} onKeyUp={handleChangeOnKey} className="single-otp-input form-control" style={{ textAlign: "center" }} type="number" placeholder="-" max={9} maxLength={1} />
                  <input onChange={handleChange4} value={value4} ref={focusValue4} onKeyUp={handleChangeOnKey} className="single-otp-input form-control" style={{ textAlign: "center" }} type="number" placeholder="-" max={9} maxLength={1} />
                </div>
                <button id="doLoginConfirmUser" disabled={disabledButton} className="btn btn-default color-bottons btn-lg w-100" type="submit">
                  Validar
                  {spinnerLoading &&
                    <div className="d-flex justify-content-center" style={{ float: 'right', paddingTop: 7 }}>
                      <span className="spinner-border spinner-border-sm button-sppiner-pauty" role="status" aria-hidden="true"></span>
                    </div>
                  }
                </button>
              </form>
            </div>

            <div className="login-meta-data px-4">
              <input type="hidden" value="" id="number_phone_confirm" />
              {typeLogin==='email'?
                <p className="mt-3 mb-0">Ingresa el código enviado a tu correo {phone} antes de <span className="otp-sec ms-1 text-color-pauty" id="resendOTP">{parseInt(counter)} seg</span></p>
                :
                <p className="mt-3 mb-0">Ingresa el código enviado al número +{countryCodePhone}{phone} antes de <span className="otp-sec ms-1 text-color-pauty" id="resendOTP">{parseInt(counter)} seg</span></p>
              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToastContainer />
  </div>

  )
}
export default Confirm;