import React, { useState, useEffect, useRef } from 'react';
import { config } from '../configPautty';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import mapboxgl from 'mapbox-gl';
import { BiCurrentLocation } from 'react-icons/bi';
import HeaderBack from '../general/HeaderBack';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCookies } from '../deleteCookies';
import { Form } from 'react-bootstrap';
import {  FcMoneyTransfer, FcUpload } from 'react-icons/fc';
import { BsFacebook, BsInstagram, BsLine, BsLink45Deg, BsLinkedin, BsPencilFill, BsFillTelephoneFill, BsPinterest, BsPlus, BsSnapchat, BsTelegram, BsTrashFill, BsTwitterX, BsWhatsapp, BsYoutube, BsSpotify } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import { decryptData, encryptData } from '../crypto/pauttyCrypro';
import { AiFillCheckCircle } from 'react-icons/ai';
import { cleanUrlWeb, cleanUrlWeb2 } from '../utils/CleanUrl';

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX;

const UpdateValla = () => {
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();
    let { screen } = useParams();
    const [disabledButton, setDisabledButton] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [spinnerLoadingMap, setSpinnerLoadingMap] = useState(false);

    const [buttonOpenNewProgram, setButtonOpenNewProgram] = useState(false);
    const [buttonOpenNewProgramUpdate, setButtonOpenNewProgramUpdate] = useState(false);

    const [nameElementScreen, setNameElementScreen] = useState('');
    const [elementScreenFollowers, setElementScreenFollowers] = useState(0);
    const [elementScreenPrice, setElementScreenPrice] = useState(0);
    const [elementScreenHorario, setElementScreenHorario] = useState('');
    const [elementScreenHorarioHasta, setElementScreenHorarioHasta] = useState('');

    const [idElementScreenUpdate, setIdElementScreenUpdate] = useState();
    const [nameElementScreenUpdate, setNameElementScreenUpdate] = useState('');
    const [elementScreenFollowersUpdate, setElementScreenFollowersUpdate] = useState(0);
    const [elementScreenPriceUpdate, setElementScreenPriceUpdate] = useState(0);
    const [elementScreenHorarioUpdate, setElementScreenHorarioUpdate] = useState('');
    const [elementScreenHorarioHastaUpdate, setElementScreenHorarioHastaUpdate] = useState('');
    
    const [nameSocialLink, setNameSocialLink] = useState('');
    const [nameSocialMedia, setNameSocialMedia] = useState('');

    const [nameSocialLinkUpdate, setNameSocialLinkUpdate] = useState('');
    const [nameSocialMediaUpdate, setNameSocialMediaUpdate] = useState('');

    const [urlUsernameSocial, setUrlUsernameSocial] = useState('');
    
    const [heightMap, setHeightMap] = useState(400);

    const handleChangeNameSocialMedia = (value) => {
        setNameSocialLink(value);
    }

    const handleChangeNameSocialMediaUpdate = (value) => {
        setNameSocialLinkUpdate(value);
    }

    const handleChangeUrlUsernameSocial = (value) => {
        setUrlUsernameSocial(value);
    }

    const handleOpenNewProgram = () => {
        setButtonOpenNewProgram(true);
    }

    const handleOpenNewProgramUpdate = (itemElement) => {
        setButtonOpenNewProgramUpdate(true);
        setIdElementScreenUpdate(itemElement.element_screen_id);
        setNameElementScreenUpdate(itemElement.name);
        setElementScreenHorarioUpdate(itemElement.horario);
        setElementScreenHorarioHastaUpdate(itemElement.horario_fin);
        setElementScreenFollowersUpdate(itemElement.followers);
        setElementScreenPriceUpdate(itemElement.price);
        setNameSocialLinkUpdate(itemElement.url);
        handleChangeNameSocialMediaUpdate(itemElement.description);
        handleChangeUrlUsernameSocial(config.list_links_social_media.find(item => item.name === itemElement.url).urlWeb);
        setNameSocialMediaUpdate(itemElement.description);
    }

    const handleCloseOpenNewProgram = () => {
        setButtonOpenNewProgram(false);
    }

    const handleCloseOpenNewProgramUpdate = () => {
        setButtonOpenNewProgramUpdate(false);
    }

    const handleChangeNameElementScreen = (event) => {
                
        var urlToDelete = config.list_links_social_media.find(item => cleanUrlWeb(event.target.value).includes(cleanUrlWeb(item.urlWeb)));
        
        if (urlToDelete !== undefined) {
            if (urlToDelete.name!=="Web") {
                setNameSocialMedia(urlToDelete.name);
                setNameSocialLink(urlToDelete.name);
                handleChangeUrlUsernameSocial(urlToDelete.urlWeb);  
                urlToDelete = urlToDelete.urlWeb;
                var tempUsername = event.target.value;
                tempUsername = cleanUrlWeb2(tempUsername).replace(cleanUrlWeb2(urlToDelete), "");
                setNameElementScreen(tempUsername);
                return
            }
        }
        setNameElementScreen(event.target.value);
    }

    const handleChangeHorarioElementScreen = (event) => {
        setElementScreenHorario(event.target.value);
    }
    const handleChangeHorarioFinElementScreen = (event) => {
        setElementScreenHorarioHasta(event.target.value);
    }

    const handleChangeNameElementScreenFollowers = (event) => {
        setElementScreenFollowers(event.target.value);
    }

    const handleChangeNameElementScreenPrice = (event) => {
        setElementScreenPrice(event.target.value);
    }

    
    const handleChangeNameElementScreenUpdate = (event) => {
        var urlToDelete = config.list_links_social_media.find(item => cleanUrlWeb(event.target.value).includes(cleanUrlWeb(item.urlWeb)));
        if (urlToDelete !== undefined) {
            if (urlToDelete.name!=="Web") {
                setNameSocialMediaUpdate(urlToDelete.name);
                setNameSocialLinkUpdate(urlToDelete.name);
                handleChangeUrlUsernameSocial(urlToDelete.urlWeb);  
                urlToDelete = urlToDelete.urlWeb;
                var tempUsername = event.target.value;
                tempUsername = cleanUrlWeb2(tempUsername).replace(cleanUrlWeb2(urlToDelete), "");
                setNameElementScreenUpdate(tempUsername);
                return
            }
        }
        setNameElementScreenUpdate(event.target.value);
    }

    const handleChangeHorarioElementScreenUpdate = (event) => {
        setElementScreenHorarioUpdate(event.target.value);
    }
    const handleChangeHorarioFinElementScreenUpdate = (event) => {
        setElementScreenHorarioHastaUpdate(event.target.value);
    }

    const handleChangeNameElementScreenFollowersUpdate = (event) => {
        setElementScreenFollowersUpdate(event.target.value);
    }

    const handleChangeNameElementScreenPriceUpdate = (event) => {
        setElementScreenPriceUpdate(event.target.value);
    }

    const map = useRef(null);

    // const [mapNewScreen, setMapNewScreen] = useState(null);
    const [markerLocationValla] = useState(new mapboxgl.Marker({ "color": "#f52d3f" }));

    const [status, setStatus] = useState(null);

    const [valla, setValla] = useState();

    useEffect(() => {
        getScreensById();
        return () => null;
    }, []);

    const getLocation = () => {
        if (valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') {
            setSpinnerLoadingMap(true);
        if (!navigator.geolocation) {
            setStatus('Geolocation is not supported by your browser');
                } else {
                    setStatus('Locating...');
                    markedPointScreen();
                    try {
                        navigator.geolocation.getCurrentPosition((position) => {
                            setStatus(null);
                            markerLocationValla.setLngLat([position.coords.longitude, position.coords.latitude]).addTo(map.current);
                            setPositionMap(position.coords.longitude, position.coords.latitude);
                            setSpinnerLoadingMap(false);
                        }, (err) => {
                            setStatus('Unable to retrieve your location: ' + err.message);
                            setSpinnerLoadingMap(false);
                        });
                    } catch (error) {
                        setSpinnerLoadingMap(false);
                        alert(error+" - "+status);
                    }

                }   
        }
    }

    const [countryCode, setCountryCode] = useState('57');

    const handleChangeNameValla = (event) => {
        setValla(valla => ({
            ...valla,
            name: event.target.value
        }));
    }

    const handleUsernameInfluencer = (event) => {
        var regex = /^[A-Za-z0-9._]+$/;
        if (event.target.value.length === 0 || regex.test(event.target.value)) {
            setValla(valla => ({
                ...valla,
                username: event.target.value
            }));   
        }
    }

    const handleChangeTypeValla = (event) => {
        setValla(valla => ({
            ...valla,
            type_business: event.target.value
        }));
    }
    const handleChangePriceValla = (event) => {
        setValla(valla => ({
            ...valla,
            price: event.target.value
        }));
    }
    const handleChangeTimePrice = (event) => {
        setValla(valla => ({
            ...valla,
            time_price: event.target.value
        }));
    }

    const handleChangePriceValla_2 = (event) => {
        setValla(valla => ({
            ...valla,
            price_2: event.target.value
        }));
    }
    const handleChangeTimePrice_2 = (event) => {
        setValla(valla => ({
            ...valla,
            time_price_2: event.target.value
        }));
    }

    const handleChangeNumberWhatsapp = (event) => {
        let sinEspacios = event.target.value.replace(/\s+/g, '');
        // if (userPremiumMessage) {
            setValla(valla => ({
                ...valla,
                number_whatsapp: sinEspacios
            }));    
        // }        
    }

    const handleChangePhoneNumber = (event) => {
        let sinEspacios = event.target.value.replace(/\s+/g, '');
        setValla(valla => ({
            ...valla,
            phone_number: sinEspacios
        }));    
    }

    const handleChangeCountry = (event) => {
        setCountryCode(event.target.value);
    }
    const handleChangeMessageWhatsapp = (event) => {
        setValla(valla => ({
            ...valla,
            message_whatsapp: event.target.value
        }));
    }
    const handleChangeIsPublicityValla = (event) => {
        setValla(valla => ({
            ...valla,
            is_publicity: event.target.checked
        }));
    }

    const handleChangeIsApprovalAutomatic = (event) => {
        setValla(valla => ({
            ...valla,
            is_approval_automatic: event.target.checked
        }));
    }

    const handleChangeIsPaymentValla = (event) => {
        setValla(valla => ({
            ...valla,
            is_payment: event.target.checked
        }));
    }

    const handleChangeAvailableValla = (event) => {
        setValla(valla => ({
            ...valla,
            available: event.target.checked
        }));
    }
    const handleChangeHeightValla = (event) => {
        setValla(valla => ({
            ...valla,
            height_screen_active: event.target.value
        }));
    }
    const handleChangeWidthValla = (idDimension) => {
        setValla(valla => ({
            ...valla,
            width_screen_active: idDimension
        }));
    }
    const handleChangeSizePosteValla = (event) => {
        setValla(valla => ({
            ...valla,
            poste_height: event.target.value
        }));
    }
    const handleChangeNumberFacesValla = (event) => {
        setValla(valla => ({
            ...valla,
            number_faces_valla: event.target.value
        }));
    }
    const handleChangeAudiencia = (event) => {
        setValla(valla => ({
            ...valla,
            audiencia: event.target.value
        }));
    }
    const handleChangeChannel = (event) => {
        setValla(valla => ({
            ...valla,
            channel_tv: event.target.value
        }));
    }
    const handleChangeAddressLocation = (event) => {
        setValla(valla => ({
            ...valla,
            address_location: event.target.value
        }));
    }
    const handleChangeUrlPage = (event) => {
        setValla(valla => ({
            ...valla,
            url_page: event.target.value
        }));
    }
    const handleChangePeoplePass = (event) => {
        setValla(valla => ({
            ...valla,
            people_pass: event.target.value
        }));
    }
    const handleChangeOwnerCompany = (event) => {
        setValla(valla => ({
            ...valla,
            owner_company: event.target.value
        }));
    }
    

    const handleChangeFrecuencyRadio = (event) => {
        setValla(valla => ({
            ...valla,
            frecuency_radio: event.target.value
        }));
    }
    
    const handleChangePrograma = (event) => {
        setValla(valla => ({
            ...valla,
            programa: event.target.value
        }));
    }

    const [addButtonSleepModalElementScreen, setaddButtonSleepModalElementScreen] = useState(false);

    const handleSubmitAddElementScreen = event => {
        event.preventDefault();
        if (nameElementScreen.length===0) {
            showToastError("Debes completar los campos requeridos");
            return
        }
        setaddButtonSleepModalElementScreen(true);

        let objectElement = {
            name: nameElementScreen,
            description: nameSocialMedia,
            url: nameSocialLink,
            name_icon: '',
            color: '',
            horario: elementScreenHorario,
            horario_fin: elementScreenHorarioHasta,
            followers: elementScreenFollowers+"",
            screen_id: valla.screen_id
        }

        let dataHeaderCrypto = encryptData('/api/v1/element-screen/', JSON.stringify(objectElement));
        fetch(config.urlApi + '/api/v1/element-screen/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            },
            body: JSON.stringify({payload: dataHeaderCrypto[2]})
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status===201) {
                    showToastSuccess(data.message);
                    getElementScreen(valla.screen_id);   
                    setNameElementScreen('');
                    setNameSocialLink('');
                    setElementScreenHorario('');
                    setElementScreenHorarioHasta('');
                    setElementScreenFollowers(0);
                    setElementScreenPrice(0);
                    handleCloseOpenNewProgram();
                    handleCloseOpenNewProgramUpdate();
                } else {
                    showToastError("Inténtalo más tarde. ");
                }
                setaddButtonSleepModalElementScreen(false);
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setaddButtonSleepModalElementScreen(false);
                return e;
            });
    }

    const handleSubmitElementScreenUpdate = event => {
        event.preventDefault();
        if (nameElementScreenUpdate.length===0) {
            showToastError("Debes completar los campos requeridos");
            return
        }
        setaddButtonSleepModalElementScreen(true);

        let objectUpdateElement = {
            name: nameElementScreenUpdate,
            description: nameSocialMediaUpdate,
            url: nameSocialLinkUpdate,
            name_icon: '',
            color: '',
            horario: elementScreenHorarioUpdate,
            horario_fin: elementScreenHorarioHastaUpdate,
            followers: elementScreenFollowersUpdate+""
        }
        let dataHeaderCrypto = encryptData('/api/v1/element-screen-update/'+idElementScreenUpdate, JSON.stringify(objectUpdateElement));
        fetch(config.urlApi + '/api/v1/element-screen-update/'+idElementScreenUpdate, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            },
            body: JSON.stringify({payload: dataHeaderCrypto[2]})
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status===200) {
                    showToastSuccess(data.message);
                    getElementScreen(valla.screen_id);   
                    setNameElementScreen('');
                    setNameSocialLink('');
                    setElementScreenHorario('');
                    setElementScreenHorarioHasta('');
                    setElementScreenFollowers(0);
                    setElementScreenPrice(0);
                    handleCloseOpenNewProgram();
                    handleCloseOpenNewProgramUpdate();
                } else {
                    showToastError("Inténtalo más tarde. ");
                }
                setaddButtonSleepModalElementScreen(false);
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setaddButtonSleepModalElementScreen(false);
                return e;
            });
    }

    const [listElementsScreen, setListElementsScreen] = useState([]);

    const getElementScreen = (idScreen) => {
        let dataHeaderCrypto = encryptData('/api/v1/element-screen/'+idScreen);
        fetch(config.urlApi + '/api/v1/element-screen/'+idScreen, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            }
        })
            .then((response) => response.json())
            .then(data => {
                var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
                dataPayload = JSON.parse(dataPayload);
                if (dataPayload) {
                    setListElementsScreen(dataPayload);
                } else {
                    showToastError("Inténtalo más tarde. ");
                }
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    const handleDeletePrograma = (idScreen) => {
        let dataHeaderCrypto = encryptData('/api/v1/element-screen/'+idScreen);
        fetch(config.urlApi + '/api/v1/element-screen/'+idScreen, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            }
        })
            .then((response) => response.json())
            .then(data => {
                if (data!==0) {
                    getElementScreen(valla.screen_id);
                    showToastSuccess("Eliminado correctamente");
                } else {
                    showToastError("Inténtalo más tarde. ");
                }
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    const handleChangeTerritorioTransmision = (event) => {
        setValla(valla => ({
            ...valla,
            territorio_transmision: event.target.value
        }));
    }
    const handleChangeDiasTransmision = (event) => {
        setValla(valla => ({
            ...valla,
            dias_transmision: event.target.value
        }));
    }
    const handleChangeInicioTransmision = (event) => {
        setValla(valla => ({
            ...valla,
            inicio_transmision: event.target.value
        }));
    }
    const handleChangeFinTransmision = (event) => {
        setValla(valla => ({
            ...valla,
            fin_transmision: event.target.value
        }));
    }

    const handleChangeFollowersFacebook = (event) => {
        setValla(valla => ({
            ...valla,
            followers_facebook: event.target.value
        }));
    }
    const handleChangeFollowersTwitter = (event) => {
        setValla(valla => ({
            ...valla,
            followers_twitter: event.target.value
        }));
    }
    const handleChangeFollowersInstagram = (event) => {
        setValla(valla => ({
            ...valla,
            followers_instagram: event.target.value
        }));
    }
    const handleChangeFollowersTiktok = (event) => {
        setValla(valla => ({
            ...valla,
            followers_tiktok: event.target.value
        }));
    }
    const handleChangeFollowersYoutube = (event) => {
        setValla(valla => ({
            ...valla,
            followers_youtube: event.target.value
        }));
    }

    const setPositionMap = (longitudTemp, latitudTemp) => {
        map.current.jumpTo({
            center: [longitudTemp, latitudTemp],
            // zoom: 10
        });
        setValla(valla => ({
            ...valla,
            longitud: longitudTemp,
            latitud: latitudTemp
        }));
    }

    const markedPointScreen = () => {
        if (valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') {
            map.current.on('click', (e) => {
                markerLocationValla.setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map.current);
                setPositionMap(e.lngLat.lng, e.lngLat.lat);
            });
        }
    }

    const showToastSuccess = (message) => {
        toast.success(message, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }


    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const handleSubmitUpdateValla = event => {
        event.preventDefault();

        if (valla.name === "") {
            showToastError("El nombre no puede estar vacío.");
            return null;
        }

        if (valla.is_payment) {
            if (valla.price === "" || valla.price === undefined) {
                showToastError("El precio no puede estar vacío.");
                return null;
            }
            if (parseInt(valla.price)<= 2000) {
                showToastError("El precio debe ser mayor a $2.000");
                return null;
            }
        }else{
            valla.price = "";
            valla.time_price = "";
        }

        if (valla.type_service_app === 'influencer') {
            if (valla.username === undefined || valla.username === "") {
                showToastError("El nombre de usuario no puede estar vacío");
                return null;
            }
        }
        if (valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') {
            
            if (valla.latitud === '' || valla.latitud.length === 0) {
                showToastError("Selecciona la ubicación.");
                return null;
            }

            if (valla.width_screen_active === "") {
                showToastError("Seleccione el ancho.");
                return null;
            }

            if (valla.height_screen_active === "" || valla.height_screen_active === "0") {
                showToastError("Seleccione el alto.");
                return null;
            }

            if (valla.type_business === "Ninguno") {
                showToastError("Debes seleccionar el tipo de Valla.");
                return null;
            }

            if (valla.number_faces_valla === '' || valla.number_faces_valla === undefined || valla.number_faces_valla === 0 || valla.number_faces_valla > 3) {
                showToastError("La cantidad de caras no es correcto.");
                setSpinnerLoading(false);
                setDisabledButton(false);
                return null;
            }
        }

        setDisabledButton(true);
        setSpinnerLoading(true);

        let objectUPdateScreen = {
            latitud: valla.latitud.toString(),
            longitud: valla.longitud.toString(),
            type_business: valla.type_business.toString(),
            width_screen_active: valla.width_screen_active.toString(),
            height_screen_active: "",
            name: valla.name.toString(),
            available: valla.available,
            number_whatsapp: valla.number_whatsapp.toString(),
            phone_number: valla.phone_number.toString(),
            message_whatsapp: valla.message_whatsapp.toString(),
            price: valla.price.toString(),
            time_price: valla.time_price.toString(),
            price_2: valla.price_2.toString(),
            time_price_2: valla.time_price_2.toString(),
            photo_tv: imageBase64Current,
            poste_height: valla.poste_height,
            number_faces_valla: parseInt(valla.number_faces_valla),
            type_service_app: valla.type_service_app,
            audiencia: valla.audiencia,
            programa: valla.programa,
            territorio_transmision: valla.territorio_transmision,
            dias_transmision: valla.dias_transmision,
            inicio_transmision: valla.inicio_transmision,
            fin_transmision: valla.fin_transmision,
            channel_tv: valla.channel_tv,
            frecuency_radio: valla.frecuency_radio,
            address_location: valla.address_location,
            url_page: valla.url_page,
            people_pass: valla.people_pass,
            owner_company: valla.owner_company,
            username: valla.username===''?screen:valla.username,
            code_checksum: screen,
            is_publicity: valla.is_publicity,
            is_payment: valla.is_payment,
            is_approval_automatic: valla.is_approval_automatic
        }

        let dataHeaderCrypto = encryptData('/api/v1/update-valla/' + screen, JSON.stringify(objectUPdateScreen));

        fetch(config.urlApi + '/api/v1/update-valla/' + screen, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            },
            body: JSON.stringify({payload: dataHeaderCrypto[2]})
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status >= 400) {
                    if (data.status === 401) {
                        deleteCookies();
                        setCookies('pathBefore', window.location.pathname, { path: '/' });
                        navigate('/login');
                      }
                    showToastError(data.message);
                }else{
                    navigate(-1);
                    showToastSuccess("Actualizado con éxito!");
                }
                setSpinnerLoading(false);
                setDisabledButton(false);
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. "+e);
                setSpinnerLoading(false);
                setDisabledButton(false);
                return e;
            });
    }

    const getScreensById = () => {
        setSpinnerLoading(true);
        if (cookies.access_token==='' || cookies.access_token === undefined) {
            deleteCookies();
            setCookies('pathBefore', window.location.pathname, { path: '/' });
            navigate('/login');
        }
        let dataHeaderCrypto = encryptData('/api/v1/screen/' + screen);
        fetch(config.urlApi + '/api/v1/screen/' + screen, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            }
        })
            .then((response) => response.json())
            .then(data => {
                var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
                dataPayload = JSON.parse(dataPayload);
                if (dataPayload.status === 404) {
                    showToastError(dataPayload.message);
                    navigate('/not-found/404');
                } else {
                    if (dataPayload.status === 401) {
                        deleteCookies();
                        setCookies('pathBefore', window.location.pathname, { path: '/' });
                        navigate('/login');
                    }
                    if (!dataPayload.status) {
                        setValla(dataPayload.screen);
                        if (dataPayload.screen.type_service_app === 'valla' || dataPayload.screen.type_service_app === 'valla-digital') {
                            var nav = new mapboxgl.NavigationControl({ visualizePitch: true });
                            map.current = new mapboxgl.Map({
                                container: 'map',
                                style: 'mapbox://styles/mapbox/streets-v11',
                                center: [-74.15645417974865, 4.642202298461683],
                                zoom: 9,

                            }).addControl(nav, 'bottom-right');
                            markerLocationValla.setLngLat([dataPayload.screen.longitud, dataPayload.screen.latitud]).setPopup(
                                new mapboxgl.Popup({ offset: 25 }).setHTML(
                                    `<div><h2 style="color:#000000;padding-top:35px;">` + dataPayload.screen.name + `</h2><p>` + dataPayload.screen.type_business + ` </p></div>`
                                )
                            ).addTo(map.current);
                            map.current.jumpTo({
                                center: [dataPayload.screen.longitud, dataPayload.screen.latitud],
                            });
                        }else{
                            setHeightMap(1);
                        }
                        if (dataPayload.screen.type_service_app === 'radio' || dataPayload.screen.type_service_app === 'television' || dataPayload.screen.type_service_app === 'influencer') {
                            getElementScreen(dataPayload.screen.screen_id);
                        }
                        // GetUserPremiumMessage();
                    } else {
                        showToastError(dataPayload.message);
                    }
                }
                setSpinnerLoading(false);
            })
            .catch(e => {
                setSpinnerLoading(false);
                // showToastError("Inténtalo más tarde. "+e);
            });
    }

    const [userPremiumMessage, setUserPremiumMessage] = useState();

    const GetUserPremiumMessage = () => {
        fetch(config.urlApi + '/api/v1/user-premium-message/', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status === 401) {
                    deleteCookies();
                    setCookies('pathBefore', window.location.pathname, { path: '/' });
                    navigate('/login');
                }
                if (data) {
                    setUserPremiumMessage(data.premium_messages);
                }
            })
            .catch(e => {
                return e;
            });
    }


    const [imageBase64Current, setImageBase64Current] = useState(undefined);
    
    const converterImagePost = (element) => {
        setSpinnerLoading(true);
        let reader = new FileReader();
        if (element.target) {
                reader.readAsDataURL(element.target.files[0]);
                reader.onload = function (e) {
                    var imagenAntigua = new Image();
                    imagenAntigua.src = e.target.result;
                        imagenAntigua.onload = function(){
                            var nuevoAncho = 800;
                            var ratio = (nuevoAncho/imagenAntigua.width);
                            var nuevoAlto = ratio * imagenAntigua.height;
                            var lienzo = document.createElement('canvas');
                            var contexto = lienzo.getContext('2d');
                            lienzo.width = nuevoAncho;
                            lienzo.height = nuevoAlto;
                            contexto.drawImage(imagenAntigua, 0, 0, nuevoAncho, nuevoAlto);
                            var imagenComprimida = lienzo.toDataURL('image/png');
                            setImageBase64Current(imagenComprimida);
                        }
                    setSpinnerLoading(false);
                }
                reader.onerror = function (error) {
                    showToastError("Ocurrió un error al intentar cargar la Imagen. " + error);
                    setSpinnerLoading(false);
                }
        }
    }


    const GetIconSocialMedia = ({nameSocial}) => {
        if (nameSocial==="Facebook") {
            return  <BsFacebook color='#3b5998' size={28} style={{ margin: 'auto' }} />
        }
        if (nameSocial==="Twitter") {
            return  <BsTwitterX color='#000000' size={28} style={{ margin: 'auto' }} />
        }
        if (nameSocial==="Instagram") {
            return  <BsInstagram color='#ffffff' size={28} style={{ borderRadius: 5, margin: 'auto', background: 'radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)' }} />
        }
        if (nameSocial==="Tiktok") {
            return  <FaTiktok color='#000000' size={28} style={{ margin: 'auto' }} />
        }
        if (nameSocial==="Youtube") {
            return  <BsYoutube color='#c4302b' size={28} style={{ margin: 'auto' }} />
        }
        if (nameSocial==="Whatsapp") {
            return  <BsWhatsapp color='#25d366' size={28} style={{ margin: 'auto' }} />
        }
        if (nameSocial==="Linkedin") {
            return  <BsLinkedin color='#0e76a8' size={28} style={{ margin: 'auto' }} />
        }    
        if (nameSocial==="Telegram") {
            return  <BsTelegram color='#0088cc' size={28} style={{ margin: 'auto' }} />
        }  
        if (nameSocial==="Pinterest") {
            return  <BsPinterest color='#C8232C' size={28} style={{ margin: 'auto' }} />
        } 
        if (nameSocial==="Line") {
            return  <BsLine color='#00b900' size={28} style={{ margin: 'auto' }} />
        }    
        if (nameSocial==="Snapchat") {
            return  <BsSnapchat color='#FFFC00' size={28} style={{ margin: 'auto' }} />
        } 
        if (nameSocial==="Web") {
            return  <BsLink45Deg color='#0645AD' size={28} style={{ margin: 'auto' }} />
        } 
        if (nameSocial==="Spotify") {
            return  <BsSpotify color='#1ED760' size={28} style={{ margin: 'auto' }} />
        } 
        
        return                                                  
    }

    return (
        <>

            <HeaderBack urlBack={''} title={'Editar'} needLogin={true} />

            {spinnerLoading &&
                <SpinnerLoadingFixed />
            }

            <br></br><br></br><br></br>
            <div style={{ maxWidth: 800, margin: 'auto' }}>

                <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                    {!spinnerLoading &&
                        <form onSubmit={handleSubmitUpdateValla} style={{ marginTop: 10, textAlign: 'center' }}>
                            <div className="edit-pautty-form">
                                <div className="row">

                                {valla.type_service_app === 'influencer' && 
                                    <div style={{ position: 'relative' }}>
                                        <div style={{ position: 'relative' }}>
                                            <label>Username - <span style={{ color: 'rgb(109 109 109)', textTransform: 'lowercase' }}>pautty.com/{valla.username.toLowerCase()}</span></label>
                                            <input
                                                className='input-form-pautty' 
                                                onChange={handleUsernameInfluencer} 
                                                maxLength={30} 
                                                type="text" 
                                                placeholder='Username' 
                                                name="usernameInfluencer" 
                                                value={valla.username.toLowerCase()} />
                                        </div>
                                    </div>
                                }

                                    <div style={{ position: 'relative' }}>
                                        <label >Nombre</label>
                                        {/* {!userPremiumMessage &&
                                            <Link to={'/premium'} id='buttonPremiumPaymentUpdate2' style={{ fontSize: 15 }}>
                                                  Solicitar Verificación <BsFillPatchCheckFill color='#49ADF4' />  
                                            </Link>
                                        } */}
                                        <input className='input-form-pautty' onChange={handleChangeNameValla} maxLength={30} type="text" placeholder='Nombre' name="nameValla" value={valla.name} />
                                    </div>

                                    <div style={{ position: 'relative' }}>
                                        {(valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') &&
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <label >Tipo de Valla</label>
                                                    <Form.Group className="mb-3">
                                                        <Form.Select className='input-form-pautty' onChange={handleChangeTypeValla} value={valla.type_business}>
                                                            {config.types_screen_location_valla.map((item) => <option key={item.id} value={item.name}>{item.name}</option>)}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className='col-4' style={{ textAlign: 'center' }}>
                                                    <label htmlFor="custom-switch">Disponible</label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={valla.available}
                                                        onChange={handleChangeAvailableValla}
                                                    />
                                                </div>
                                            </div>
                                        }


                                        <div className='row'>

                                            {(valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') &&
                                                <>
                                                {/* <div className='col-6' style={{ padding: 10, display: 'flex' }}>
                                                    <span style={{ paddingRight: 5 }}>Ancho:</span>
                                                    <input className='input-medida-valla' onChange={handleChangeWidthValla} maxLength={4} type="number" placeholder='0' name="widthValla" value={valla.width_screen_active} />
                                                </div> */}
                                                    {/* <div className='row'> */}
                                                        {/* <label>Dimensiones (metros)</label> */}
                                                        {/* <div className='col-6' style={{ padding: 10, display: 'flex' }}>
                                                            <span style={{ paddingRight: 5 }}>Ancho:</span>
                                                            <input className='input-medida-valla' onChange={handleChangeWidthValla} maxLength={4} type="number" placeholder='0' name="widthValla" value={valla.width_screen_active} />
                                                        </div>
                                                        <div className='col-6' style={{ padding: 10 }}>
                                                            <span style={{ paddingRight: 5 }}>Alto:</span>
                                                            <input className='input-medida-valla' onChange={handleChangeHeightValla} maxLength={4} type="number" placeholder='0' name="heightValla" value={valla.height_screen_active} />
                                                        </div> */}
                                                        {/* <div className='col-4' style={{ padding: 10 }}>
                                                            <span style={{ paddingRight: 5 }}>Poste:</span>
                                                            <input className='input-medida-valla' onChange={handleChangeSizePosteValla} maxLength={4} type="number" placeholder='0' name="sizePosteValla" value={valla.poste_height} />
                                                        </div> */}
                                                    {/* </div> */}

                                                    <div className='row'>
                                                    <label>Dimensión (metros)</label>
                                                            <div className='content-dimensiones-form' style={{ padding: 10 }}>
                                                                
                                                                {config.list_diomensions_screen.map((item) => { 
                                                                    if (item.type==='metro') {
                                                                        return <div onClick={()=>{handleChangeWidthValla(item.id)}} key={item.id} className='dimensiones-form'>
                                                                            {item.id===valla.width_screen_active&&
                                                                                <div className='item-selected-dimensions' style={{ width: item.widthCss, height: item.heightCss }}>
                                                                                    <AiFillCheckCircle size={'70%'} color='#dc3545' style={{ margin: 'auto' }} />
                                                                                </div>
                                                                            }
                                                                                <div style={{ width: item.widthCss, height: item.heightCss }} className='button-valla-dimension'>
                                                                                    <span>{item.name}</span>
                                                                                </div>
                                                                            </div>
                                                                    }})
                                                                }
                                                                
                                                            </div>

                                                            <label>Dimensión (Pulgadas)</label>
                                                            <div className='content-dimensiones-form' style={{ padding: 10 }}>
                                                                {config.list_diomensions_screen.map((item) => { 
                                                                    if (item.type==='pulgada') {
                                                                        return <div onClick={()=>{handleChangeWidthValla(item.id)}} key={item.id} className='dimensiones-form'>
                                                                                {item.id===valla.width_screen_active&&
                                                                                    <div className='item-selected-dimensions' style={{ width: item.widthCss, height: item.heightCss }}>
                                                                                        <AiFillCheckCircle size={'70%'} color='#dc3545' style={{ margin: 'auto' }} />
                                                                                    </div>
                                                                                }
                                                                                <div style={{ width: item.widthCss, height: item.heightCss }} className='button-valla-dimension'>
                                                                                    <span>{item.name}</span>
                                                                                </div>
                                                                            </div>
                                                                    }})
                                                                }
                                                            </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-12' style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
                                                            <span style={{ paddingRight: 5, paddingTop: 5 }}>Cantidad de caras:</span>
                                                            <Form.Group>
                                                                <Form.Select style={{ width: 60 }} onChange={handleChangeNumberFacesValla} value={valla.number_faces_valla}>
                                                                    <option value={1}>1</option>
                                                                    <option value={2}>2</option>
                                                                    <option value={3}>3</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {valla.type_service_app !== 'influencer'&&
                                            <div>
                                                <label>Dirección</label>
                                                <input autoComplete="false" className='input-form-pautty' onChange={handleChangeAddressLocation} maxLength={30} type="text" placeholder='Eje: AV 102 # 102' name="address_location" value={valla.address_location} />

                                                <label>Sitio Web</label>
                                                <input autoComplete="false" className='input-form-pautty' onChange={handleChangeUrlPage} maxLength={300} type="text" placeholder='www.pautty.com' name="url_page" value={valla.url_page} />
                                            </div>
                                            }

                                            {(valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') && 
                                                <div>
                                                    <label>Flujo de tránsito</label>
                                                    <span style={{ color: '#e0e0e0', fontSize: 14 }}>Cantidad de personas o vehículos que transitan</span>
                                                    <input autoComplete="false" className='input-form-pautty' onChange={handleChangePeoplePass} maxLength={11} type="number" placeholder='1000' name="people_pass" value={valla.people_pass} />
                                                </div>
                                            }

                                            {valla.type_service_app !== 'influencer'&&
                                                <div>
                                                    <label>Empresa</label>
                                                    <input autoComplete="false" className='input-form-pautty' onChange={handleChangeOwnerCompany} maxLength={100} type="text" placeholder='Mi Empresa SAS' name="owner_company" value={valla.owner_company} />
                                                </div>
                                            }

                                            {(valla.type_service_app === 'television' || valla.type_service_app === 'radio') &&
                                                <div className='content-programacion-update'>
                                                    <label>Programación</label>
                                                    {/* <div className='update-programs'>
                                                        <input autoComplete="false" className='input-form-pautty' onChange={handleChangePrograma} maxLength={30} type="text" placeholder='eje: Noticias' name="programaName" value={valla.programa} />
                                                    </div> */}

                                                    {listElementsScreen.length>0?
                                                        <div style={{ paddingTop: 15 }}>
                                                        
                                                            {listElementsScreen.map((item) => {
                                                                return <div className='item-program-list' key={item.element_screen_id}>
                                                                            {(item.horario!=='' || item.horario_fin!=='')&&
                                                                                <span>({item.horario} - {item.horario_fin})</span>
                                                                            }
                                                                            <span>{item.name}</span>
                                                                            {item.price!==''&&
                                                                                <span>{item.price}</span>
                                                                            }
                                                                            <div style={{ zIndex: 99 }} className="c-p-edit-horario-card-play" onClick={() => handleOpenNewProgramUpdate(item)}>
                                                                                <BsPencilFill />
                                                                            </div>
                                                                            <div style={{ zIndex: 99 }} className="c-p-remove-card-play" onClick={() => handleDeletePrograma(item.element_screen_id)}>
                                                                                <BsTrashFill />
                                                                            </div>
                                                                            <hr></hr>
                                                                        </div>
                                                            })}
                                                        </div>
                                                        :
                                                        <div>
                                                            <span>Agrega los programas que desees</span>
                                                        </div>
                                                    }
                                                    
                                                    <br></br>
                                                    <a className='button-add-new-program' onClick={handleOpenNewProgram}><BsPlus /> Agregar programa</a>
                                                    
                                                </div>
                                            }

                                            {(valla.type_service_app === 'television' || valla.type_service_app === 'radio') &&
                                                <div>
                                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                                        <label style={{ paddingTop: 10 }}>Audiencia</label>
                                                        <input autoComplete="false" className='input-form-pautty' onChange={handleChangeAudiencia} maxLength={30} type="text" placeholder='100.000 mensuales' name="audienciaQ" value={valla.audiencia} />
                                                    </div>
                                                </div>
                                            }

                                            {(valla.type_service_app === 'television') &&
                                                <div>                                                   
                                                    <div style={{ display: 'flex' }}>
                                                        <label style={{ paddingTop: 10 }}>Canal</label>
                                                        <input autoComplete="false" className='input-form-pautty' onChange={handleChangeChannel} maxLength={30} type="text" placeholder='Eje: 101' name="channel" value={valla.channel_tv} />
                                                    </div>
                                                </div>
                                            }

                                            {(valla.type_service_app === 'radio') &&
                                                <div>                                                   
                                                    <div style={{ display: 'flex' }}>
                                                        <label style={{ paddingTop: 10 }}>Frecuencia</label>
                                                        <input autoComplete="false" className='input-form-pautty' onChange={handleChangeFrecuencyRadio} maxLength={30} type="text" placeholder='Eje: 101.1 FM' name="frecuency_radio" value={valla.frecuency_radio} />
                                                    </div>
                                                </div>
                                            }
                                            
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                                                                   
                                        <div className='row item-update-check-valla'>
                                            <div className='col-8' style={{  display: 'flex', paddingTop: 5 }}>                                        
                                                <label htmlFor="custom-switch-publiccity">Permitir Pautas en este sitio</label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch-publiccity"
                                                        checked={valla.is_publicity}
                                                        onChange={handleChangeIsPublicityValla}
                                                        
                                                    />                                        
                                            </div>
                                            <div className='col-4'>
                                                {valla.is_publicity&&
                                                    <div style={{ background: '#dc3545', color: '#ffffff', borderRadius: 10, padding: 8 }}>
                                                        Pautar
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        {valla.is_publicity&&
                                            <>
                                                <div className='row item-update-check-valla'>
                                                    <div className='col-12' style={{  display: 'flex', paddingTop: 5 }}>
                                                        <label htmlFor="custom-switch-is_approval-automatic">Revisar antes de publicar</label>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch-is_approval-automatic"
                                                                checked={valla.is_approval_automatic}
                                                                onChange={handleChangeIsApprovalAutomatic}
                                                                
                                                            />                                        
                                                    </div>
                                                </div>

                                                <div className='row item-update-check-valla'>
                                                    <div className='col-12' style={{  display: 'flex', paddingTop: 5 }}>                                        
                                                        <label htmlFor="custom-switch-price"><FcMoneyTransfer size={25} /> Incluir Pagos</label>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch-price"
                                                                checked={valla.is_payment}
                                                                onChange={handleChangeIsPaymentValla}
                                                            />                                      
                                                    </div>
                                                </div>   
                                            </>
                                        }

                                            {(valla.is_payment && valla.is_publicity)&&
                                                <div className='row item-update-check-valla'>
                                                    <label >Precio</label>
                                                        <div className='col-7'>
                                                            <input autoComplete="false" className='input-form-pautty' onChange={handleChangePriceValla} maxLength={11} type="number" placeholder='Ingresa precio' name="price" value={valla.price} />
                                                            {/* <Form.Group className="mb-3">
                                                                <Form.Select className='input-form-pautty' onChange={handleChangePriceValla} value={valla.price}>
                                                                    {config.list_price_options.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                                                                </Form.Select>
                                                            </Form.Group> */}
                                                        </div>
                                                        <div className='col-5'>
                                                            <Form.Group className="mb-3">
                                                                <Form.Select className='input-form-pautty' onChange={handleChangeTimePrice} value={valla.time_price}>
                                                                    {config.list_code_plan_payments_pautty.map((item) => {
                                                                        if (item.type==='pauta') {
                                                                            if ((valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') & item.timer) {
                                                                                return <option key={item.id} value={item.id}>{item.name}</option>   
                                                                            }else{
                                                                                return <option key={item.id} value={item.id}>{item.name}</option>   
                                                                            }
                                                                        }
                                                                    })}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>

                                                        <label >Promoción</label>
                                                        <div className='col-7'>
                                                            <input autoComplete="false" className='input-form-pautty' onChange={handleChangePriceValla_2} maxLength={11} type="number" placeholder='Ingresa precio de promoción' name="price_2" value={valla.price_2} />
                                                            {/* <Form.Group className="mb-3">
                                                                <Form.Select className='input-form-pautty' onChange={handleChangePriceValla} value={valla.price}>
                                                                    {config.list_price_options.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                                                                </Form.Select>
                                                            </Form.Group> */}
                                                        </div>
                                                        <div className='col-5'>
                                                            <Form.Group className="mb-3">
                                                                <Form.Select className='input-form-pautty' onChange={handleChangeTimePrice_2} value={valla.time_price_2}>
                                                                    {config.list_code_plan_payments_pautty.map((item) => {
                                                                        if (item.type==='pauta') {
                                                                            if ((valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') & item.timer) {
                                                                                return <option key={item.id} value={item.id}>{item.name}</option>   
                                                                            }else{
                                                                                return <option key={item.id} value={item.id}>{item.name}</option>   
                                                                            }
                                                                        }
                                                                    })}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>

                                                    </div>
                                            }
                                        </div>

                                    </div>
{/* 
                                    <div style={{ position: 'relative', borderRadius: 5, padding: 10 }}>
                                        <div className='row'>
                                            <div className='col-1' style={{ paddingTop: 9 }}> <BsWhatsapp size={25} color='#25D366' /></div>
                                            <div className='col-11'>
                                                <span style={{ position: 'absolute', paddingTop: 11, paddingLeft: 5, color: 'rgb(171 171 171)' }}>+</span>
                                                <input className='input-form-pautty' onChange={handleChangeNumberWhatsapp} maxLength={12} type="text" placeholder='57 320 823 7758' name="numberWhatsapp" value={valla.number_whatsapp} />
                                            </div>
                                            <div className='col-1' style={{ paddingTop: 9 }}> <BsFillTelephoneFill size={25} color='#292929' /></div>
                                            <div className='col-11'>
                                                <input className='input-form-pautty' onChange={handleChangePhoneNumber} maxLength={12} type="text" placeholder='6010001' name="phone_number" value={valla.phone_number} />
                                            </div>
                                        </div>
                                        <textarea className='input-form-pautty' onChange={handleChangeMessageWhatsapp} maxLength={100} type="text" placeholder='Eje: Hola, te encontré en Pautty, quisiera más información sobre la Valla' name="massageWhatsapp" value={valla.message_whatsapp} />
                                        
                                    </div> */}

                                    {valla.type_service_app === 'influencer'&&
                                    <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                                        <label>Redes Sociales</label>                                            

                                        {listElementsScreen.length>0?
                                                <div style={{ paddingTop: 15 }}>
                                                
                                                    {listElementsScreen.map((item) => {
                                                        return <div className='row' key={item.element_screen_id}>
                                                                    <div className='col-2' style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                                                                        <GetIconSocialMedia nameSocial={item.description} />
                                                                    </div>
                                                                    <div className='col-8' style={{ cursor: 'pointer' }} onClick={()=>handleOpenNewProgramUpdate(item)}>
                                                                        <span><b>{item.url}</b></span><br></br>
                                                                        <span style={{ paddingLeft: 10 }}>{item.name}</span>
                                                                        <p>{item.followers} seguidores</p>
                                                                    </div>
                                                                    <div className='col-2' style={{ display: 'flex' }}>
                                                                        {/* <div style={{ zIndex: 99, display: 'flex' }} className="col-12" onClick={() => handleOpenNewProgramUpdate(item)}>
                                                                            <BsPencilFill color='#0dcaf0' style={{ margin: 'auto' }} />
                                                                        </div> */}
                                                                        <div style={{ zIndex: 99, display: 'flex', cursor: 'pointer', borderRadius: 15, margin: 'auto' }} className="col-12" onClick={() => handleDeletePrograma(item.element_screen_id)}>
                                                                            <BsTrashFill size={30} color='#dc3545' style={{ margin: 'auto' }} /> 
                                                                        </div>
                                                                    </div>
                                                                    <hr></hr>
                                                                </div>
                                                    })}
                                                </div>
                                                :
                                                <div>
                                                    <span>Agrega los enlaces que desees</span>
                                                </div>
                                            }
                                            <br></br>
                                            <a className='button-add-new-program' onClick={handleOpenNewProgram}><BsPlus /> Agregar enlace</a>
                                    </div>
                                    }
                                    <hr></hr>

                                    <div className='row' style={{ marginBottom: 10 }}>
                                        <label style={{ padding: 10 }}>Imagen </label>
                                        <div style={{ borderRadius: 5 }}>
                                            <div className='row' style={{ background: 'transparent' }}>
                                                <div className='col-8'>
                                                    {imageBase64Current !== undefined ?
                                                        <img alt='Imagen pautty' src={imageBase64Current} width={'100%'} />
                                                        :
                                                        <img alt='Imagen pautty' src={valla.photo_tv} width={'100%'} />
                                                    }

                                                </div>
                                                <div className='col-4' style={{ textAlign: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>
                                                    <span style={{ margin: 'auto' }}>
                                                        <label htmlFor='images' className="drop-container">
                                                            <span className='drop-title'>Cambiar Imagen <FcUpload size={30} /> </span>
                                                            <input style={{ margin: 2, border: 'none', background: "#f52d3f", color: "#fff", borderRadius: 5, display: 'none' }} id='images' accept="image/*" type="file" className="form-control" onChange={(e) => converterImagePost(e)} />
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {(valla.type_service_app === 'television' || valla.type_service_app === 'radio') &&
                                            <div style={{ paddingTop: 15 }}>
                                                <div style={{ display: 'flex' }}>
                                                    <label style={{ paddingTop: 10 }}>Transmitido en:</label>
                                                    <input autoComplete="false" className='input-form-pautty' onChange={handleChangeTerritorioTransmision} maxLength={60} type="text" placeholder='eje: Toda Colombia' name="territorioTransmision" value={valla.territorio_transmision} />
                                                </div>
                                                
                                                {/* <div className='row'>
                                                    <div className='col-4'>
                                                        <label style={{ paddingTop: 10 }}>Horario:</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <Form.Group className="mb-3">
                                                            <Form.Select className='input-form-pautty' onChange={handleChangeDiasTransmision} value={valla.dias_transmision}>
                                                                {config.list_dias_transmision.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label style={{ paddingTop: 10 }}>Desde las:</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <input onChange={handleChangeInicioTransmision} type="time" name="hourInicioTransmision" value={valla.inicio_transmision}/>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label style={{ paddingTop: 10 }}>Hasta las:</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <input onChange={handleChangeFinTransmision} type="time" name="finInicioTransmision" value={valla.fin_transmision}/>
                                                    </div>
                                                </div> */}


                                            </div>
                                        }


                                    {(valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') &&
                                        <div className="get-geolocation" style={{ width: "100%", textAlign: 'center', padding: 5, color: 'blue', cursor: 'pointer' }}>
                                            <div onClick={getLocation}><BiCurrentLocation color='blue' /> Mi ubicación</div>
                                            {spinnerLoadingMap &&
                                                <div className="d-flex justify-content-center" style={{ height: '50px' }}>
                                                    <h1 className="text-login-title" style={{ margin: 'auto' }}>
                                                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                                                    </h1>
                                                </div>
                                            }
                                        </div>
                                    }

                                    <div style={{ position: 'fixed', bottom: 0, margin: 0, padding: '10px 20px 10px 20px', background: '#fff', zIndex: 999, left: 0 }}>
                                        <button type='submit' disabled={disabledButton}>
                                            Actualizar
                                            {spinnerLoading &&
                                                <div className="d-flex justify-content-center" style={{ float: 'right', paddingTop: 5, paddingRight: 8 }}>
                                                    <span className="spinner-border spinner-border-sm button-sppiner-pauty" role="status" aria-hidden="true"></span>
                                                </div>
                                            }
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </form>
                    }

                    <div onClick={markedPointScreen}>
                        <div id="map" style={{ width: "100%", height: heightMap, marginBottom: "55px", marginTop: "15px", cursor: "pointer" }}></div>
                    </div>


                </div>

            </div>

            <Modal show={buttonOpenNewProgram} onHide={handleCloseOpenNewProgram} size="lg" centered>
                <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                    <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Agregar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="edit-pautty-form">
                        <form onSubmit={handleSubmitAddElementScreen}>
                            {valla&&
                                <>
                                    {(valla.type_service_app === 'television' || valla.type_service_app === 'radio')&&
                                        <div className='row'>
                                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                <input onChange={handleChangeNameElementScreen} className='input-form-pautty' type="text" name="nameElementScreen" placeholder="Agrega un nombre" value={nameElementScreen} maxLength={100} />
                                            </div>
                                            <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1' style={{ paddingTop: 11 }}>Desde:</div>
                                            <div className='col-xl-5 col-lg-5 col-md-5 col-sm-11'>
                                                <input onChange={handleChangeHorarioElementScreen} className='input-form-pautty' type="time" name="elementScreenHorario" placeholder="Inicio" value={elementScreenHorario} maxLength={40} />
                                            </div>
                                            <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1' style={{ paddingTop: 11 }}>Hasta:</div>
                                            <div className='col-xl-5 col-lg-5 col-md-5 col-sm-11'>
                                                <input onChange={handleChangeHorarioFinElementScreen} className='input-form-pautty' type="time" name="elementScreenHorarioHasta" placeholder="Final" value={elementScreenHorarioHasta} maxLength={40} />
                                            </div>
                                        </div>
                                        
                                    }   
                                    {valla.type_service_app === 'influencer'&&
                                        <div className='row'>
                                            <div className='col-12 content-social-links-modal'>
                                                    {config.list_links_social_media.map((item) => {
                                                        return  <a key={item.id} onClick={()=>{
                                                                                            handleChangeNameSocialMedia(item.name);
                                                                                            setNameSocialMedia(item.name);
                                                                                            handleChangeUrlUsernameSocial(item.urlWeb);
                                                                                        }}><GetIconSocialMedia nameSocial={item.name} /></a>    
                                                    })}
                                            </div>
                                            {nameSocialLink.length>0&&
                                                <div className='col-12 row'>
                                                    <div className='col-12 url-update-links'>
                                                        <p><b>{nameSocialLink}</b></p>
                                                        {/* <span>{nameElementScreen}</span> */}
                                                    </div>
                                                    <div className='col-xl-9 col-lg-9 col-md-9 col-sm-12'>
                                                        <input onChange={handleChangeNameElementScreen} className='input-form-pautty' autoComplete={'false'} type="text" name="nameElementScreen" placeholder="usuario" value={nameElementScreen} maxLength={100} />
                                                    </div>                                                
                                                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12'>
                                                        <input onChange={handleChangeNameElementScreenFollowers} className='input-form-pautty' type="number" name="nameElementScreenFollowers" placeholder="seguidores" value={elementScreenFollowers} maxLength={10} />
                                                    </div>

                                                    <div className='col-12'>
                                                        <p className='text-below-username-link'>{urlUsernameSocial}{nameElementScreen}</p>
                                                    </div>
                                                    {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' style={{ textAlign: 'center', display: 'flex' }}>
                                                        <span style={{ margin: 'auto' }}>Precio de Pauta</span>
                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' style={{ paddingTop: 10 }}>
                                                        <input onChange={handleChangeNameElementScreenPrice} className='input-form-pautty' type="number" name="nameElementScreenPrice" placeholder="$200" value={elementScreenPrice} maxLength={10} />
                                                    </div> */}
                                                </div>
                                            }
                                        </div>
                                    }    
                                </>
                            }
                            
                            <br></br>
                            {addButtonSleepModalElementScreen?
                                <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                                    <h1 className="text-login-title" style={{ margin: 'auto' }}>
                                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                                    </h1>
                                </div>:
                                <button disabled={nameElementScreen.length===0} className="btn btn-default color-bottons btn-lg w-100" type="submit">
                                    Agregar
                                </button>
                            }
                        </form>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={buttonOpenNewProgramUpdate} onHide={handleCloseOpenNewProgramUpdate} size="lg" centered>
                <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                    <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Actualizar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="edit-pautty-form">
                        <form onSubmit={handleSubmitElementScreenUpdate}>
                            {valla&&
                                <>
                                    {/* {(valla.type_service_app === 'television' || valla.type_service_app === 'radio')&&
                                        <div className='row'>
                                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                <input onChange={handleChangeNameElementScreenUpdate} className='input-form-pautty' type="text" name="nameElementScreenUpdate" placeholder="Agrega un nombre" value={nameElementScreenUpdate} maxLength={40} />
                                            </div>
                                            <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1' style={{ paddingTop: 11 }}>Desde:</div>
                                            <div className='col-xl-5 col-lg-5 col-md-5 col-sm-11'>
                                                <input onChange={handleChangeHorarioElementScreenUpdate} className='input-form-pautty' type="time" name="elementScreenHorarioUpdate" placeholder="Inicio" value={elementScreenHorarioUpdate} maxLength={40} />
                                            </div>
                                            <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1' style={{ paddingTop: 11 }}>Hasta:</div>
                                            <div className='col-xl-5 col-lg-5 col-md-5 col-sm-11'>
                                                <input onChange={handleChangeHorarioFinElementScreenUpdate} className='input-form-pautty' type="time" name="elementScreenHorarioHastaUpdate" placeholder="Final" value={elementScreenHorarioHastaUpdate} maxLength={40} />
                                            </div>
                                        </div>
                                        
                                    }    */}
                                    {valla.type_service_app === 'influencer'&&
                                        <div className='row'>
                                            <div className='col-12 content-social-links-modal'>
                                                    {config.list_links_social_media.map((item) => {
                                                        return  <a key={item.id} onClick={()=>{handleChangeNameSocialMediaUpdate(item.name);setNameSocialMediaUpdate(item.name);handleChangeUrlUsernameSocial(item.urlWeb);}}><GetIconSocialMedia nameSocial={item.name} /></a>    
                                                    })}
                                            </div>
                                            {nameSocialLinkUpdate.length>0&&
                                                <div className='col-12 row'>
                                                    <div className='col-12 url-update-links'>
                                                        <span><b>{nameSocialLinkUpdate}</b></span><br></br>
                                                    </div>
                                                    <div className='col-xl-9 col-lg-9 col-md-9 col-sm-12'>
                                                        <input onChange={handleChangeNameElementScreenUpdate} className='input-form-pautty' autoComplete={'false'} type="text" name="nameElementScreen" placeholder="usuario" value={nameElementScreenUpdate} maxLength={100} />
                                                    </div>
                                                
                                                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12'>
                                                        <input onChange={handleChangeNameElementScreenFollowersUpdate} className='input-form-pautty' type="number" name="nameElementScreenFollowers" placeholder="seguidores" value={elementScreenFollowersUpdate} maxLength={10} />
                                                    </div>

                                                    <div className='col-12'>
                                                        <p className='text-below-username-link'>{urlUsernameSocial}{nameElementScreenUpdate}</p>
                                                    </div>

                                                    {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' style={{ textAlign: 'center', display: 'flex' }}>
                                                        <span style={{ margin: 'auto' }}>Precio de Pauta</span>
                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' style={{ paddingTop: 10 }}>
                                                        <input onChange={handleChangeNameElementScreenPriceUpdate} className='input-form-pautty' type="number" name="nameElementScreenPriceUpdate" placeholder="$200" value={elementScreenPriceUpdate} maxLength={10} />
                                                    </div> */}

                                                </div>
                                            }
                                        </div>
                                    }    
                                </>
                            }
                            
                            <br></br>
                            {addButtonSleepModalElementScreen?
                                <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                                    <h1 className="text-login-title" style={{ margin: 'auto' }}>
                                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                                    </h1>
                                </div>:
                                <button disabled={nameElementScreenUpdate.length===0} className="btn btn-default color-bottons btn-lg w-100" type="submit">
                                    Actualizar
                                </button>
                            }
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <ToastContainer />
        </>
    )

}

export default UpdateValla;