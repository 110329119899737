import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import { config } from '../configPautty';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { deleteCookies } from '../deleteCookies';

import NavBarTop from '../general/NavBar';
import NavBarFooter from '../general/NaBarFooter';
import { BsDownload, BsFillPenFill, BsPower, BsQrCode, BsShareFill } from 'react-icons/bs';
import { FcAdvertising, FcFrame, FcManager, FcPicture } from 'react-icons/fc';
import MisPautas from './MisPautas';
import MisVallas from './MisVallas';
import { decryptData, encryptData } from '../crypto/pauttyCrypro';
import ShowInfluencer from '../valla/show/Influencer';
import { Modal } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';


const Username = () => {
  const [cookies, setCookies] = useCookies(['user']);
  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const qrRef = useRef();

  const [reloadEffect, setReloadEffect] = useState(true);
  
  const [loading, setLoading] = useState(true);

  const [profile, setProfile] = useState();

  const [showModalMyQRCode, setShowwModalMyQRCode] = useState(false);

  const handleCloseModalQRCode = () => setShowwModalMyQRCode(false);
  const handleShowModalQRCode = () => setShowwModalMyQRCode(true);

  const [buttonQRDisabled, setButtonQRDisabled] = useState(false);

  useEffect(() => {
    if (reloadEffect) {
      setReloadEffect(false);
      getProfileUser();
    }    
  }, [reloadEffect]);

  const LogoutApp = () => {
    deleteCookies();
    setCookies('pathBefore', "/", { path: '/' });    
    window.location.href = "/login";
}

  const getProfileUser = () => {
    setLoading(true);

    let dataHeaderCrypto = encryptData('/api/v1/profile/');
    fetch(config.urlApi + '/api/v1/profile/', {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${cookies.access_token}`,
            'X-Api-Pautty-0': dataHeaderCrypto[0],
            'X-Api-Pautty-1': dataHeaderCrypto[1]
        }
    })
        .then((response) => response.json())
        .then(data => {
            var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
            dataPayload = JSON.parse(dataPayload);
            if (dataPayload.status === 401) {
                deleteCookies();
                navigate('/login');
            } else if (dataPayload.status > 400) {
                showToastError("Ocurrió un error al consultar el usuario");
            } else {
                if (!dataPayload.status) {
                  setProfile(dataPayload);
                  setLoading(false);
                }
            }
            return data;
        })
        .catch(e => {
            showToastError("Inténtalo más tarde. ");
            return e;
        });
}


  const showToastError = (error) => {
    toast.error(error, {
      position: "bottom-center",
      autoClose: 5000,
      rtl: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    });
  }
 

  const downloadQRCode = () => {
    setButtonQRDisabled(true);
    const canvas = generarCanvasConQR();
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'codigo_qr_'+profile.username+'.png';
    link.click();
    setButtonQRDisabled(false);
  };

  const shareQRCode = () => {
    const canvas = generarCanvasConQR();
    canvas.toBlob((blob) => {
      if (navigator.share) {
        navigator.share({
          files: [new File([blob], 'codigo_qr_'+profile.code_checksum+'.png', { type: 'image/png' })],
          title: 'Mi Código QR',
          text: 'Escanea este código QR para acceder a mi perfil de Pautty',
        })
        .then(() => console.log('Compartido con éxito'))
        .catch((error) => console.error('Error al compartir:', error));
      } else {
        showToastError('La funcionalidad de compartir no está soportada en este navegador.');
      }
    }, 'image/png');
  };

  const generarCanvasConQR = () => {
    const canvas = document.createElement('canvas');
    const size = 400;
    const extraHeight = 80;
    const cornerRadius = 30;
    canvas.width = size;
    canvas.height = size + extraHeight;
    const ctx = canvas.getContext('2d');

    ctx.fillStyle = '#dc3545';
    ctx.beginPath();
    ctx.moveTo(cornerRadius, 0);
    ctx.lineTo(canvas.width - cornerRadius, 0);
    ctx.quadraticCurveTo(canvas.width, 0, canvas.width, cornerRadius);
    ctx.lineTo(canvas.width, canvas.height - cornerRadius);
    ctx.quadraticCurveTo(canvas.width, canvas.height, canvas.width - cornerRadius, canvas.height);
    ctx.lineTo(cornerRadius, canvas.height);
    ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - cornerRadius);
    ctx.lineTo(0, cornerRadius);
    ctx.quadraticCurveTo(0, 0, cornerRadius, 0);
    ctx.closePath();
    ctx.fill();
    ctx.clip();

    const qrCanvas = qrRef.current.querySelector('canvas');
    ctx.drawImage(qrCanvas, 10, 10, size - 20, size - 20); 

    ctx.fillStyle = '#ffffff';
    if (profile.name) {
      if (profile.name.length > 20) {
        ctx.font = '25px Arial';   
      }else{
        ctx.font = '40px Arial';
      }
    ctx.textAlign = 'center';
    ctx.fillText(profile.name, canvas.width / 2, size);
    }

    if (profile.code_checksum) {
      if (profile.code_checksum.length > 20) {
        ctx.font = '20px Arial';   
      }else{
        ctx.font = '30px Arial';
      }
    }
    ctx.textAlign = 'center';
    ctx.fillText("@"+profile.code_checksum, canvas.width / 2, size + 38); 

    return canvas;
  };


  return (
    <div style={{ paddingTop: 60 }}>

      <NavBarTop></NavBarTop>

      {/* {loading &&
        <SpinnerLoadingFixed />
      } */}

        <div style={{ margin: 'auto', maxWidth: '800px', background: '#fff' }}>
            <div className='tabs-menu-medios' style={{ marginBottom: 0 }}>
                <div>
                    <button onClick={()=>{setSearchParams({tab:'user'})}} className={searchParams.get("tab")==='user'?'active ':''}>
                        <FcManager />{' '}
                        Mi Perfil
                    </button>
                </div>
                <div>
                    <button onClick={()=>{setSearchParams({tab:'pautas'})}} className={searchParams.get("tab")==='pautas'?'active ':''}>
                        <FcAdvertising />{' '}
                        Mis Pautas
                    </button>
                </div>
                <div>
                    <button onClick={()=>{setSearchParams({tab:'vallas'})}} className={searchParams.get("tab")==='vallas'?'active ':''}>
                        <FcFrame />{' '}
                        Mis Vallas
                    </button>
                </div>
                
            </div>
        </div>

      <div>
        {searchParams.get("tab")==='user'&&
          <>
            {!loading &&
              <div style={{ width: '100%', textAlign: 'center' }}>
                <button 
                  className='btn btn-warning' 
                  onClick={handleShowModalQRCode}
                  style={{ marginBottom: 10, marginRight: 5 }}
                  ><BsQrCode />{' '}Mi QR</button>

                <button 
                  className='btn btn-info' 
                  onClick={()=>{navigate('/editar-medio-publicitario/' + profile.code_checksum)}}
                  style={{ marginBottom: 10 }}
                  ><BsFillPenFill />{' '}Editar Perfil</button>

                <ShowInfluencer valla={profile} />
              </div>
            }
            {loading&&
              <SpinnerLoadingFixed />
            }
            <button style={{ textAlign: 'center', width: '100%', paddingBottom: 100, padding: 10, background: 'transparent', color: '#f52d3f', border: 'none' }} onClick={LogoutApp}><i><BsPower /></i>{' '} Cerrar Sesión</button>
          </>
        }
        {searchParams.get("tab")==='pautas'&&
          <MisPautas />
        }
        {searchParams.get("tab")==='vallas'&&
          <MisVallas />
        }
      </div>


      <Modal
          show={showModalMyQRCode}
          dialogClassName="modal-90w"
          centered
          onHide={handleCloseModalQRCode}>
          <Modal.Header style={{ textAlign: 'center' }} closeButton>
              <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Mi QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ background: "#dc3545" }}>
              <div style={{ textAlign: 'center' }}>
                {profile&&
                  <div ref={qrRef}>

                      <QRCodeCanvas 
                        id='canvaQRCodeProfileUsername' 
                        value={"https://pautty.com/"+profile.code_checksum} 
                        size={250} 
                        includeMargin={true}
                        bgColor="#dc3545" // Color de fondo
                        fgColor="#ffffff" // Color del código
                        />
                        <h3 style={{ color: "#ffffff" }}>{profile.name}</h3>
                        <h5 style={{ color: "#ffffff" }}>@{profile.code_checksum}</h5>

                      <button disabled={buttonQRDisabled} className='button-qr-username' onClick={downloadQRCode}>
                        Descargar <BsDownload />
                      </button>

                      <button className='button-qr-username' onClick={shareQRCode} style={{ marginLeft: 5 }}>
                        Compartir <BsShareFill />
                      </button>

                  </div>
                }
              </div>
          </Modal.Body>
      </Modal>

      <ToastContainer />

      <NavBarFooter></NavBarFooter>
    </div>
  )
}

export default Username;