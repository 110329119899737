import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { deleteCookies } from '../deleteCookies';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import { useCookies } from 'react-cookie';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import PautaItem from '../valla/PautaItem';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { Form } from 'react-bootstrap';
import NavBarTop from '../general/NavBar';
import NavBarFooter from '../general/NaBarFooter';
import { decryptData, encryptData } from '../crypto/pauttyCrypro';

const MisPautas = () => {
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [loadingPautas, setLoadingPautas] = useState(true);

    const [postScreen, setPostScreen] = useState([]);

    const [titlePost, setTitlePost] = useState();
    const [buttonActiveModal, setButtonActiveModal] = useState(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        getListPostScreen("0", true);
    }, []);

    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const handleChangeTitle = (event) => {
        if (event.target.value.length > 0) {
            setButtonActiveModal(false);
        } else {
            setButtonActiveModal(true);
        }
        if (event.target.value.length <= 40) {
            setTitlePost(event.target.value);
        }
    }

  

    const handleSubmitAddInitPostScreen = event => {
        event.preventDefault();
        setLoading(true);
        if (titlePost.length === 0) {
            showToastError("El títuo no puede estar vacío.");
            setLoading(false);
            return 0;
        }
        fetch(config.urlApi + '/api/v1/init-post-screen/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify({
                name: titlePost,
                originator_post: 'L',
                option_style: 1,
                description: 'Haz crecer tu negocio'      
            })
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status === 401) {
                    deleteCookies();
                    setCookies('pathBefore', window.location.pathname, { path: '/' });
                    navigate('/login');
                }else{
                    if (!data.status) {
                        setTitlePost('');
                    } else {
                        showToastError(data.message);
                    }
                }                
                setLoading(false);
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setLoading(false);
                return e;
            });
    }

    const [userPremiumMessage, setUserPremiumMessage] = useState(false);

    // const GetUserPremiumMessage = () => {
    //     fetch(config.urlApi + '/api/v1/user-premium-message/' , {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${cookies.access_token}`
    //         }
    //     })
    //         .then((response) => response.json())
    //         .then(data => {
    //             if (data){
    //                 setUserPremiumMessage(data.premium_messages);
    //             }
    //         })
    //         .catch(e => {
    //             return e;
    //         });
    // }

    const [pageCurrent, setPageCurrent] = useState(0);
    const [lastResults, setLastResults] = useState(true);
    const [statusPauta, setStatusPauta] = useState('0');
    

    const getListPostScreen = (status, increment) => {
        setLoadingPautas(true);
        var urlFetch = "";
        // get-post-screen-partner
        if (!increment) {
            urlFetch = config.urlApi + '/api/v1/screen-by-user/?page=' + 0 + '&status=' + status;
        }else{
            urlFetch = config.urlApi + '/api/v1/screen-by-user/?page=' + pageCurrent + '&status=' + status;
        }
        
        let dataHeaderCrypto = encryptData('/api/v1/screen-by-user/');
        fetch(urlFetch, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            }
        })
            .then((response) => response.json())
            .then(data => {
                
                var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
                dataPayload = JSON.parse(dataPayload);
                
                if (dataPayload.status) {
                    if (dataPayload.status === 401) {
                        deleteCookies();
                        setCookies('pathBefore', window.location.pathname, { path: '/' });
                        navigate('/login');
                    }
                    if (dataPayload.status===404) {
                        setLastResults(false);
                    }
                }else{
                    if (pageCurrent === 0) {
                        setPostScreen(dataPayload);
                    }else{
                        setPostScreen(pauta => pauta.concat(dataPayload));
                    }
                }
                if (increment) {
                    setPageCurrent(pageCurrent + 1);   
                }
                setLoadingPautas(false);
                setLoading(false);
            })
            .catch(e => {
                setLoadingPautas(false);
                setLoading(false);
                // showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    const handleChangeValueStatusPauta = (status) => {
        setPostScreen([]);
        setStatusPauta(status);
        getListPostScreen(status, false);
        setLastResults(true);
    }

    return (
        <div>

            {loading&&
                <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                    <h1 className="text-login-title" style={{ margin: 'auto' }}>
                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                    </h1>
                </div>
            }

                <>
                    {postScreen &&
                        <div className='' style={{ height: 'auto' }}>              
                            <div style={{ height: 'auto', margin: 'auto', justifyContent: 'center', justifyItems: 'center', textAlign: 'center', maxWidth: 800 }}>
                            
                            <div style={{ margin: 'auto', background: '#eaeaea', borderRadius: 10 }}>
                                <div className='tabs-menu-medios tabs-menu-medios-pautas' style={{ textAlign: 'center', overflowX: 'scroll' }}>
                                    {/* <div className="radio-filters">
                                        <label htmlFor='todos-switch'>Todos</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="todos-switch"
                                            defaultChecked="true"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('');setPageCurrent(1);}}
                                        />
                                    </div> */}
                                    <div className="radio-filters">
                                        <label htmlFor='registrado-switch'>{config.list_status_pauta[0].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="registrado-switch"
                                            defaultChecked="true"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('0');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    {/* <div className="radio-filters">
                                        <label htmlFor='recibidos-switch'>{config.list_status_pauta[1].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="recibidos-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('1');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div> */}
                                    <div className="radio-filters">
                                        <label htmlFor='pagados-switch'>{config.list_status_pauta[2].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="pagados-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('2');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='aprobados-switch'>{config.list_status_pauta[3].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="aprobados-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('3');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='en-proceso-switch'>{config.list_status_pauta[4].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="en-proceso-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('4');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='publicados-switch'>{config.list_status_pauta[5].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="publicados-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('5');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='finish-switch'>{config.list_status_pauta[6].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="finish-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('6');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='rejected-switch'>{config.list_status_pauta[7].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="rejected-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('7');setPageCurrent(1);}}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            
                            {postScreen.length>0?
                            <>

                                <div>
                                    {postScreen.map((item, index) => {
                                        return <PautaItem key={index} item={item} index={index} userPremiumMessage={userPremiumMessage} pautar={false} />
                                    })}
                                </div>


                                {loadingPautas ?
                                    <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                                        <h1 className="text-login-title" style={{ margin: 'auto' }}>
                                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                                        </h1>
                                    </div>
                                    :
                                    <div style={{ marginBottom: 60 }}>
                                        {lastResults&&
                                            <button onClick={()=>{getListPostScreen(statusPauta, true);}} className="button-12" style={{ cursor: 'pointer', border: 'none', borderRadius: 15, marginBottom: 50 }}>
                                                Cargar más
                                            </button>
                                        }
                                    </div>
                                }

                            </>
                            :
                                <div style={{ width: '100%', textAlign: 'center', paddingTop: 10 }}>
                                    <BsFillInfoCircleFill color='#838080' size={50} />
                                    <h3 style={{ paddingTop: 10, color: '#838080' }}>No tienes Pautas</h3>
                                </div>
                            }
                                
                            </div>


                        </div>
                    }

                </>
           

                {loadingPautas &&
                    <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                        <h1 className="text-login-title" style={{ margin: 'auto' }}>
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </h1>
                    </div>
                }



            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Agregar Pauta
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="edit-pautty-form">
                        <form onSubmit={handleSubmitAddInitPostScreen}>
                            <input onChange={handleChangeTitle} className='input-form-pautty' type="text" name="name" placeholder="Título de tu contenido" value={titlePost} maxLength={40} />
                            <br></br>
                            <button disabled={buttonActiveModal} className="btn btn-default color-bottons btn-lg w-100" type="submit">
                                Agregar
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <ToastContainer />

        </div>
    )
}

export default MisPautas;