import React, { useEffect, useState } from 'react';
import { config } from '../configPautty';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from '../crypto/pauttyCrypro';

const Login = () => {
  const [phone, setPhone] = useState('');
  const [disabledButton, setDisabledButton] = useState(true);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [timeLogin, setTimeLogin] = useState(60);
  const [cookies, setCookies] = useCookies(['user']);

  const [counteCode, setCounteCode] = useState("57");

  const [typeLogin] = useState("phone");
  const [typeLoginFinish] = useState("email");

  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.access_token !== undefined) {
      navigate('/');
    }
  }, [cookies, navigate]);


  const handleChange = (event) => {
    if (event.target.value.length <= 5) {
      setDisabledButton(true);
    }else{
      setDisabledButton(false);
    }
    if (event.target.value.length < 50) {
      setPhone(event.target.value);
    }
  }

  const showToastError = (error) => {
    toast.error(error, {
      position: "bottom-center",
      autoClose: 5000,
      rtl:false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
      });
  }

  const handleSubmitLogin = event => {
    event.preventDefault();
    setDisabledButton(true);
    setSpinnerLoading(true);
    setTimeLogin(60);

    if (phone.length===0) {
      showToastError("Ingresa los datos completos.");
      setSpinnerLoading(false);
      setPhone('');
      return
    }

    let objectLogin = { phone: phone, country: counteCode, type_login: typeLoginFinish }

    let dataHeaderCrypto = encryptData('/api/v1/login/', JSON.stringify(objectLogin));

    fetch(config.urlApi + '/api/v1/login/', {
      method: 'POST',
      headers: {
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
    },
      body: JSON.stringify({payload: dataHeaderCrypto[2]})
    })
      .then((response) => response.json())
      .then(data => {
        var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
        dataPayload = JSON.parse(dataPayload);
        if (dataPayload.status>=400) {
          showToastError(data.dataPayload);
        }else{
          setCookies('SecondsOTP', timeLogin, { path: '/' });
          var today = new Date();
          var expire = new Date(today.getTime() + 90 * 24 * 3600 * 1000);
          setCookies('phone', phone, { path: '/', expires: expire });
          setCookies('countryCode', counteCode, { path: '/', expires: expire });
          setCookies('typeLogin', typeLoginFinish, { path: '/', expires: expire });
          navigate('/login-confirm');
        }
        setSpinnerLoading(false);
        setPhone('');
      })
      .catch(err => {
        if (err.message==="Failed to fetch") {
          showToastError("Comprueba la conexión a internet");
        }else{
          showToastError("Inténtalo más tarde. ");
        }
        setSpinnerLoading(false);
        setPhone('');
        return err;
      });
  }

    const handleChangeCountry = (event) => {
        setCounteCode(event.target.value);
    }

    return (
      <div className="content-login">
        <div className="capa-fondo"></div>
       
        
              <div className="login-wrapper d-flex align-items-center justify-content-center text-center login-form">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-sm-9 col-md-7 col-lg-7">
                      <div className="text-start px-4 text-center">
                      <img alt='Logo pautty.com' width={91} src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/logopauttycircle.png' />
                        <h1 className="mb-1 text-login-title" style={{ fontFamily: "Rampart One" }}><b>Pautty</b></h1>

                      </div>

                      <div className="otp-form mt-5 mx-4">
                        <form onSubmit={handleSubmitLogin}>
                          <div className=" d-flex">

                            {typeLogin==='email'&&
                              <select id="selectCountry" defaultValue={'57'} value={counteCode} onChange={handleChangeCountry} className="form-select" style={{ width: '100px' }} name=""
                                aria-label="Select country">
                                  <option value="1">+1</option>
                                <option value="57">+57</option>
                              </select>
                            }
                            
                            {typeLogin==='email'?
                              <input onChange={handleChange} className="form-control form-control-whatsapp" type="number"
                                placeholder="Número de celular" value={phone} minLength={9} maxLength={12}/>
                                :
                                <input onChange={handleChange} className="form-control form-control-whatsapp" type="email"
                                placeholder="Ingresa con tu Correo" autoComplete='true' value={phone} minLength={5} maxLength={50}/>
                            }

                          </div>
                          <br></br>
                          
                          <button disabled={disabledButton} className="btn btn-default color-bottons btn-lg w-100" type="submit"
                            id="doLoginUser">
                              Continuar
                              {spinnerLoading&&
                                <div className="d-flex justify-content-center" style={{float: 'right', paddingTop: 7}}>
                                  <span className="spinner-border spinner-border-sm button-sppiner-pauty" role="status" aria-hidden="true"></span>
                                </div>
                              }
                            </button>
                            
                            {/* <div style={{ paddingTop: 12, paddingBottom: 12 }}>
                              <a onClick={handleChangeTypeLogin} style={{ color: '#2F329F', textDecorationLine: 'underline', WebkitTextDecorationLine : 'underline', cursor: 'pointer' }}>Ingresar con {typeLoginText} <BsArrowRight /></a>
                            </div> */}
                            
                        </form>
                        
                      </div>

                      <div className="login-meta-data px-4">
                        <p className="mt-3 mb-0">Al proporcionar el correo electrónico, aceptas los <Link
                          className="mx-1 text-color-pauty" to="/terms">Términos y condiciones</Link>y las <Link
                            className="mx-1" style={{ color: '#f52d3f' }} to="/privacy-policies">Política de privacidad.</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
        

        <ToastContainer />
      </div>
    )

};
export default Login;