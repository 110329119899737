import React from 'react';
import '../css/Templates.css'
import { QRCodeCanvas } from 'qrcode.react';

const Tv = ({ itemPostScreen, elements, widthPreview, heightPreview, template, preview, isMovilVersion, isPlay, isMovilView}) => {

    // if (!isPlay) {
    //     if (!isMovilVersion) {
    //         if (parseInt(widthPreview) > parseInt(heightPreview)) {
    //             if (parseInt(widthPreview) > 1) {
    //                 widthPreview = widthPreview * (window.screen.width / 13);
    //                 heightPreview = heightPreview * (window.screen.width / 13);
    //             } else {
    //                 widthPreview = widthPreview * (window.screen.width / 6);
    //                 heightPreview = heightPreview * (window.screen.width / 6);
    //             }
    //         } else {
    //             if (parseInt(widthPreview) > 1) {
    //                 widthPreview = widthPreview * (window.screen.height / 11);
    //                 heightPreview = heightPreview * (window.screen.height / 11);
    //             } else {
    //                 widthPreview = widthPreview * (window.screen.height / 4);
    //                 heightPreview = heightPreview * (window.screen.height / 4);
    //             }
    //         }
    //     }
    // }

    let title_1 = "";
    let title_2 = "";
    let title_3 = "";
    let title_4 = "";
    let image_1 = "";
    let logo_1 = "";
    let video_1 = "";

    if (elements) {
        for (let c = 0; c < elements.length; c++) {
            if (elements[c].code_element==='title_1') {
                title_1 = elements[c];
            } else if (elements[c].code_element==='title_2') {
                title_2 = elements[c];
            } else if (elements[c].code_element==='title_3') {
                title_3 = elements[c];
            } else if (elements[c].code_element==='title_4') {
                title_4 = elements[c];
            } else if (elements[c].code_element==='image_1') {
                image_1 = elements[c];
            } else if (elements[c].code_element==='logo_1') {
                logo_1 = elements[c];
            } else if (elements[c].code_element==='video_1') {
                video_1 = elements[c];
            }
        }
    }   

    // Pauta aquí
    let templateHTML0 = `<div class="sombras-tv-card" style=" position: relative;background: ${itemPostScreen.color};"><div class="row" style="width: ${widthPreview}px; height: ${heightPreview}px;"> <div class="col-12 col-lg-6"  style="display: flex; text-align: center; align-items: center; background: ${itemPostScreen.color};">  <div class="template-text-center"><div><img alt="Logo pautty.com" class="animate__animated animate__backInDown animate__delay-1s" width="${widthPreview/10}" src="https://statics-gridwel.s3.us-east-2.amazonaws.com/img/logopauttycircle.png" /><br></br></div>   <div class="animate__animated animate__backInLeft animate__delay-3s">    <h2 class="animate__animated animate__infinite animate__heartBeat" style="font-size: ${(widthPreview/2)/title_1.size_element}px; font-family: &quot;${title_1.font_family}&quot;; color: ${title_1.color_element};">     ${title_1.text_content}</h2>   </div>   <div class="animate__animated animate__bounceInUp animate__delay-2s ">    <p     style="font-size: ${(widthPreview/2)/title_2.size_element}px; font-family: ${title_2.font_family}; color: ${title_2.color_element};">     ${title_2.text_content}</p>   </div>  </div> </div><div class="col-12 col-lg-6 image-pauta" style="background-image: url(${image_1.url_image});"></div></div></div>`;

    let templateHTML1 = `<div class="sombras-tv-card" style="position: relative;"> <div class="img-pauta-view-tv" style="background-image: url(&quot;${image_1.url_image}&quot;); width: ${widthPreview}px; height: ${heightPreview}px;"></div>  <div class="content-template2"> <div class="content-template2" style="background: ${itemPostScreen.color}; opacity: ${itemPostScreen.opaque};"> </div> <div class="content-center-template"> <div class="content-text-template2"> <div class="animate__animated animate__bounceInDown animate__delay-1s text-title-template2"> <h2 style="font-size: ${widthPreview/title_1.size_element}px; font-family: &quot;${title_1.font_family}&quot;; color: ${title_1.color_element};"> ${title_1.text_content}</h2> </div> <div class="animate__animated animate__bounceInUp animate__delay-2s text-description-template2"> <p style="font-size: ${widthPreview/title_2.size_element}px; font-family: &quot;${title_2.font_family}&quot;; color: ${title_2.color_element};"> ${title_2.text_content}</p> </div> </div> </div></div>  </div>`;
    
    let templateHTML2 = `<div class="sombras-tv-card" style=" position: relative;background: ${itemPostScreen.color};"><div class="row" style="width: ${widthPreview}px; height: ${heightPreview}px;"> <div class="col-6 image-pauta" style="background-image: url(${image_1.url_image});"></div> <div class="col-6" style="display: flex; text-align: center; align-items: center; background: ${itemPostScreen.color};"> <div class="template-text-center"> <div class="animate__animated animate__bounceInDown">  <h2 style="font-size: ${(widthPreview/2)/title_1.size_element}px; font-family: &quot;${title_1.font_family}&quot;; color: ${title_1.color_element};"> ${title_1.text_content}</h2> </div> <div class="animate__animated animate__bounceInUp animate__delay-1s ">  <p style="font-size: ${(widthPreview/2)/title_2.size_element}px; font-family: ${title_2.font_family}; color: ${title_2.color_element};"> ${title_2.text_content}</p> </div> </div> </div></div></div>`;
    let templateHTML2Responsive = `<div class="sombras-tv-card" style=" position: relative;background: ${itemPostScreen.color};"><div class="row" style="width: ${widthPreview}px; height: ${heightPreview}px;"> <div class="col-12 image-pauta" style="background-image: url(${image_1.url_image});"></div> <div class="col-12" style="display: flex; text-align: center; align-items: center; background: ${itemPostScreen.color};"> <div class="template-text-center"> <div class="animate__animated animate__bounceInDown">  <h2 style="font-size: ${(widthPreview/2)/title_1.size_element}px; font-family: &quot;${title_1.font_family}&quot;; color: ${title_1.color_element};"> ${title_1.text_content}</h2> </div> <div class="animate__animated animate__bounceInUp animate__delay-1s ">  <p style="font-size: ${(widthPreview/2)/title_2.size_element}px; font-family: ${title_2.font_family}; color: ${title_2.color_element};"> ${title_2.text_content}</p> </div> </div> </div></div></div>`;

    let templateHTML3 = `<div class="sombras-tv-card" style=" position: relative;background: ${itemPostScreen.color};">     <div class="row" style="width: ${widthPreview}px; height: ${heightPreview}px;"> <div class="col-6"     style="display: flex; text-align: center; align-items: center; background: ${itemPostScreen.color};">     <div class="template-text-center"> <div class="animate__animated animate__bounceInDown">     <h2 style="font-size: ${(widthPreview/2)/title_1.size_element}px; font-family: &quot;${title_1.font_family}&quot;; color: ${title_1.color_element};"> ${title_1.text_content}</h2> </div> <div class="animate__animated animate__bounceInUp animate__delay-1s ">     <p style="font-size: ${(widthPreview/2)/title_2.size_element}px; font-family: ${title_2.font_family}; color: ${title_2.color_element};"> ${title_2.text_content}</p> </div>     </div> </div> <div class="col-6 image-pauta" style="background-image: url(${image_1.url_image});"></div>     </div> </div>`;
    let templateHTML3Responsive = `<div class="sombras-tv-card" style=" position: relative;background: ${itemPostScreen.color};">     <div class="row" style="width: ${widthPreview}px; height: ${heightPreview}px;"> <div class="col-12"     style="display: flex; text-align: center; align-items: center; background: ${itemPostScreen.color};">     <div class="template-text-center"> <div class="animate__animated animate__bounceInDown">     <h2 style="font-size: ${(widthPreview/2)/title_1.size_element}px; font-family: &quot;${title_1.font_family}&quot;; color: ${title_1.color_element};"> ${title_1.text_content}</h2> </div> <div class="animate__animated animate__bounceInUp animate__delay-1s ">     <p style="font-size: ${(widthPreview/2)/title_2.size_element}px; font-family: ${title_2.font_family}; color: ${title_2.color_element};"> ${title_2.text_content}</p> </div>     </div> </div> <div class="col-12 image-pauta" style="background-image: url(${image_1.url_image});"></div>     </div> </div>`;

    let templateHTML4 = `<div class="sombras-tv-card" style=" position: relative;background: ${itemPostScreen.color};">     <div class="row" style="width: ${widthPreview}px; height: ${heightPreview}px;"> <div class="col-8"     style="display: flex; text-align: center; align-items: center; background: ${itemPostScreen.color};">     <div class="template-text-center"> <div class="animate__animated animate__bounceInDown">     <h2 style="font-size: ${(widthPreview/1.6)/title_1.size_element}px; font-family: &quot;${title_1.font_family}&quot;; color: ${title_1.color_element};"> ${title_1.text_content}</h2> </div> <div class="animate__animated animate__bounceInUp animate__delay-1s ">     <p style="font-size: ${(widthPreview/1.6)/title_2.size_element}px; font-family: ${title_2.font_family}; color: ${title_2.color_element};"> ${title_2.text_content}</p> </div>     </div> </div> <div class="col-4 image-pauta" style="background-image: url(${image_1.url_image});"></div>     </div> </div>`;
    let templateHTML5 = `<div class="sombras-tv-card" style=" position: relative;background: ${itemPostScreen.color};"><div class="row" style="width: ${widthPreview}px; height: ${heightPreview}px;"> <div class="col-8 image-pauta" style="background-image: url(${image_1.url_image});"></div> <div class="col-4"  style="display: flex; text-align: center; align-items: center; background: ${itemPostScreen.color};">  <div class="template-text-center">   <div class="animate__animated animate__bounceInDown">    <h2     style="font-size: ${(widthPreview/2.6)/title_1.size_element}px; font-family: &quot;${title_1.font_family}&quot;; color: ${title_1.color_element};">     ${title_1.text_content}</h2>   </div>   <div class="animate__animated animate__bounceInUp animate__delay-1s ">    <p     style="font-size: ${(widthPreview/2.6)/title_2.size_element}px; font-family: ${title_2.font_family}; color: ${title_2.color_element};">     ${title_2.text_content}</p>   </div>  </div> </div></div></div>`;
    
    let templateHTML6 = `<div class="sombras-tv-card" style=" position: relative;">
                            <div class="row " style="background-image: url(&quot;${image_1.url_image}&quot;);background-size: cover; width: ${widthPreview}px; height: ${heightPreview}px;">
                                    <div class="col-4">
                                    </div>
                                    <div class="col-4" style="display: flex; text-align: center; align-items: center; background: ${itemPostScreen.color};">
                                            <div class="template-text">
                                                    <div class="animate__animated animate__bounceInDown">
                                                            <h2 style=" font-size: ${(widthPreview/3) / title_1.size_element}px; font-family: ${title_1.font_family}; color: ${title_1.color_element};">
                                                                    ${title_1.text_content}</h2>
                                                    </div>
                                                    <div class="animate__animated animate__bounceInUp animate__delay-1s ">
                                                            <p style=" font-size: ${(widthPreview/3) / title_2.size_element}px; font-family: ${title_2.font_family}; color: ${title_2.color_element};">
                                                                    ${title_2.text_content}</p>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="col-4">
                                    </div>
                            </div>
                    </div>
                    </div>`;
    let templateHTML100 = `<div class="sombras-tv-card" style=" position: relative;background: ${itemPostScreen.color};"><video width="${widthPreview}" height="${heightPreview}" autoPlay loop muted><source src="${video_1.url_video}" type="video/mp4" />Your browser does not support the video tag.</video></div>`;

    return (
        <div>
            {template===0&&
                <div>
                    <div dangerouslySetInnerHTML={{__html: templateHTML0}}></div>
                    <div className="animate__animated animate__rotateIn animate__delay-4s" style={{ zIndex: 999, position: 'absolute', bottom: 0, right: 0, height: '100%', width: widthPreview/2, display: 'flex' }}>
                        <QRCodeCanvas id='canvaQRCodePauta' value={"https://pautty.com/"+itemPostScreen.code_checksum} size={widthPreview/2.5} includeMargin={true} style={{ margin: 'auto' }} />
                    </div>
                </div>
            }
            {template===1&&
                <div dangerouslySetInnerHTML={{__html: templateHTML1}}></div>
            }
            {template===2&&
                <>
                    {isMovilView?
                        <div dangerouslySetInnerHTML={{__html: templateHTML2Responsive}}></div>
                    :
                        <div dangerouslySetInnerHTML={{__html: templateHTML2}}></div>
                    }
                </>
            }
            {template===3&&
                <>
                    {isMovilView?
                        <div dangerouslySetInnerHTML={{__html: templateHTML3Responsive}}></div>
                    :
                        <div dangerouslySetInnerHTML={{__html: templateHTML3}}></div>
                    }
                </>
            }
            
            {template===4&&
                <div dangerouslySetInnerHTML={{__html: templateHTML4}}></div>
            }
            {template===5&&
                <div dangerouslySetInnerHTML={{__html: templateHTML5}}></div>
            }
            {template===6&&
                <div dangerouslySetInnerHTML={{__html: templateHTML6}}></div>
            }
            {template===100&&
                <div dangerouslySetInnerHTML={{__html: templateHTML100}}></div>
            }
{/* 
            <div style={{ zIndex: 999, position: 'absolute', bottom: 0, left: 0 }}>
                <QRCodeCanvas id='canvaQRCodePauta' value={"https://pautty.com/pauta/695"} height={250} width={250} size={250} includeMargin={true} />
            </div> */}

        </div>
    )
}

export default Tv;
