import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { deleteCookies } from '../deleteCookies';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import { useCookies } from 'react-cookie';
import HeaderBack from '../general/HeaderBack';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import { BsCameraVideo, BsEye, BsFillInfoCircleFill, BsFillPatchCheckFill, BsFillPlayCircleFill, BsFillTrashFill, BsPencil, BsPlus } from 'react-icons/bs';
import { Form, Modal } from 'react-bootstrap';
import PautaItem from '../valla/PautaItem';
import ButtonAddPauta from '../utils/ButtonAddPauta';
import { decryptData, encryptData } from '../crypto/pauttyCrypro';

const ListadoPautas = () => {
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();
    let { code_screen } = useParams();
    const [screen, setScreen] = useState();

    const [loading, setLoading] = useState(true);
    const [loadingPautas, setLoadingPautas] = useState(true);
    
    const [initStart, setInitStart] = useState(true);
    const [itemScreen, setItemScreen] = useState();

    const [postScreen, setPostScreen] = useState([]);

    const [showModalDeleteScreen, setShowModalDeleteScreen] = useState(false);

    const [showModalNewPauta, setShowModalNewPauta] = useState(false);

    const handleModalNewPautaClose = () => setShowModalNewPauta(false);
    const handleModalNewPautaShow = () => setShowModalNewPauta(true);

    const [ searchParams, setSearchParams ] = useSearchParams();

    const [pageCurrent, setPageCurrent] = useState(0);
    const [lastResults, setLastResults] = useState(true);

    useEffect(() => {
        if (initStart) {
            getScreensById();
            setInitStart(false);
            // GetUserPremiumMessage();
        }
    }, [initStart, searchParams]);

    const getScreensById = () => {
        setLoading(true);
        let dataHeaderCrypto = encryptData('/api/v1/screen/' + code_screen);
        fetch(config.urlApi + '/api/v1/screen/' + code_screen, {
            method: "GET",
            headers: {
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            }
        })
            .then((response) => response.json())
            .then(data => {
                var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
                dataPayload = JSON.parse(dataPayload);
                if (dataPayload.status === 404) {
                    showToastError(dataPayload.message);
                } else {
                    if (dataPayload.status === 401) {
                        deleteCookies();
                        setCookies('pathBefore', window.location.pathname, { path: '/' });
                        navigate('/login');
                    }
                    if (!dataPayload.status) {
                        setItemScreen(dataPayload.screen);
                        setScreen(dataPayload.screen.screen_id);
                        getListPostScreen(dataPayload.screen.screen_id, searchParams.get('status'), true);
                        setLoading(false);
                    } else {
                        showToastError(dataPayload.message);
                        setLoading(false);
                    }
                }
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
            });
    }

    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const showToastSuccess = (message) => {
        toast.success(message, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const [userPremiumMessage, setUserPremiumMessage] = useState(false);

    // const GetUserPremiumMessage = () => {
    //     fetch(config.urlApi + '/api/v1/user-premium-message/' , {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${cookies.access_token}`
    //         }
    //     })
    //         .then((response) => response.json())
    //         .then(data => {
    //             if (data){
    //                 setUserPremiumMessage(data.premium_messages);
    //             }
    //         })
    //         .catch(e => {
    //             return e;
    //         });
    // }

    

    const getListPostScreen = (screen, status, increment) => {
        setLoadingPautas(true);
        var urlFetch = "";
        if (!increment) {
            urlFetch = config.urlApi + '/api/v1/get-post-screen-partner/' + screen + '?page=' + 0 + '&status=' + status;
        }else{
            urlFetch = config.urlApi + '/api/v1/get-post-screen-partner/' + screen + '?page=' + pageCurrent + '&status=' + status;
        }

        let dataHeaderCrypto = encryptData('/api/v1/get-post-screen-partner/' + screen);

        fetch(urlFetch, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            }
        })
            .then((response) => response.json())
            .then(data => {
                var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
                dataPayload = JSON.parse(dataPayload);
                if (dataPayload.status) {
                    if (dataPayload.status===404) {
                        setLastResults(false);
                    }
                    if (dataPayload.status===401) {
                        deleteCookies();
                        setCookies('pathBefore', window.location.pathname, { path: '/' });
                        navigate('/login');
                    }
                }else{
                    if (pageCurrent === 0) {
                        setPostScreen(dataPayload);
                    }else{
                        setPostScreen(pauta => pauta.concat(dataPayload));
                    }
                }
                if (increment) {
                    setPageCurrent(pageCurrent + 1);   
                }
                setLoadingPautas(false);
            })
            .catch(e => {
                setLoadingPautas(false);
                // showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    const handleChangeValueStatusPauta = (status) => {
        setPostScreen([]);
        setSearchParams({status: status});
        getListPostScreen(screen, status, false);
        setLastResults(true);
    }

    const DeleteScreen = () => {
        setShowModalDeleteScreen(false);
        setLoadingPautas(true);

        let dataHeaderCrypto = encryptData('/api/v1/screen-active/' + screen);
        
        fetch(config.urlApi + '/api/v1/screen-active/' + screen, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            }
        })
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    setPostScreen([]);
                    showToastSuccess("Pantalla eliminada.");
                    setShowModalDeleteScreen(false);
                    setLoading(false);
                    navigate('/perfil?tab=vallas');
                } else {
                    showToastError(data.message);
                    if (data.status === 401) {
                        deleteCookies();
                        navigate('/login');
                    }
                    setLoading(false);
                }
                return data;
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setLoading(false);
                return e;
            });
    }

    const toggleFullScreen = (elem, status) => {

        if (status) {
          if (elem.requestFullScreen) {
            elem.requestFullScreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      }


    return (
        <div className="cart-wrapper-content" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '65px', maxWidth: 1200, margin: 'auto', background: '#fff'}}>

            <HeaderBack urlBack={'/perfil?tab=vallas'} title={''} needLogin={true} />

            {loading&&
                <SpinnerLoadingFixed />
            }

                <>
                    {postScreen &&
                        <div style={{ height: 'auto' }}>

                            <button className='btn btn-info btn-show-preview-pautas' style={{ background: '#F7CF57', color: '#292929', border: 'none' }} onClick={()=>{toggleFullScreen(document.body, true);navigate('/play/'+itemScreen.code_checksum);}}><BsFillPlayCircleFill size={25} /> {' '}Play</button>

                            <button className='btn btn-info btn-edit-pautas' onClick={()=>{navigate('/editar-medio-publicitario/'+itemScreen.code_checksum)}}>Editar <BsPencil />{' '}</button>
                            <button className='btn btn-danger btn-delete-pautas' onClick={()=>{setShowModalDeleteScreen(true)}}><BsFillTrashFill />{' '}</button>
                            {itemScreen&&
                                <div className='text-title-valla'>
                                {itemScreen.valid_screen ?
                                    <h2>{itemScreen.name} {' '}
                                        <span title='Verificado por el equipo de Pautty'>
                                            <BsFillPatchCheckFill color='#49ADF4' />
                                        </span>
                                    </h2>
                                    :
                                    <>
                                        <h2>{itemScreen.name} <button className='btn btn-default' onClick={()=>{navigate('/'+itemScreen.code_checksum)}}>Preview <BsEye />{' '}</button></h2>
                                        {(itemScreen.type_service_app==="valla" || itemScreen.type_service_app==="valla-digital")&&
                                            <span> Tamaño: <b>{config.list_diomensions_screen[itemScreen.width_screen_active].name}</b></span>
                                        }
                                    </>
                                }
                                </div>
                            }


                            <div className='content-tabs-menu'>
                            <div style={{ margin: 'auto', background: '#eaeaea', borderRadius: 10 }}>
                                <div className='tabs-menu-medios tabs-menu-medios-pautas' style={{ overflowX: 'scroll' }}>
                                    {/* <div className="radio-filters">
                                        <label htmlFor="todos-switch">Todos</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="todos-switch"
                                            defaultChecked="true"
                                            onClick={()=>{handleChangeValueStatusPauta('');setPageCurrent(1);}}
                                        />
                                    </div> */}
                                    <div className="radio-filters">
                                        <label htmlFor='registrado-switch'>{config.list_status_pauta[0].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="registrado-switch"
                                            defaultChecked={searchParams.get('status')==="0"?true:false}
                                            onClick={()=>{handleChangeValueStatusPauta('0');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    {/* <div className="radio-filters">
                                        <label htmlFor='recibidos-switch'>Recibidos</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="recibidos-switch"
                                            defaultChecked={searchParams.get('status')==="1"?true:false}
                                            onClick={()=>{handleChangeValueStatusPauta('1');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div> */}
                                    {itemScreen&&
                                    <>
                                    {itemScreen.is_payment&&
                                        <div className="radio-filters">
                                            <label htmlFor='pagados-switch'>{config.list_status_pauta[2].name}</label>
                                            <Form.Check
                                                type="radio"
                                                name="group-status"
                                                id="pagados-switch"
                                                defaultChecked={searchParams.get('status')==="2"?true:false}
                                                onClick={()=>{handleChangeValueStatusPauta('2');setPageCurrent(1);}}
                                            />
                                            <hr className="hr-radio-filters"></hr>
                                        </div>
                                    }
                                    
                                    {itemScreen.is_approval_automatic&&
                                    <div className="radio-filters">
                                        <label htmlFor='aprobados-switch'>{config.list_status_pauta[3].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="aprobados-switch"
                                            defaultChecked={searchParams.get('status')==="3"?true:false}
                                            onClick={()=>{handleChangeValueStatusPauta('3');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    }
                                    </>
                                    }
                                    
                                    
                                    {/* {itemScreen&&
                                        <div className="radio-filters">
                                            <label htmlFor='en-proceso-switch'>{config.list_status_pauta[4].name}</label>
                                            <Form.Check
                                                type="radio"
                                                name="group-status"
                                                id="en-proceso-switch"
                                                defaultChecked={searchParams.get('status')==="4"?true:false}
                                                onClick={()=>{handleChangeValueStatusPauta('4');setPageCurrent(1);}}
                                            />
                                            <hr className="hr-radio-filters"></hr>
                                        </div>
                                    } */}
                                    <div className="radio-filters">
                                        <label htmlFor='publicados-switch'>{config.list_status_pauta[5].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="publicados-switch"
                                            defaultChecked={searchParams.get('status')==="5"?true:false}
                                            onClick={()=>{handleChangeValueStatusPauta('5');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='finish-switch'>{config.list_status_pauta[6].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="finish-switch"
                                            defaultChecked={searchParams.get('status')==="6"?true:false}
                                            onClick={()=>{handleChangeValueStatusPauta('6');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='rejected-switch'>{config.list_status_pauta[7].name}</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="rejected-switch"
                                            defaultChecked={searchParams.get('status')==="7"?true:false}
                                            onClick={()=>{handleChangeValueStatusPauta('7');setPageCurrent(1);}}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            {/* {statusPauta==='0'&& */}
                                <div className='content-add-pauta-partner'>
                                    <button onClick={handleModalNewPautaShow}>
                                        Pautar
                                    </button>
                                </div>
                            {/* } */}

                            {postScreen.length>0?
                            <>                               

                                {postScreen.map((item, index) => {
                                    item.type_service_app = itemScreen.type_service_app;
                                    return <PautaItem key={index} item={item} index={index} screen={screen} setPostScreen={setPostScreen} valla={itemScreen} userPremiumMessage={userPremiumMessage} isPartner={true} showToastError={showToastError} />
                                })}


                                {loadingPautas ?
                                    <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                                        <h1 className="text-login-title" style={{ margin: 'auto' }}>
                                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                                        </h1>
                                    </div>
                                    :
                                    <div>
                                        {lastResults&&
                                            <button onClick={()=>{getListPostScreen(screen, searchParams.get('status'), true);}} className="button-12" style={{ cursor: 'pointer', border: 'none', borderRadius: 15, marginBottom: 50 }}>
                                                Cargar más
                                            </button>
                                        }
                                    </div>
                                }

                            </>
                            :
                                <div style={{ width: '100%', textAlign: 'center', paddingTop: 10 }}>
                                    <BsFillInfoCircleFill color='#838080' size={50} />
                                    <h3 style={{ paddingTop: 10, color: '#838080' }}>No tienes Pautas</h3>
                                </div>
                            }
                                
                            </div>

                        </div>
                    }

                </>

                {/* <div className='responsiveNavBarFooter sombras-tv-card create-pauta-user'>
                    <button onClick={handleModalNewPautaShow}>
                        <BsPlus size={40} color='#fff' /> Pautar
                    </button>
                </div> */}
{/* 
                <div className='responsiveNavBarFooter sombras-tv-card create-pauta-user'>
                    <button onClick={()=>{
                        navigate('/video-generator/');
                    }}>
                        <BsCameraVideo size={40} color='#fff' /> Generar
                    </button>
                </div> */}
           

                {loadingPautas &&
                    <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                        <h1 className="text-login-title" style={{ margin: 'auto' }}>
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </h1>
                    </div>
                }





                {itemScreen &&
                <>

                <ButtonAddPauta 
                    itemScreen={itemScreen} 
                    showModalNewPauta={showModalNewPauta} 
                    handleModalNewPautaClose={handleModalNewPautaClose} 
                    showToastError={showToastError}
                />


                <Modal
                    show={showModalDeleteScreen}
                    dialogClassName="modal-90w"
                    centered
                    onHide={() => setShowModalDeleteScreen(false)}>
                    <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Eliminar Pantalla</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Si eliminas la pantalla no podrás recuperarla.</p>
                        {/* <button className='btn btn-default'>Desactivar</button> */}
                        <button className='btn btn-danger' onClick={DeleteScreen}>Eliminar</button>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={showModalDeleteScreen}
                    dialogClassName="modal-90w"
                    centered
                    onHide={() => setShowModalDeleteScreen(false)}>
                    <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Eliminar Pantalla</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Si eliminas la pantalla no podrás recuperarla.</p>
                        {/* <button className='btn btn-default'>Desactivar</button> */}
                        <button className='btn btn-danger' onClick={DeleteScreen}>Eliminar</button>
                    </Modal.Body>
                </Modal>
                </>
            }


            <ToastContainer />


        </div>
    )
}

export default ListadoPautas;