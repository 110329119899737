import React, { Component } from 'react';
import HeaderBack from '../HeaderBack';
import { BsFillPatchCheckFill } from 'react-icons/bs';

export class Terms extends Component {

  render() {
    return(
     <>
        <HeaderBack urlBack={''} title={'Términos y condiciones de Pautty'} needLogin={false} />
        <br></br>
        <br></br>
        <div style={{ paddingTop: 50, padding: 20, textAlign: 'center' }}>
          <h2>Términos y Condiciones de Uso Pautty</h2>
          <h4>1. INFORMACIÓN RELEVANTE</h4>
          <p>Es requisito necesario para la adquisición de los productos que se ofrecen en este sitio, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios así como la compra de nuestros productos implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. Todos los productos y promociones ofrecidos por terceros y publicados en nuestra plataforma (Pautty), estarán sujetos a sus propios Términos y Condiciones. Para adquirir un producto, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos. Validaremos tu correo por medio de un código que enviaremos.</p>
          <p>Para el registro enviaremos una clave de acceso. En caso de que se haya registrado y que sea necesario para la compra de alguno de nuestros productos, no asumimos la responsabilidad en caso de que entregue dicha clave a terceros.</p>
          <p>Todas las compras y transacciones que se lleven a cabo por medio de este sitio web, están sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación de disponibilidad de servicio, validación de la forma de pago, validación de la factura (en caso de existir) y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera una verificación por medio de correo electrónico.</p>
          <p>Las compras en este sitio web, deben ser realizadas por una persona mayor de edad. De lo contratio deben estar acompañados de un adulto responsable. </p>
          <h4>2. LICENCIA</h4>
          <p>Pautty a través de su sitio web concede una licencia para que los usuarios utilicen las herramientas y servicios en este sitio de acuerdo a los Términos y Condiciones que se describen en este documento.</p>
          <h4>3. USO NO AUTORIZADO</h4>
          <p>En caso de que aplique (para venta de software, templetes, u otro producto de diseño y programación) usted no puede colocar uno de nuestros productos, modificado o sin modificar, en un CD, sitio web o ningún otro medio y ofrecerlos para la redistribución o la reventa de ningún tipo.</p>
          <h4>4. PROPIEDAD</h4>
          <p>Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar. Los productos publicitados son propiedad de los proveedores del contenido. En caso de que no se especifique lo contrario, nuestros productos se proporcionan sin ningún tipo de garantía, expresa o implícita. En ningún esta compañía será responsables de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos.</p>
          <h4>5. POLÍTICA DE REEMBOLSO Y GARANTÍA</h4>
          <p>En el caso de productos y promociones de terceros, no nos hacemos responsables de fallos o daños en en los productos ofrecidos por ellos. El sitio web únicamente será el canal que ayude a propagar información del medio de publicidad. En caso de fallas o daños o cualquier tipo de inconveniente con los productos promocionados en nuestro sitio web, tendrá que dirigirse directamente a la entidad tercera.</p>
          {/* <p>Después de realizado el pago en nuestro sitio web y después de que nuestro sitio web haga publicidad en alguna de las vallas asociadas, no se realizarán desembolsos. En caso de fallos en las vallas seleccionadas, tendrá la opción de seleccionar otro horario de publicación u otra pantalla. </p> */}
          <h4>6. COMPROBACIÓN ANTIFRAUDE</h4>
          <p>La compra del cliente puede ser aplazada para la comprobación antifraude. También puede ser suspendida por más tiempo para una investigación más rigurosa, para evitar transacciones fraudulentas.</p>
          <h4>7. PRIVACIDAD</h4>
          <p>Este garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Los datos ingresados por usuario o en el caso de requerir una validación de los pedidos no serán entregados a terceros, salvo que deba ser revelada en cumplimiento a una orden judicial o requerimientos legales. La suscripción a boletines de correos electrónicos publicitarios es voluntaria y podría ser seleccionada al momento de crear su cuenta. Pautty se reserva los derechos de cambiar o de modificar estos términos sin previo aviso.</p>
          <h4>8. TARIFAS</h4>
          <p>Los precios serán escogidos por cada dueño de valla o medio de publicidad y pueden variar según el tamaño, ubicación y día o temporada.</p>
          <p>El estudio para la verificación de los perfiles tiene un costo de $25.000</p>
          {/* <p>Se realizará el desembolso de las ganancias a los dueños de vallas los últimos días de cada mes y Pautty tomará un porcentaje por el uso de los servicios.</p> */}
          <h4>9. TÉRMINOS DE USO</h4>
          <p>Las publicaciones serán validadas por nuestro equipo para evitar que se publiquen cosas indebidas tales como: contenido para adultos, informción no verídica, contenido que perjudique la integridad de otras personas, publicaciones homofóbicas, rasistas u otro tipo de publicación parecida, palabras obscenas o publicidad engañosa, productos ilícitos o cualquier publicación ilegal. Cumpliendo con el código Colombiano de Autorregulación Publicitaria.</p>
          {/* <p>El usuario tendrá la opción de escoger el día en el que desea visualizar la publicación.</p> */}
          <p>El usuario no podrá anunciar armas de fuego, juegos, juguetes o implementos bélicos.</p>
          <p>La información de los medios de publicidad verificados por el equipo de Pautty serán validados para darle seguridad a los clientes y se identificará con el ícono (<BsFillPatchCheckFill color='#49ADF4' />).</p>
          <p>El usuario podrá realizar seguimiento de las pautas en cada medio con los siguientes estados, (<b>REGISTRADO, RECIBIDO, PAGO, APROBACIÓN DE DISEÑO, EN PROECSO DE PUBLICACIÓN, PUBLICADO, FINALIZADO y RECHAZADO</b>)</p>
          {/* <p>Las vallas registradas, tendrán que ser de exposición interna en los negocios o locales y no en sitio públicos, de lo contrario haremos el proceso de cancelación de cuenta.</p> */}
          {/* <p>Al registrar la pantalla en Pautty, se da el consentimiento para utilizar la pantalla para publicidad.</p> */}
          <h4>10. TÉRMINOS DE USO PARA ADMINISTRADOR DE PANTALLA PUBLICITARIA </h4>
          {/* <p>Al aceptar estos términos de uso, el administrador o encargado le da a pautty.com los permisos para anunciar publicidad de otras entidades, recibiendo así la mayor parte de las ganancias.</p> */}
          <p>Podrán cerrar la cuenta o eliminar el registro del medio de publicidad en nuestra base de datos.</p>
          <p>El equipo de Pautty realizará una validación para asegurarse que los medios de publicidad de anuncios tenga las características adecuadas y acordes a las registradas en la plataforma.</p>
          <p>Se podrá solicitar la verificación enviando un correo a info@pautty.com para obtener el ícono de verificación (<BsFillPatchCheckFill color='#49ADF4' />)</p>
          <p>Tendrá la opción para hacer los cambios de estado de cada pauta para su seguimiento.</p>
          <h4>11. SANCIONES</h4>
          <p>En caso de que los partners dueños de las vallas registradas no cumplan con los horarios propuestos por ellos mismos, o no permitan visualizar correctamente las publicaciones de los usuarios, los dueños serán sancionados y se inactivarán los medios de publicidad temporalmente.</p>
          <p>Si el equipo de Pautty detecta usuarios que no cumplen con nuestros términos y condiciones, podrán ser sancionados con la suspensión o bloqueo de la cuenta y los dueños, encargados o arrendatarios de las vallas u otro medio, asumirán las multas al no cumplir las reglas establecidas.</p>
          <p>Los anuncios de Pautty.com deben ser validados por el administrador del medio de publicidad previamente acordado con el propietario o poseedor. Pautty no se hará responsable de multas por incumplimientos de parte de los administradores, arrendatarios o encargados de las vallas o medios de publicidad, que hagan mal uso de pautty.com</p>
          <p>Última actualización el 23 de noviembre de 2024</p>
        </div>
     </>
    )
  }

}