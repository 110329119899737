import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FcHome, FcManager } from "react-icons/fc";
import { BsBellFill, BsTv } from 'react-icons/bs';
import { AiFillNotification } from 'react-icons/ai';
import { MdLocationPin } from 'react-icons/md';
import { encryptData } from '../crypto/pauttyCrypro';
import { useCookies } from 'react-cookie';
import { deleteCookies } from '../deleteCookies';
import { config } from '../configPautty';

const NavBarFooter = () => {
    const [showMenuButtonCenter, setShowMenuButtonCenter] = useState(false);
    const [cookies] = useCookies(['user']);
    const navigate = useNavigate();

    const handleShowMenuButtonCenter = () => {
        setShowMenuButtonCenter(!showMenuButtonCenter);
    }

    const [notificationsCount, setNotificationsCount] = useState(0);

    useEffect(() => {
        getCountNotificationsByUser(); 
      }, []);
    
      const getCountNotificationsByUser = () => {
    
        let dataHeaderCrypto = encryptData('/api/v1/notifications-count/');
        fetch(config.urlApi + '/api/v1/notifications-count/', {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1],
                'Accept': 'application/json'
            },
            mode: 'cors' 
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status === 401) {
                    deleteCookies();
                    navigate('/login');
                } else {
                    if (!data.status) {
                        setNotificationsCount(data);
                    }
                }
                return data;
            })
            .catch(e => {
                return e;
            });
    }

    return (
        <div className='responsiveNavBarFooter'>
            
            <footer className="footer-nav-wrapper">
                <div className="container">
                    <div className="footer-nav-menu">
                      
                        <Link className={window.location.pathname==="/" ? "footMenu active":"footMenu"} to="/">
                            <i><FcHome/></i>
                            <span>Inicio</span>
                        </Link>

                        {/* {!showMenuButtonCenter ?
                            <button className='button-plus-center' onClick={()=>handleShowMenuButtonCenter(true)}>
                                <BsFillPlusCircleFill color='#F7CF57' size={55} />
                            </button>
                            :
                            <button className='button-plus-center' onClick={()=>handleShowMenuButtonCenter(true)}>
                                <AiFillCloseCircle color='#dc3545' size={55} />
                            </button>
                        } */}
                        
                        
                        <Link className={window.location.pathname==="/mapa" ? "footMenu active":"footMenu"} to="/mapa">
                            <i><MdLocationPin color='#dc3545' /></i>
                            <span>Mapa</span>
                        </Link>

                        <Link className={window.location.pathname.includes("/notifications") ? "footMenu active":"footMenu"} to="/notifications">
                            <i className={notificationsCount>0 ? 'animate__animated animate__tada animate__infinite animate__slow':''}><BsBellFill color='#438ced'/>
                                {notificationsCount>0&&
                                    <span className='notificationIconMenuFooter'>{notificationsCount}</span>
                                }
                            </i>
                            <span>Novedades</span>
                        </Link>

                        <Link className={window.location.pathname.includes("/perfil") ? "footMenu active":"footMenu"} to="/perfil?tab=user">
                            <i><FcManager/></i>
                            <span>Perfil</span>
                        </Link>

                        {/* <Link className={window.location.pathname==="/mi-perfil" ? "footMenu active":"footMenu"} to="/mi-perfil">
                            <i><FcManager /></i>
                            <span>Perfil</span>
                        </Link> */}

                        {/* <Link className={window.location.pathname==="/promociones" ? "footMenu active":"footMenu"} to="/promociones">
                            <i><FcBullish/></i>
                            <span>Análisis</span>
                        </Link> */}

                    {showMenuButtonCenter&&
                        <div className='modal-options-button-center animate__animated animate__bounceInUp'>
                            <div className='search-tags' style={{ width: '100%', padding: 25 }}>
                                <Link to="/mis-pantallas"><i><BsTv /></i> Mis Pantallas</Link>
                            </div>
                            <div className='search-tags' style={{ width: '100%', padding: 25 }}>
                                <Link to="/my-posts"><i><AiFillNotification /></i> Pautar</Link>
                            </div>
                        </div>
                    }
                        
                    </div>
                </div>
            
            </footer>

            {showMenuButtonCenter&&
                <a onClick={()=>handleShowMenuButtonCenter(true)} className='background-menu-center'></a>
            }

        </div>
    )
}

export default NavBarFooter;