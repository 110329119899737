import CryptoJS from "crypto-js";
export const encryptData = (data, body) => {
    var keys = new Array();
    const keyGenerated = CryptoJS.lib.WordArray.random(8).toString(CryptoJS.enc.Hex);     
    const ivGenerated = CryptoJS.lib.WordArray.random(8).toString(CryptoJS.enc.Hex); 
    const keyIvEncryption = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);     
    const keyIvEncryption1 = CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Hex);     
    const keyIvEncryption2 = CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Hex);     
        
    const key = CryptoJS.enc.Utf8.parse(keyGenerated); 
    const iv = CryptoJS.enc.Utf8.parse(ivGenerated);    

    const encryptedBody = CryptoJS.AES.encrypt(body, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    keys[0] = keyIvEncryption+"__"+keyGenerated+ivGenerated+"__"+keyIvEncryption+"__"+keyIvEncryption1+keyIvEncryption2;
    keys[1] = CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
    const encryptedBase64Body = encryptedBody.ciphertext.toString(CryptoJS.enc.Base64);
    keys[2] = encryptedBase64Body;

    return keys;
  };
  


export const decryptData = (encryptedBase64, keyData) => {
    
    keyData = keyData.split("__")[1];
    const keyHex = keyData.slice(0, 16);
    const ivHex = keyData.slice(16);

    const key = CryptoJS.enc.Utf8.parse(keyHex);
    const iv = CryptoJS.enc.Utf8.parse(ivHex);

    const encryptedCiphertext = CryptoJS.enc.Base64.parse(encryptedBase64);

    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedCiphertext },
        key,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );    
    let decryptedText = decrypted.toString(CryptoJS.enc.Utf8);;
    return decryptedText;
};
  
  