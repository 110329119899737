import React, { useRef, useState } from 'react';

const VideoFromImages = () => {
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const images = [
    'https://images.unsplash.com/photo-1465146344425-f00d5f5c8f07?w=600&auto=format&fit=crop&q=60',
    'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80',
    'https://images.unsplash.com/photo-1645008404465-82d16e726baf?q=80&w=1771&auto=format&fit=crop',
    'https://images.unsplash.com/photo-1725203574073-79922f64110a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNzQ4Mjh8MHwxfGFsbHwyfHx8fHx8fHwxNzI3ODQ3MTYyfA&ixlib=rb-4.0.3&q=80&w=400',
    'https://images.unsplash.com/photo-1725203574073-79922f64110a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNzQ4Mjh8MHwxfGFsbHwyfHx8fHx8fHwxNzI3ODQ3MTYyfA&ixlib=rb-4.0.3&q=80&w=400',
    'https://images.unsplash.com/photo-1727775805114-a87c6bcaf9db?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNzQ4Mjh8MHwxfGFsbHw0fHx8fHx8fHwxNzI3ODQ3MTYyfA&ixlib=rb-4.0.3&q=80&w=400'
  ];

  // Dibuja una imagen en el canvas
  const drawImageOnCanvas = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;

      img.onload = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);
        resolve();
      };

      img.onerror = (error) => {
        console.error('Error al cargar la imagen:', error);
        resolve(); // Asegurarse de que la promesa se resuelva incluso en caso de error
      };
    });
  };

  const startRecording = async () => {
    const canvas = canvasRef.current;
    const stream = canvas.captureStream(30); // Captura el stream del canvas a 30 FPS
    const mediaRecorder = new MediaRecorder(stream);
    const chunks = [];

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const videoBlob = new Blob(chunks, { type: 'video/webm' });
      const videoUrl = URL.createObjectURL(videoBlob);
      setVideoUrl(videoUrl);
      setIsRecording(false);
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setIsRecording(true);

    // Itera sobre las imágenes y las dibuja en el canvas
    for (const imageUrl of images) {
      await drawImageOnCanvas(imageUrl);
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }

    // Detener la grabación después de que se hayan dibujado todas las imágenes
    mediaRecorder.stop();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  return (
    <div>
      <h2>Generar Video desde Imágenes</h2>
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      <div>
        <button onClick={startRecording} disabled={isRecording}>
          Iniciar Grabación
        </button>
        <button onClick={stopRecording} disabled={!isRecording}>
          Detener Grabación
        </button>
      </div>
      {videoUrl && (
        <div>
          <h3>Video Generado:</h3>
          <video ref={videoRef} src={videoUrl} controls width="640" height="480"></video>
        </div>
      )}
    </div>
  );
};

export default VideoFromImages;
