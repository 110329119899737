import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { config } from '../configPautty';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import { encryptData } from '../crypto/pauttyCrypro';
import { deleteCookies } from '../deleteCookies';

const ButtonAddPauta = ({itemScreen, showModalNewPauta, handleModalNewPautaClose, showToastError }) => {

    const [loading, setLoading] = useState(true);
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();

    const [stepCurrent, setStepCurrent] = useState(0);

    const [textTitlePauta, setTextTitlePauta] = useState("The Burguer");
    const [textDescriptionPauta, setTextDescriptionPauta] = useState("Super Delicious Food");

    const [templateSelected, setTemplateSelected] = useState(1);

    const [buttonPautarDisabled, setButtonPautarDisabled] = useState(true);
    
    const SelectedComponentTemplate = ({ item }) => {
        return (
          <button 
            className='col-xl-6 col-lg-6 col-md-6 col-sm-12 button-template-item' 
            title='Seleccionar' 
            onClick={() => { 
                setTemplateSelected(item.id); 
                setStepCurrent(1);
                setButtonPautarDisabled(false);
                }}>
            {item.category === 'video' ?
              <video width="100%" height="100%" autoPlay loop muted>
                <source src={item.url_content} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              :
              <img alt={item.name} src={item.url_content} />
            }
    
    
          </button>)
      }

    const changeTextTitlePauta = (event) => {
        setTextTitlePauta(event.target.value);
    }
    const changeTextDescriptionPauta = (event) => {
        setTextDescriptionPauta(event.target.value);
    }


      const handleSubmitAddInitPostScreen = event => {
        event.preventDefault();
        
        if (textTitlePauta.length===0) {
            showToastError("Agrega un título");
            return
        }
        
        const objectPauta = {
            name: textTitlePauta,
            type_post: 'valla',
            originator_post: 'L',
            option_style: templateSelected,
            description: textDescriptionPauta,
            elements_data: [
                {
                element_post_id: 1,
                code_element: 'title_1',
                type_element: 'text',
                type_file: '',
                background_element: '',
                color_element: '#ffffff',
                border_radius_element: '',
                font_family: 'Rubik Mono One',
                text_content: textTitlePauta,
                col_row_boostrap: '',
                is_shadow: '',
                active: false,
                order_element: 0,
                name_action: '',
                name_icon: '',
                url_info: '',
                url_image: '',
                url_image_textura: '',
                url_video: '',
                position_x: '',
                position_y: '',
                size_element: '10',
                size_width: '',
                size_height: '',
                post_screen_id: 0
            },{
                element_post_id: 2,
                code_element: 'title_2',
                type_element: 'text',
                type_file: '',
                background_element: '',
                color_element: '#ffffff',
                border_radius_element: '',
                font_family: 'Special Elite',
                text_content: textDescriptionPauta,
                col_row_boostrap: '',
                is_shadow: '',
                active: false,
                order_element: 0,
                name_action: '',
                name_icon: '',
                url_info: '',
                url_image: '',
                url_image_textura: '',
                url_video: '',
                position_x: '',
                position_y: '',
                size_element: '40',
                size_width: '',
                size_height: '',
                post_screen_id: 0
            },{
                element_post_id: 3,
                code_element: 'image_1',
                type_element: 'image',
                type_file: '',
                background_element: '',
                color_element: '',
                border_radius_element: '',
                font_family: '',
                text_content: 'Imagen Principal',
                col_row_boostrap: '',
                is_shadow: '',
                active: false,
                order_element: 0,
                name_action: '',
                name_icon: '',
                url_info: '',
                url_image: 'https://images.unsplash.com/photo-1561758033-f8ff74d6494a?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzNzQ4Mjh8MHwxfHNlYXJjaHwzMTB8fGNvbWlkYXxlbnwwfHx8fDE3MTYyNDczMDJ8MA&ixlib=rb-4.0.3&q=85',
                url_image_textura: '',
                url_video: '',
                position_x: '',
                position_y: '',
                size_element: '',
                size_width: '',
                size_height: '',
                post_screen_id: 0
            },{
                element_post_id: 4,
                code_element: 'video_1',
                type_element: 'video',
                type_file: '',
                background_element: '',
                color_element: '',
                border_radius_element: '',
                font_family: '',
                text_content: 'Video Principal',
                col_row_boostrap: '',
                is_shadow: '',
                active: false,
                order_element: 0,
                name_action: '',
                name_icon: '',
                url_info: '',
                url_image: '',
                url_image_textura: '',
                url_video: 'https://statics-gridwel.s3.us-east-2.amazonaws.com/videoPost/1_user_video_post_202308181604266487346050005.mp4',
                position_x: '',
                position_y: '',
                size_element: '',
                size_width: '',
                size_height: '',
                post_screen_id: 0
            }],
        }
        objectPauta.screen_id=parseInt(itemScreen.screen_id);
        objectPauta.type_post=itemScreen.type_service_app;

        if (itemScreen.type_service_app==='valla') {
            objectPauta.elements_data = objectPauta.elements_data.filter(element => element.element_post_id !== 4);
        }

        setLoading(true);
        setButtonPautarDisabled(true);

        let dataHeaderCrypto = encryptData('/api/v1/post-screen/', JSON.stringify(objectPauta));

        fetch(config.urlApi + '/api/v1/post-screen/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            },
            body: JSON.stringify({payload: dataHeaderCrypto[2]})
        })
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    navigate('/editar-pantalla/' + itemScreen.screen_id + '/editar-contenido/' + data);
                } else {
                    if (data.status === 401) {
                        deleteCookies();
                        setCookies('pathBefore', window.location.pathname, { path: '/' });
                        navigate('/login');
                      }
                    showToastError(data.message);
                }
                setLoading(false);
                setButtonPautarDisabled(false);
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setLoading(false);
                setButtonPautarDisabled(false);
                return e;
            });
    }
    

    return (
        <div>
          
          
          <Modal 
                show={showModalNewPauta} 
                onHide={()=>{
                    handleModalNewPautaClose();
                    setStepCurrent(0);
                    setButtonPautarDisabled(true);
                }}
                centered
                >
                <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                    <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Crea tu Pauta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-add-pauta-list'>
                        
                        {stepCurrent===0&&
                            <div className='row'>
                                <div className='title-modal-new-pauta'>
                                    <span>Selecciona el estilo que te guste</span>
                                </div>
                                {config.list_options_templates_tabs.map((item) => {
                                    
                                    if (itemScreen.type_service_app==='valla') {
                                        if (item.category!=='video') {
                                            return <SelectedComponentTemplate item={item} key={item.id} />   
                                        }
                                    }else{
                                        return <SelectedComponentTemplate item={item} key={item.id} />
                                    }
                                })}
                            </div>
                        }

                        {stepCurrent===1&&
                            <div className='row'>
                                <div className='title-modal-new-pauta'>
                                        <span>Agrega un título</span>
                                </div>
                                <div className='edit-pautty-form' style={{ marginTop: 10 }}>
                                    <div className='col-12'>
                                        <input
                                            onChange={(e) => { changeTextTitlePauta(e) }}
                                            placeholder='Ingresa un título'
                                            value={textTitlePauta}
                                            maxLength={50}
                                            className='input-form-pautty'
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <textarea
                                            onChange={(e) => { changeTextDescriptionPauta(e) }}
                                            placeholder='Ingresa una descripción'
                                            value={textDescriptionPauta}
                                            maxLength={200}
                                            className='input-form-pautty'
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        

                    </div>
                </Modal.Body>
                
                <Modal.Footer>
                <button 
                    className='btn btn-default' 
                    variant="secondary" onClick={()=>{
                            handleModalNewPautaClose();
                            setStepCurrent(0);
                            setButtonPautarDisabled(true);
                }}>
                    Cancelar
                </button>
                
                {!loading?
                    <SpinnerLoadingFixed />
                :
                <button disabled={buttonPautarDisabled} className='btn color-pautty-red' variant="primary" onClick={handleSubmitAddInitPostScreen}>
                    Crear Pauta
                </button>
                }
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default ButtonAddPauta;