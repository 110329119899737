import React from 'react';
import { BsCardChecklist, BsCardImage, BsCollectionPlayFill, BsEye, BsFillPlayCircleFill, BsFillPatchCheckFill, BsFillPenFill, BsFillTrashFill, BsPenFill, BsPencil, BsPlay, BsShare } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { config } from '../../configPautty';
let dollarIndianLocale = Intl.NumberFormat('es-CO');

const ItemCard = ({item, tabShowGetScreens, handleOpenModalSharing, isPauter}) => {

    const navigate = useNavigate();
    
    return <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 card-home-content' 
            onClick={()=>{
            if (isPauter) {
                navigate('/'+item.code_checksum);
            }else{
                navigate('/pautas/'+item.code_checksum+ '?status=0');    
            }                                                                                            
            }}>
                
                     <div className='card-home-content-body'>
                        <div className='img-card-home' style={{ backgroundImage: 'url('+item.photo_tv+')', height: window.screen.height/2.7 }}>
                            
                            {((item.type_service_app==='valla' || item.type_service_app==='valla-digital'))&&
                                <div>
                                    {(item.width_screen_active!=='')&&
                                        <div className='content-item-card-sizes'>
                                            <span>{config.list_diomensions_screen[item.width_screen_active].name}</span>
                                        </div>
                                    }
                                    {(item.address_location!=='' && item.address_location!==undefined)&&
                                    <>
                                        <div className='content-item-card-location'>
                                        </div>
                                        <div className='content-item-card-location-span'>
                                            <MdLocationOn color='#ffffff' />
                                            <span>{' '}{item.address_location}</span>
                                        </div>
                                    </>
                                    }
                                </div>
                            }    
                        </div> 

                        <div className='card-home-content-body-text' style={{ paddingBottom:5 }}>

                        {(item.owner_company!=='' && item.owner_company!==undefined)&&
                            <span className='text-company-card-home'>
                                By <b>{item.owner_company}</b>
                            </span>
                        }
                        
                                <div>
                                {item.valid_screen?
                                    <span title='Validada por el equipo de Pautty'>{item.name} {' '}
                                        <span>
                                            <BsFillPatchCheckFill color='#49ADF4' />
                                        </span>
                                    </span>
                                    :
                                    <span>{item.name!==""?
                                        <>{item.name}</>
                                        :
                                        <>Sin nombre</>
                                    }</span>
                                }

                                {(item.username!=='' && item.type_service_app==='influencer')&&
                                    <p className='text-price-extra-home'>@{item.username}</p>
                                }
                                {(item.type_service_app==='valla' || item.type_service_app==='valla-digital')&&
                                    <p className='text-price-extra-home'>{item.type_business}<br></br>
                                        {item.type_service_app === 'valla-digital'?
                                            <span style={{ background: '#19a7a4', color: '#fff', padding: '3px 28px 3px 28px', borderRadius: 5 }}>Valla Digital</span>
                                            :
                                            <span style={{ background: '#dcdcdc', color: '#fff', padding: '3px 28px 3px 28px', borderRadius: 5 }}>Valla No Digital</span>
                                        }
                                    </p>
                                }

                                {/* {(item.time_price !== '' && item.time_price !== undefined && item.price !== "" && item.price !== "0") ?
                                    <div>
                                        <span className='price-card-home'>${dollarIndianLocale.format(item.price)}</span>
                                        <span className='text-price-extra-home'>{config.list_code_plan_payments_pautty[parseInt(item.time_price)].name}</span>
                                    </div>
                                    :
                                    <div>
                                        <span className='text-price-extra-home'>Precio sin especificar</span>
                                    </div>
                                } */}
                                
                            </div>
                            

                         {/* <div className='row items-icons-bottom-home-card-content'> */}
                                 
                                 {/* <div className='col-6 items-icons-bottom-home-card'>
                                     <button style={{ border: 'none', background: 'transparent' }}><p style={{ color: '#9a9a9a' }}> <BsSearch /> </p></button>
                                 </div> */}
                                 {/* <div className='col-12 items-icons-bottom-home-card'>
                                     <button style={{ border: 'none', background: 'transparent', zIndex: 999 }} onClick={()=>{handleOpenModalSharing(item.code_checksum, item.name)}}><p style={{ color: '#9a9a9a' }}>  <BsShare />{' '}Compartir</p></button>
                                 </div> */}
                                 {/* <div className='col-6 items-icons-bottom-home-card'> */}
                                    {/* <button style={{ border: 'none', background: 'transparent', zIndex: 999 }}><p style={{ color: '#9a9a9a' }}> <BsEye /> Ver</p></button>*/}
                                    {/* <button style={{ background: '#F7CF57', color: '#292929', border: 'none', borderRadius: 10, padding: '5px 15px 5px 15px' }} onClick={()=>{buttonFull();navigate('/play/'+item.code_checksum);}}>PLAY <BsFillPlayCircleFill /></button> */}
                                 {/* </div> */}
                             {/* </div> */}

                        </div>
                     </div>
               </div>
}

export default ItemCard;