import React, { useState } from 'react';
import Tv from '../studio-pautty/Tv';
import { BsCheck, BsFillPenFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { config } from '../configPautty';
import { useCookies } from 'react-cookie';
import { encryptData } from '../crypto/pauttyCrypro';
import { tiempoDesdePublicacion } from '../utils/Fechas';

const PautaItem = ({ item, index, userPremiumMessage, pautar, screen, isPartner, showToastError }) => {
    const navigate = useNavigate();
    const [cookies] = useCookies(['user']);

    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
    
    const updateStatusPauta = (newStatus) => {
        setLoadingChangeStatus(true);

        let objectPostStatus = {
            post_screen_id: item.post_screen_id,
            screen_id: parseInt(screen),
            status_pauta: newStatus.toString()
        }
        let dataHeaderCrypto = encryptData('/api/v1/change-status-pauta/', JSON.stringify(objectPostStatus));

        fetch(config.urlApi + '/api/v1/change-status-pauta/', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            },
            body: JSON.stringify({payload: dataHeaderCrypto[2]})
        })
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    item.status_pauta=newStatus;
                } else {
                    showToastError(data.message);
                }
                setLoadingChangeStatus(false);
            })
            .catch(e => {
                showToastError("Ocurrió un error o no tienes permisos para realizar esta acción");
                setLoadingChangeStatus(false);
                return e;
            });
    }


        return (
            <div key={index} className='item-card-list-pautas-status'>
                <div className='row content-item-pauta-list'>
                    <div className='col-4' style={{ textAlign: 'center' }}>
                        <button title='Ver Pauta' 
                            onClick={()=>{
                                navigate('/pauta/'+item.post_screen_id+"?status=true")}
                            } 
                            key={item.post_screen_id} 
                            style={{ border: 'none', width: 120, height: 67, margin: 'auto' }} >
                                <Tv preview={true} template={item.option_style} elements={item.elements_data} itemPostScreen={item} widthPreview={120} heightPreview={67} isPlay={true} ></Tv>
                        </button>
                    </div>
                    <div className='col-8'>
                        <h4>{item.name}<span style={{ fontSize: 12, color: '#a9a9a9', paddingLeft: 10 }}>{tiempoDesdePublicacion(item.updated)}</span></h4>
                        {/* <p>{item.created}</p> */}
                        {!isPartner&&
                            <div>
                                {(item.status_pauta!=='3' && item.status_pauta!=='4' && item.status_pauta!=='5' && item.status_pauta!=='6')&&
                                    <button className='btn btn-info' onClick={()=>{navigate('/editar-pantalla/' + item.screen_id + '/editar-contenido/' + item.post_screen_id)}}><BsFillPenFill />{' '}Editar</button>
                                }
                            </div>
                        }

                        {isPartner&&
                            <div className=''>
                                {loadingChangeStatus ?
                                    <div className="d-flex justify-content-center" style={{ float: 'right', paddingTop: 7 }}>
                                        <span className="spinner-border spinner-border-sm button-sppiner-pauty" role="status" aria-hidden="true"></span>
                                    </div>
                                    :
                                    <>
                                    {item.status_pauta==='3'&&
                                        <button className='button-update-status-item' onClick={()=>updateStatusPauta(5)}>Publicar <BsCheck /></button>
                                    }
                                    {item.status_pauta==='3'&&
                                        <button className='button-update-status-item-rejected' onClick={()=>updateStatusPauta(7)}>Rechazar</button>
                                    }
                                    
                                    </>
                                }
                            </div>
                            }
                            
                    </div>

                </div>
                
            </div>
        )    
    
    
}

export default PautaItem;