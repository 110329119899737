import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { BsTv, BsPower, BsFillPatchQuestionFill, BsSearch, BsChatFill, BsPerson, BsPersonFill, BsFillPersonFill, BsShop, BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";

import Offcanvas from 'react-bootstrap/Offcanvas'
import 'bootstrap/dist/css/bootstrap.min.css';
import ValidLogin from './ValidLogin';
import { useCookies } from 'react-cookie';
import { deleteCookies } from '../deleteCookies';
// import DataHeader from '../profile/component/DataHeader';
import { MdLocationPin } from "react-icons/md";
import NavSearch from './NavSearch';
import { config } from '../configPautty';
import { encryptData } from '../crypto/pauttyCrypro';


const NavBarTop = ({isHome}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);
    const [cookies, setCookies] = useCookies(['user']);

    const navigate = useNavigate();

    const LogoutApp = () => {
        deleteCookies();
        window.location.href = "/login";
    }

    const handleShow = () => {
        setShow(true);
    }

    const [showSearchOffcanva, setShowSearchOffcanva] = useState(false);

    const handleCloseSearchOffcanva = () => setShowSearchOffcanva(false);
    const handleShowSearchOffcanva = () => setShowSearchOffcanva(true);

    const [notificationsCount, setNotificationsCount] = useState(0);

    useEffect(() => {
        getCountNotificationsByUser(); 
      }, []);
    
      const getCountNotificationsByUser = () => {
    
        let dataHeaderCrypto = encryptData('/api/v1/notifications-count/');
        fetch(config.urlApi + '/api/v1/notifications-count/', {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`,
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1],
                'Accept': 'application/json'
            },
            mode: 'cors' 
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status === 401) {
                    deleteCookies();
                    navigate('/login');
                } else {
                    if (!data.status) {
                        setNotificationsCount(data);
                    }
                }
                return data;
            })
            .catch(e => {
                return e;
            });
    }

    return (
        <header className={isHome?"header-home":"header"}>
            <ValidLogin />
            <div className="container">
                <div className="row">
                    <div className="navbar-inner">
                        <div style={{ cursor: 'pointer' }} className='left' onClick={()=>{navigate('/');}}>
                            <img alt='Logo pautty.com' width={35} src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/logopauttycircle.png' />
                        </div>
                        <div style={{ cursor: 'pointer' }} className="title" onClick={()=>{navigate('/');}}>
                            <h2><span style={{ fontFamily: "Rampart One" }}><b>Pautty</b></span></h2>
                        </div>

                        <div style={{ cursor: 'pointer' }}>
                            {/* <a className="right " style={{ padding: 5, paddingLeft: 5, paddingTop: 5, background: 'rgb(177 11 11)', borderRadius: 15, color: '#ffffff' }} onClick={handleShow}> */}
                                {/* <img width={35} height={35} style={{ borderRadius: 50 }} src={cookies.url_photo} /> */}
                                {/* <img width={35} height={35} style={{ borderRadius: 50 }} src={"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/user.png"} /> */}
                                {/* <BsPersonFill size={28} color='#fff' />
                            </a> */}

                            
                            {/* <button className="right " style={{ padding: 5, paddingLeft: 15, border: 'none', background: 'transparent' }} onClick={handleShowSearchOffcanva}>
                                <i><BsSearch size={30} color='#fff' /></i>
                            </button> */}
                            

                            {/* <a className="right " style={{ padding: 5, paddingLeft: 15, paddingTop: 5, zIndex: 9999 }} onClick={()=>{
                                navigate('/mensajes');
                            }}>
                                <BsChatFill  size={28} color='#fff' />
                            </a> */}

                                <Offcanvas className='header-search-offcanva' show={showSearchOffcanva} onHide={handleCloseSearchOffcanva} placement='top' style={{ backgroundImage: 'url('+config.images_slider_home[0].image+')'}}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title className='carousel-pauta-content' style={{ textAlign: 'center', width: '95%' }}>
                                        <div style={{ justifyContent: 'center', justifyItems: 'center', display: 'flex', width: '100%' }}>
                                            <NavSearch />
                                        </div>
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                {/* <Offcanvas.Body>
                                    <div style={{ justifyContent: 'center', justifyItems: 'center', display: 'flex', width: '100%' }}>
                                        <NavSearch />
                                    </div>
                                </Offcanvas.Body> */}
                                </Offcanvas>
                            
                                <div className='responsiveNavBarTop'>

                                    {/* <Link className="right " style={{ padding: 5, paddingLeft: 15, color: "#fff" }} to='/mi-perfil'>
                                        Perfil <BsPersonFill size={25} />
                                    </Link> */}

                                    <Link className="right " style={{ padding: 5, paddingLeft: 15, color: "#fff", background: 'rgb(177 11 11)' }} to='/perfil?tab=user'>
                                     <span style={{ paddingRight: 5 }}>Perfil</span><BsPersonFill />
                                    </Link>

                                    <Link className="right " style={{ padding: 5, paddingLeft: 15, color: '#fff' }} to='/mapa'>
                                        <span> Mapa<MdLocationPin size={30} color='#fff' /></span>
                                    </Link>

                                    <Link className={notificationsCount>0 ? "right animate__animated animate__tada animate__infinite animate__slow ": "right"} style={{ padding: 5, paddingLeft: 15, color: "#fff", position: 'relative' }} to='/notifications'>
                                        <BsBellFill size={25} />
                                        {notificationsCount>0&&
                                            <span className='notificationIconMenu'>{notificationsCount}</span>
                                        }
                                    </Link>
                                    
                                    <Link className="right " style={{ padding: 5, paddingLeft: 15, color: "#fff" }} to='/'>
                                        Inicio
                                    </Link>


                                </div>

                        </div>



                    </div>
                </div>
            </div>

            <Offcanvas show={show} onHide={handleClose}>
                <div className="canvas-menu active">
                    <div className="canvas-menu-full mb-50">

                        <div className="menu-close">
                            <i className="fa fa-close"></i>
                        </div>

                        {/* <div className="canvas-header">
                            <div className="ba-navbar-user text-center mb-30">
                                <DataHeader />
                            </div>
                        </div> */}
                        <div className="canvas-nav">
                            <br></br>
                            <nav>

                                <div className='nav-business-partner'>
                                    <button className={cookies.tab_nav_bussiness==='person'?'active':''} onClick={()=>{
                                        setCookies('tab_nav_bussiness', 'person');
                                        navigate('/');
                                    }}><BsFillPersonFill />{' '}
                                        Personas
                                    </button>

                                    <button className={cookies.tab_nav_bussiness==='business'?'active':''} onClick={()=>{
                                        setCookies('tab_nav_bussiness', 'business');
                                        navigate('/partner/');
                                    }}><BsShop />{' '}
                                        Empresas
                                    </button>

                                    <button className="" onClick={()=>{
                                        navigate('/perfil');
                                    }}><BsFillPersonFill />{' '}
                                        Mi Perfil
                                    </button>
                                </div>

                                <ul>
                                    {/* <li><Link className="my-profile" to="/editar-perfil"><i><BsFillPersonFill /></i>Editar Perfil</Link></li> */}
                                    {/* <li><Link to="/mis-pantallas"><i><BsTv /></i> Mis Pantallas</Link></li> */}
                                    {/* <li><Link to="/my-posts"><i><BsFillFileImageFill /></i> Mis Pautas</Link></li> */}
                                    {/* <li><Link to="/mis-compras"><i><BsCart4 /></i>Mis Compras</Link></li> */}
                                    {/* <li><Link to="/pantallas-favoritas"><i><BsHeart /></i>Pantallas Favoritas</Link></li> */}
                                    
                                </ul>

                                <div style={{ width: '90%', textAlign: 'center', borderRadius: 3, position: 'absolute', bottom: '14%' }}>
                                    <div>
                                        <Link style={{ color: '#fff' }} to="/pqr"><i><BsFillPatchQuestionFill /></i>Ayuda / PQR</Link>
                                    </div>
                                </div>
                                <div style={{ width: '90%', background: '#fff', textAlign: 'center', borderRadius: 3, position: 'absolute', bottom: '8%' }}>
                                    <button style={{ margin: 'auto', bottom: 0, border: 'none', background: 'transparent', color: '#f52d3f' }} onClick={LogoutApp}><i><BsPower /></i>{' '} Cerrar Sesión</button>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </Offcanvas>

        </header>
    )
}

export default NavBarTop;