import React, { useEffect, useState } from 'react';
import NavBarTop from '../general/NavBar';
import NavBarFooter from '../general/NaBarFooter';
import { decryptData, encryptData } from '../crypto/pauttyCrypro';
import { useCookies } from 'react-cookie';
import { deleteCookies } from '../deleteCookies';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import { tiempoDesdePublicacion } from '../utils/Fechas';
import { BsBellFill } from 'react-icons/bs';
import { FcSynchronize, FcTodoList } from 'react-icons/fc';

const Notifications = () => {
  const [cookies] = useCookies(['user']);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotificationsByUser(); 
  }, []);

  const getNotificationsByUser = () => {
    setLoading(true);

    let dataHeaderCrypto = encryptData('/api/v1/notifications/');
    fetch(config.urlApi + '/api/v1/notifications/', {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${cookies.access_token}`,
            'X-Api-Pautty-0': dataHeaderCrypto[0],
            'X-Api-Pautty-1': dataHeaderCrypto[1]
        }
    })
        .then((response) => response.json())
        .then(data => {
            var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
            dataPayload = JSON.parse(dataPayload);
            if (dataPayload.status === 401) {
                deleteCookies();
                navigate('/login');
            } else {
                if (!dataPayload.status) {
                  setNotifications(dataPayload);
                }
            }
            setLoading(false);
            return data;
        })
        .catch(e => {
            showToastError("Inténtalo más tarde. ");
            return e;
        });
}


const updateNotification = (idNotification) => {

  let dataHeaderCrypto = encryptData('/api/v1/notifications/', JSON.stringify({notification_id: idNotification}));
  
  fetch(config.urlApi + '/api/v1/notifications/', {
    method: "PUT",
    headers: {
      'Content-Type':'application/json',
      'Authorization': `Bearer ${cookies.access_token}`,
      'X-Api-Pautty-0': dataHeaderCrypto[0],
      'X-Api-Pautty-1': dataHeaderCrypto[1]
    },
    body: JSON.stringify({payload: dataHeaderCrypto[2]})
  })
    .then((response) => response.json())
    .then(data => {
      console.log(data);
    })
    .catch(e => {
      console.log("Error al actualizar la notificación");
    });
}

const showToastError = (error) => {
  toast.error(error, {
    position: "bottom-center",
    autoClose: 5000,
    rtl: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  });
}


    return (
      <div>
        <NavBarTop></NavBarTop>
        <div className="profile-wrapper-content mt-70 centerMaxContentPage" style={{paddingTop: '70px'}}>
        <div className="container">
        
        <div className="section-heading d-flex align-items-center pt-3 justify-content-between">
        </div>

        {loading ? 
            <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                <h1 className="text-login-title" style={{ margin: 'auto' }}>
                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                </h1>
            </div>
          :
          <div className="notification-area pb-2">
            {notifications.length>0&&
              <div className="list-group" style={{ paddingBottom: 100 }}>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <h3>Notificaciones</h3>
                    <br></br>
                  </div>
                  {/* <a className="notification-clear-all text-secondary" href="#">Clear All</a> */}
                {notifications.map((item, index) => {
                    return <a key={index} className="list-group-item d-flex align-items-center" 
                              onClick={()=>{
                                updateNotification(item.notification_id);
                                navigate(item.url_redirect);
                              }
                              }>

                              {item.type_notification==="revision_post"&&
                                <FcTodoList style={{ paddingRight: 10 }} size={35} />
                              }
                              {item.type_notification==="updated_post"&&
                                <FcSynchronize style={{ paddingRight: 10 }} size={35} />
                              }
                              <div className="noti-info">
                                <b><h5 className="mb-0">{item.title_notification}!</h5></b>
                                <span style={{ color: '#292929' }}>{item.message_notification}!</span>
                                <span>{tiempoDesdePublicacion(item.created)}</span>
                              </div>
                            </a>
                })}
              </div>    
            }
            {notifications.length===0&&
              <div style={{ width: '100%', textAlign: 'center', paddingTop: 10 }}>
                  <BsBellFill color='#838080' size={50} />
                  <h3 style={{ paddingTop: 10, color: '#838080' }}>No tienes notificaciones nuevas</h3>
              </div>
            }
            </div>
        }
        
      </div>
        </div>
        <ToastContainer />
        <NavBarFooter></NavBarFooter>
      </div>
    )
}
export default Notifications;