import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteCookies } from '../deleteCookies';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
// import TabsOptionsEditor from './TabsOptionsEditor';
import Form from 'react-bootstrap/Form';
import { FcInspection, FcUpload } from 'react-icons/fc';
import { BsArrowLeftShort, BsCaretDown, BsCheck, BsCircleFill, BsFillSquareFill, BsFillTelephoneFill, BsFullscreen, BsLayoutWtf, BsMagic, BsPaintBucket, BsPaletteFill, BsPlus, BsSearch, BsShare } from 'react-icons/bs';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillVideoCamera, AiOutlineFontSize, AiTwotoneMail } from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { SketchPicker } from 'react-color';

import { encryptData, decryptData } from '../crypto/pauttyCrypro'; 

import Tv from './Tv';
import { QRCodeCanvas } from 'qrcode.react';

import Offcanvas from 'react-bootstrap/Offcanvas';

import { Accordion, Spinner } from 'react-bootstrap';
import ButtonShared from '../utils/ButtonShared';
import { MdOutlinePhonelink, MdPhoneIphone, MdPhonelink } from 'react-icons/md';

let dollarIndianLocale = Intl.NumberFormat('es-CO');

const Studio = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);
  let { screen, post } = useParams();
  const myCanvas = useRef(null);

  const [loading, setLoading] = useState(true);
  const [loadingSaving, setLoadingSaving] = useState(false);

  const [showGenerateTextDescriptionIA, setShowGenerateTextDescriptionIA] = useState(false);

  const handleCloseGenerateTextIA = () => {
    setShowGenerateTextDescriptionIA(false);
    setShowModalTextContenido(true);
  };

  const [textImageAI, setTextImageAI] = useState("");

  const [initStart, setInitStart] = useState(true);
  const [itemPostScreen, setItemPostScreen] = useState();
  const [elementsPost, setElementsPost] = useState([]);

  const [pageUnsplas, setPageUnsplash] = useState(1);
  const [uploadImagesUnsplas, setUploadImagesUnsplas] = useState(true);
  const [imagesUnsplas, setImagesUnsplas] = useState();

  const [showModalVersionMovile, setShowModalVersionMovile] = useState(false);

  const [showModalInformationPrePublish, setShowModalInformationPrePublish] = useState(false);

  const [queryUnsplas, setQueryUnsplas] = useState('');

  // Imágenes

  const [showModalImageBase64Temp, setShowModalImageBase64Temp] = useState(false);

  const [showModalVideoBase64Temp, setShowModalVideoBase64Temp] = useState(false);

  // opciones de diseño
  const [showModalOptionsDesign, setShowModalOptionsDesign] = useState(false);

  const [imageBase64Current, setImageBase64Current] = useState();

  const [videoBase64Current, setVideoBase64Current] = useState();

  const [showModalImageSelected, setShowModalImageSelected] = useState(false);
  const [tabImageSelected, setTabImageSelected] = useState('IA');

  // video
  const [showModalVideoSelected, setShowModalVideoSelected] = useState(false);
  const [typeFileVideo, setTypeFileVideo] = useState();

  // Contenido
  const [showModalTextContenido, setShowModalTextContenido] = useState(false);

  // Fecha de publicación
  const [showModalTextDateToPublish, setShowModalTextDateToPublish] = useState(false);

  // Color fondo
  const [showModalColorBackground, setShowModalColorBackground] = useState(false);


  const [listMediaPostProfile, setListMediaPostProfile] = useState(null);


  const [dataAcceptFilePhoto, setDataAcceptFilePhoto] = useState('');

  const [scaleViewPauta, setScaleViewPauta] = useState({ x: 0, y: 0, scale: 1 });

  const [showModalFullScreen, setShowModalFullScreen] = useState(false);

  const [showModalSharing, setShowModalSharing] = useState(false);
  const [idPautaSharingCurrent, setIdPautaSharingCurrent] = useState();
  const [titlePautaSharingCurrent, setTitlePautaSharingCurrent] = useState("");

  const handleCloseModalSharing = () => setShowModalSharing(false);

  const [showColorTextElement, setShowColorTextElement] = useState(false);
  const [colorCurrentTextElement, setColoCurrentrTextElement] = useState("#ffffff");
  const [itemIndexCurrent, setItemIndexCurrent] = useState(0);

  const [showModalFontsListText, setShowModalFontsListText] = useState(false);
  const [fontsListTextCurrentElement, setFontsListTextCurrentElement] = useState("");

  const handleCloseSetShowModalFontsListText = () => {
    setShowModalFontsListText(false);
  };

  const handleCloseSetShowModalInformationPublish = () => {
    setShowModalInformationPrePublish(false);
  };

  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentPauta, setShowPaymentPauta] = useState(false);

  const [referencePayment, setReferencePayment] = useState();
  const [referencePaymentPauta, setReferencePaymentPauta] = useState();

  const [widthCurrent, setWidthCurrent] = useState(window.screen.width/1.2);
  const [heightCurrent, setHeightCurrent] = useState(widthCurrent/1.78);
  const [nameDimensionsCurrent, setNameDimensionsCurrent] = useState('');

  const [isMovilView, setIsMovilView] = useState(false);

  const handleClosePayment = () => {
    setShowPayment(false);
    // document.querySelector('.waybox-button').remove();
  };

  const handleClosePaymentPauta = () => {
    setShowPaymentPauta(false);
    // document.querySelector('.waybox-button').remove();
  };

  const handleShowPayment = () => setShowPayment(true);

  function handleShowModalFull(show) {
    // toggleFullScreen(document.body, show);
    setShowModalFullScreen(show);
  }

  const handleChangeDimensionsView = (idDimensions, typeSize) => {
    var resize = 0;
    if (typeSize==="metro") {
      resize = window.screen.width/15;
    } else if (typeSize==="pulgada") {
      resize = window.screen.width/90;
    } else if (typeSize==="pixel") {
      if(window.screen.width < window.screen.height){
        resize = window.screen.width/800;
      }else{
        resize = window.screen.width/2000;
      }
    }

    var newWidth = config.list_diomensions_screen[idDimensions].width*resize;
    var newHeight = config.list_diomensions_screen[idDimensions].height*resize;
    
    setWidthCurrent(newWidth);
    setHeightCurrent(newHeight);
    if(newHeight > newWidth){
      setIsMovilView(true);
    }else{
      setIsMovilView(false);
    }
    setShowModalTextContenido(false);
  }


  useEffect(() => {
    if (initStart) {
      getScreensById();
      getPostScreensById();
      // getElementsPostById();
      setInitStart(false);
      getMediaPostUser();
      // getVideoPostUser();
      setDataAcceptFilePhoto('image/png,image/jpeg,image/jpg');
    }

    if (uploadImagesUnsplas) {
      getImagesUnspash(1);
      setUploadImagesUnsplas(false);
    }

  }, [initStart, uploadImagesUnsplas, myCanvas]);

  const [tabsOptionsModalDesign, setTabsOptionsModalDesign] = useState('designs');

  const [userPremiumCountAi, setUserPremiumCountAI] = useState();

  const GetUserPremiumCountAi = () => {
    let dataHeaderCrypto = encryptData('/api/v1/user-premium-ai-generator/');
    fetch(config.urlApi + '/api/v1/user-premium-ai-generator/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      }
    })
      .then((response) => response.json())
      .then(data => {
        if (data) {
          setUserPremiumCountAI(data);
        }
      })
      .catch(e => {
        return e;
      });
  }

  const getPostScreensById = () => {

    let dataHeaderCrypto = encryptData('/api/v1/post-screen-editor/' + post);

    fetch(config.urlApi + '/api/v1/post-screen-editor/' + post, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      }
    })
      .then((response) => response.json())
      .then(data => {
        var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
        dataPayload = JSON.parse(dataPayload);
        if (dataPayload.status === 404) {
          showToastError(dataPayload.message + " o no tienes permisos para editarlo.");
          navigate('/');
        } else {
          if (dataPayload.status === 401) {
            deleteCookies();
            navigate('/login');
          }
          if (!dataPayload.status) {
            setItemPostScreen(dataPayload);
            setElementsPost(dataPayload.elements_data);
            setLoading(false);
          } else {
            showToastError(dataPayload.message);
            setLoading(false);
          }
        }
      })
      .catch(e => {
        showToastError("Inténtalo más tarde. ");
        setLoading(false);
      });
  }


  const [itemScreen, setItemScreen] = useState();

  const getScreensById = () => {

    let dataHeaderCrypto = encryptData('/api/v1/screen-id/' + screen);

    fetch(config.urlApi + '/api/v1/screen-id/' + screen, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      }
    })
      .then((response) => response.json())
      .then(data => {
        var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
        dataPayload = JSON.parse(dataPayload);
        if (dataPayload.status === 404) {
          showToastError(dataPayload.message + " o no tienes permisos para editarlo.");
          navigate('/');
        } else {
          if (dataPayload.status === 401) {
            deleteCookies();
            navigate('/login');
          }
          if (!dataPayload.status) {
            setItemScreen(dataPayload);
            handleChangeDimensionsView(dataPayload.width_screen_active, config.list_diomensions_screen[dataPayload.width_screen_active].type);
            setNameDimensionsCurrent(config.list_diomensions_screen[dataPayload.width_screen_active].name)
            setLoading(false);
          } else {
            showToastError(dataPayload.message);
            setLoading(false);
          }
        }
      })
      .catch(e => {
        showToastError("Inténtalo más tarde. ");
        setLoading(false);
      });
  }


  const updatePostScreens = (type_update) => {
    setLoadingSaving(true);

    itemPostScreen.elements_data=elementsPost;
    itemPostScreen.name = elementsPost[0].text_content;
    
    if ( itemPostScreen.status_pauta === '3' ) {
      showToastError("La Pauta está en revisión, muy pronto recibirás respuesta");
      return
    }

    if ( itemPostScreen.status_pauta === '5' ) {
      showToastError("La Pauta está publicada y ya no se puede modificar");
      return
    }
    if ( itemPostScreen.status_pauta === '6') {
      showToastError("La Pauta ya está finalizada y no se puede modificar.");
      return
    }

    if(type_update==='push') {
      if (itemScreen.is_payment) {
        itemPostScreen.status_pauta='2'; 
      }else{
        if (itemScreen.is_approval_automatic) {
          itemPostScreen.status_pauta='3';  
        }else{
          itemPostScreen.status_pauta='5';  
        }
      }
    }   
    if(type_update==='repush') {
      if (itemScreen.is_approval_automatic) {
        itemPostScreen.status_pauta='3';  
      }else{
        itemPostScreen.status_pauta='4';  
      }
    } 

    let dataHeaderCrypto = encryptData('/api/v1/update-post-screen/' + post, JSON.stringify(itemPostScreen));
    
    fetch(config.urlApi + '/api/v1/update-post-screen/' + post, {
      method: "PUT",
      headers: {
        'Content-Type':'application/json',
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      },
      body: JSON.stringify({payload: dataHeaderCrypto[2]})
    })
      .then((response) => response.json())
      .then(data => {
        var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
        dataPayload = JSON.parse(dataPayload);
        if (dataPayload.status === 404) {
          showToastError(dataPayload.message);
        } else {
          if (dataPayload.status === 401) {
            deleteCookies();
            navigate('/login');
          }
          if (!dataPayload.status) {
            setItemPostScreen(dataPayload);
            setLoadingSaving(false);
            // showToastSuccess("Actualizado correctamente!");

            if (type_update==='push' && itemScreen.is_payment) {
                publishPautaPaymentWompi();
                if (itemScreen.time_price_2 !== '' && itemScreen.time_price_2 !== undefined && itemScreen.price_2 !== "" && itemScreen.price_2 !== "0") {
                  publishPautaPaymentWompi2();
                }
            }else{
              if(type_update==='push' || type_update==='repush') {
                navigate('/pauta/'+itemPostScreen.post_screen_id+"?status=true");
              }
            }
            // else{
            //   window.location.href = "https://api.whatsapp.com/send?phone="+itemPostScreen.number_whatsapp+"&text=Hola! Te encontré en Pautty.com y me gustaría pautar aquí. https://pautty.com/"+itemPostScreen.code_checksum+". Mi Pauta es https://pautty.com/pauta/"+itemPostScreen.post_screen_id
            // }

          } else {
            showToastError("Inténtalo más tarde.");
            setLoadingSaving(false);
          }
        }
      })
      .catch(e => {
        showToastError("Inténtalo más tarde. ",e);
        setLoadingSaving(false);
      });
  }

  const changeTextElementPost = (event, i) => {
    event.preventDefault();
    setElementsPost((elementsPost) => {
      const element = [...elementsPost];
      element[i].text_content = event.target.value;
      return element;
    });

  }

  const changeItemFontFamilyText = (font, i) => {
    setElementsPost((elementsPost) => {
      const element = [...elementsPost];
      element[i].font_family = font;
      return element;
    });
  }

  const handleChangeSizeTextElementPost = (event, i) => {
    event.preventDefault();
    setElementsPost((elementsPost) => {
      const element = [...elementsPost];
      element[i].size_element = event.target.value;
      return element;
    });
  }

  const onChangeCompleteColorText = (color, i) => {
    setElementsPost((elementsPost) => {
      const element = [...elementsPost];
      element[i].color_element = color.hex;
      return element;
    });
  }

  const handleNewDescriptionStartOpenIA = (index, text) => {
    setLoading(true);
    if (text === '' || text === undefined || text === null) {
      showToastError("Agrega un texto para generar la descripción");
      setLoading(false);
      return
    }

    let dataHeaderCrypto = encryptData('/api/v1/ia-suggest-text/');

    fetch(config.urlApi + '/api/v1/ia-suggest-text/', {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      },
      body: JSON.stringify({
        "text": text
      })
    })
      .then((response) => response.json())
      .then(data => {
        setLoading(false);
        if (data.status === 500) {
          showToastError(data.message);
          return data.message;
        } else {
          setElementsPost((elementsPost) => {
            const element = [...elementsPost];
            element[index].text_content = data.message;
            return element;
          });
          handleCloseGenerateTextIA();
          GetUserPremiumCountAi();
          setShowModalTextContenido(true);
        }
      })
      .catch(e => {
        showToastError("Inténtalo con otro texto.");
        setLoading(false);
        return text;
      });
  }

  const handleImageStartOpenIA = (text) => {
    setLoading(true);
    if (text === '' || text === undefined || text === null) {
      showToastError("Agrega un texto para generar la imagen");
      setLoading(false);
      return
    }

    let dataHeaderCrypto = encryptData('/api/v1/ia-suggest-image/');
    fetch(config.urlApi + '/api/v1/ia-suggest-image/', {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      },
      body: JSON.stringify({
        "text": text
      })
    })
      .then((response) => response.json())
      .then(data => {
        setLoading(false);
        if (data.status === 500) {
          showToastError(data.message);
          return text;
        } else {
          setImageBase64Current("data:image/png;base64," + data.image_base64);
          setShowModalImageBase64Temp(true);
          GetUserPremiumCountAi();
        }
      })
      .catch(e => {
        showToastError("Inténtalo con otro texto");
        setLoading(false);
        return text;
      });
  }

  const showToastError = (error) => {
    toast.error(error, {
      position: "bottom-center",
      autoClose: 5000,
      rtl: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    });
  }

  const showToastSuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 5000,
      rtl: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    });
  }

  const changeQueryUnsplas = event => {
    event.preventDefault();
    setPageUnsplash(1);
    setQueryUnsplas(event.target.value);
  }

  const changeRangeOpaque = event => {
    event.preventDefault();
    setItemPostScreen(itemPostScreen => ({
      ...itemPostScreen,
      opaque: String(event.target.value * 0.01)
    }));
  }

  const changeRangeZoomTemp = event => {
    event.preventDefault();
    setScaleViewPauta({
      scale: event.target.value * 0.001,
      x: 0,
      y: 0
    });
  }


  const changeOptionStyle = (option) => {
    // setOptionStyle(option);
    setItemPostScreen(itemPostScreen => ({
      ...itemPostScreen,
      option_style: option
    }));
    setShowModalTextContenido(false);
  }


  const changeImageElementPost = (i, imgFull) => {
    setElementsPost((elementsPost) => {
      const element = [...elementsPost];
      element[i].url_image = imgFull;
      return element;
    });
  }


  const changeVideoUrl = (i, video) => {
    setElementsPost((elementsPost) => {
      const element = [...elementsPost];
      element[i].url_video = video;
      return element;
    });
  }

  const [textGenerateIA, setTextGenerateIA] = useState();

  const handleChangeGenerateIATitle = event => {
    event.preventDefault();
    setTextGenerateIA(event.target.value);
  }


  const changeTextImageIA = event => {
    event.preventDefault();
    setTextImageAI(event.target.value);
  }

  const handleChangeDateToPublish = event => {
    event.preventDefault();
    const nowDate = new Date();
    let yearDate = nowDate.getFullYear();
    let monthDate = ConverterToMonth(nowDate.getMonth());
    let dayDate = ConverterToZero(nowDate.getDate());
    let hourDate = ConverterToZero(nowDate.getHours());
    let minDate = ConverterToZero(nowDate.getMinutes());
    let dateNowValid = yearDate + "-" + monthDate + "-" + dayDate + "T" + hourDate + ":" + minDate;
    if (event.target.value >= dateNowValid) {
      setItemPostScreen(itemPostScreen => ({
        ...itemPostScreen,
        date_to_publish: event.target.value
      }));
    } else {
      showToastError("La fecha de publicación debe ser mayor a la fecha actual");
    }
  }

  const ConverterToMonth = (month) => {
    if (month < 10) {
      return '0' + (month + 1);
    } else {
      return (month + 1);
    }
  }

  const ConverterToZero = (date_temp) => {
    if (date_temp < 10) {
      return '0' + date_temp;
    } else {
      return date_temp;
    }
  }



  const onChangeCompleteColorBackground = (color) => {
    setItemPostScreen(itemPostScreen => ({
      ...itemPostScreen,
      color: color.hex
    }));
  }

  const getImagesUnspash = () => {
    setPageUnsplash(pageUnsplas + 1);

    var urlUnsplash = '';
    if (queryUnsplas !== '') {
      urlUnsplash = 'https://api.unsplash.com/search/photos?page=' + pageUnsplas + '&query=' + queryUnsplas + '&per_page=50&client_id=ykn5j2O1MCjz_lh97P6Yr37YXJoCeHVqrdGJ_5ZvpOY'
    } else {
      urlUnsplash = 'https://api.unsplash.com/photos?page=' + pageUnsplas + '&client_id=ykn5j2O1MCjz_lh97P6Yr37YXJoCeHVqrdGJ_5ZvpOY'
    }
    fetch(urlUnsplash, {
      method: "GET",
      headers: {
        'Authorization': `Client-ID ykn5j2O1MCjz_lh97P6Yr37YXJoCeHVqrdGJ_5ZvpOY`
      }
    })
      .then((response) => response.json())
      .then(data => {
        if (data.errors) {
          showToastError(data.errors[0]);
          return
        }

        if (queryUnsplas !== '') {
          if (pageUnsplas === 1) {
            setImagesUnsplas(data.results);
          } else {
            setImagesUnsplas(imagesUnsplas => imagesUnsplas.concat(data.results));
          }
        } else {
          if (pageUnsplas === 1) {
            setImagesUnsplas(data);
          } else {
            setImagesUnsplas(imagesUnsplas => imagesUnsplas.concat(data));
          }
        }
      })
      .catch(e => {
        showToastError("Inténtalo más tarde. Error en Unsplash: ", e);
      });
  }

  const getMediaPostUser = () => {
    setLoading(true);
    
    let dataHeaderCrypto = encryptData('/api/v1/get-media/post/');
    fetch(config.urlApi + '/api/v1/get-media/post/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      }
    })
      .then((response) => response.json())
      .then(data => {
        var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
        dataPayload = JSON.parse(dataPayload);        
        setListMediaPostProfile(dataPayload);
        setLoading(false);
      })
      .catch(e => {
        showToastError("Inténtalo más tarde. ");
        setLoading(false);
        return e;
      });
  }

  const SelectedComponentTemplate = ({ item }) => {
    return (
      <button className='col-xl-3 col-lg-4 col-md-4 col-sm-6 button-template-item' title='Seleccionar' onClick={() => { changeOptionStyle(item.id); }}>
        <div className={itemPostScreen.option_style === item.id ? 'selected-market' : ''}>
          {itemPostScreen.option_style === item.id &&
            <div className='item-active-template-studio'>
              <AiFillCheckCircle size={'70%'} color='#dc3545' style={{ margin: 'auto' }} />
            </div>
          }
        </div>
        {item.category === 'video' ?
          <video width="100%" height="100%" autoPlay loop muted>
            <source src={item.url_content} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          :
          <img alt={item.name} src={item.url_content} />
        }


      </button>)
  }

  // const getVideoPostUser = () => {
  //   setLoading(true);

  //   let dataHeaderCrypto = encryptData('/api/v1/get-video/post/');

  //   fetch(config.urlApi + '/api/v1/get-video/post/', {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `Bearer ${cookies.access_token}`,
  //   'X-Api-Pautty-0': dataHeaderCrypto[0],
  //   'X-Api-Pautty-1': dataHeaderCrypto[1]
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then(data => {
  //       setListVideoPostProfile(data);
  //       setLoading(false);
  //     })
  //     .catch(e => {
  //       showToastError("Inténtalo más tarde. ");
  //       setLoading(false);
  //       return e;
  //     });
  // }

  const uploadFileToServer = (imageBase64) => {
    setLoading(true);

    let dataHeaderCrypto = encryptData('/api/v1/image/post/');

    fetch(config.urlApi + '/api/v1/image/post/', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      },
      body: JSON.stringify({
        url_image: imageBase64,
        type_file: 'image'
      })
    })
      .then((response) => response.json())
      .then(data => {
        getMediaPostUser();
        if (showModalImageSelected) {
          changeImageElementPost(itemIndexCurrent, "https://statics-gridwel.s3.us-east-2.amazonaws.com/" + data);
        }
        setShowModalImageBase64Temp(false);
        setShowModalImageSelected(false);
        setLoading(false);
      })
      .catch(e => {
        showToastError("Inténtalo más tarde. ");
        setLoading(false);
        return e;
      });
  }

  const uploadFileVideoToServer = (videoBase64) => {
    setLoading(true);

    let dataHeaderCrypto = encryptData('/api/v1/video/post/');

    fetch(config.urlApi + '/api/v1/video/post/', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      },
      body: JSON.stringify({
        url_image: videoBase64,
        type_file: 'video'
      })
    })
      .then((response) => response.json())
      .then(data => {
        getMediaPostUser();
        if (showModalVideoSelected) {
          changeVideoUrl(itemIndexCurrent, "https://statics-gridwel.s3.us-east-2.amazonaws.com/" + data);
        }
        setShowModalVideoBase64Temp(false)
        setLoading(false);
      })
      .catch(e => {
        showToastError("Inténtalo más tarde. ");
        setLoading(false);
        return e;
      });
  }

  const converterImagePost = (element) => {
    setLoading(true);
    let reader = new FileReader();
    if (element.target) {
      if (element.target.files[0].size <= 6000000) {
        if (element.target.files[0].type === 'image/png' || element.target.files[0].type === 'image/jpeg' || element.target.files[0].type === 'image/jpg') {
          reader.readAsDataURL(element.target.files[0]);
          reader.onload = function () {
            setImageBase64Current(reader.result);
            setShowModalImageBase64Temp(true);

            setLoading(false);
          }
          reader.onerror = function (error) {
            showToastError("Ocurrió un error al intentar cargar la Imagen. " + error);
            setLoading(false);
          }
        } else {
          setLoading(false);
          showToastError("Solo se permiten Imágenes");
          document.getElementById('images').value = '';
          document.getElementById('video-upload').value = '';
        }
      } else {
        setLoading(false);
        showToastError("Tamaño máximo permitido 6MB");
        document.getElementById('images').value = '';
        document.getElementById('video-upload').value = '';
      }
    }
  }


  const converterVideoPost = (element) => {
    setLoading(true);
    let reader = new FileReader();
    if (element.target) {
      if (element.target.files[0].size <= 6000000) {

        setTypeFileVideo(element.target.files[0].type);
        if (element.target.files[0].type === 'video/x-flv' || element.target.files[0].type === 'video/mp4' || element.target.files[0].type === 'video/MP2T' || element.target.files[0].type === 'video/3gpp' || element.target.files[0].type === 'video/quicktime' || element.target.files[0].type === 'video/x-msvideo' || element.target.files[0].type === 'video/x-ms-wmv' || element.target.files[0].type === 'video/webm') {
          reader.readAsDataURL(element.target.files[0]);
          reader.onload = function () {
            setVideoBase64Current(reader.result);
            setShowModalVideoBase64Temp(true);
            setLoading(false);
          }
          reader.onerror = function (error) {
            showToastError("Ocurrió un error al intentar cargar el vídeo. " + error);
            setLoading(false);
          }
        } else {
          setLoading(false);
          showToastError("Solo se permiten Vídeos");
          document.getElementById('images').value = '';
          document.getElementById('video-upload').value = '';
        }
      } else {
        setLoading(false);
        showToastError("Tamaño máximo permitido 6MB");
        document.getElementById('images').value = '';
        document.getElementById('video-upload').value = '';
      }
    }
  }


  const toggleFullScreen = (elem, status) => {

    if (status) {
      if (elem.requestFullScreen) {
        elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  const [validatingPayment, setValidatingPayment] = useState('P');
  // P = Pendiente
  // V = Validando
  // C = Confirmado

  let initIntervalGetStatusPAyment;
  // Pagos
  const changeAddButtonWompi = (plan) => {

    if (plan.value === '') {
      plan.value = itemScreen.price
    }
    var referenceFinish = '';
    if (referencePayment === undefined || referencePayment === '') {
      referenceFinish = referencePaymentPauta;
    } else {
      referenceFinish = referencePayment;
    }

    let dataPaymentConfirm = {
      id_factura: referenceFinish,
      post_screen_id: parseInt(post),
      total_ammount: parseInt(plan.value),
      type_plan: plan.id
    }
    let dataHeaderCrypto = encryptData('/api/v1/payment/', JSON.stringify(dataPaymentConfirm));

    fetch(config.urlApi + '/api/v1/payment/', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      },
      body: JSON.stringify({payload: dataHeaderCrypto[2]})})
      .then((response) => response.json())
      .then(data => {
        var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
        dataPayload = JSON.parse(dataPayload);
        if (dataPayload.status >= 400) {
          showToastError(dataPayload.message);
        } else {
          initIntervalGetStatusPAyment = setInterval(() => {
            getStatusPayment(dataPayload.payment_id);
          }, 3000);
          if (referencePayment === undefined || referencePayment === '') {
            handleClosePayment();
          } else {
            handleClosePaymentPauta();
          }
        }
        setLoading(false);
      })
      .catch(e => {
        showToastError("Inténtalo más tarde. ");
        setLoading(false);
        return e;
      });
  }

  function getStatusPayment(payment_id) {
    let dataHeaderCrypto = encryptData('/api/v1/payment/' + payment_id);

    fetch(config.urlApi + '/api/v1/payment/' + payment_id, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`,
        'X-Api-Pautty-0': dataHeaderCrypto[0],
        'X-Api-Pautty-1': dataHeaderCrypto[1]
      },
    })
      .then((response) => response.json())
      .then(data => {
        var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
        dataPayload = JSON.parse(dataPayload);
        if (dataPayload.status >= 400) {
          showToastError(dataPayload.message);
        } else {
          if (dataPayload.is_payment === true) {
            setValidatingPayment('C');
            clearInterval(initIntervalGetStatusPAyment);
            setTimeout(function() {
              navigate('/pauta/' + post + '?status=true');
            }, 3000);
          } else {
            setValidatingPayment('V');
          }
        }
      })
      .catch(e => {
        showToastError("Inténtalo más tarde.");
        setLoading(false);
        return e;
      });
  }

  var initInterval;

  const repetirCadaSegundo = () => {
    // initInterval = setInterval(initButtonsPaymentWompi, 500);
    initButtonsPaymentWompi();
  }

  const initButtonsPaymentWompi = () => {
    var dateReference = new Date();
    var idReference = "0-" + screen + "-" + post + "-" + dateReference.getFullYear() + "" + dateReference.getMonth() + "" + dateReference.getDay() + "" + dateReference.getHours() + "" + dateReference.getMinutes() + "" + dateReference.getSeconds() + "" + dateReference.getMilliseconds()
    setReferencePayment(idReference);

    for (let i = 0; i < config.list_code_plan_payments_pautty.length; i++) {
      if (config.list_code_plan_payments_pautty[i].type === 'ia') {
        const montoFinal = config.list_code_plan_payments_pautty[i].value + "00";
        const montoMoneda = 'COP';
        fetch(config.urlApi + '/api/v1/hash/generate', {
          method: "POST",
          body: JSON.stringify({
            "id_reference": idReference,
            "monto_final": montoFinal,
            "monto_moneda": montoMoneda
          })
        })
          .then((response) => response.json())
          .then(data => {
            if (data.status === 200) {
              const scriptWompi = document.createElement("script");
              scriptWompi.async = true;
              scriptWompi.src = "https://checkout.wompi.co/widget.js";
              scriptWompi.id = "scriptIdWompi" + config.list_code_plan_payments_pautty[i].id + "" + screen + "-" + post;
              scriptWompi.type = 'text/javascript';
              scriptWompi.setAttribute('data-render', 'button');
              scriptWompi.setAttribute('data-signature:integrity', data.message);
              scriptWompi.setAttribute('data-public-key', process.env.REACT_APP_PUBLIC_KEY_WOMPI);
              scriptWompi.setAttribute('data-currency', montoMoneda);
              scriptWompi.setAttribute('data-amount-in-cents', montoFinal);
              scriptWompi.setAttribute('data-reference', idReference);
              document.getElementById("button_payment_ia_" + config.list_code_plan_payments_pautty[i].id).appendChild(scriptWompi);
            }
          })
          .catch(e => {
            showToastError("No se pudo generar la firma del botón de pago.");
            return e;
          });

        // ---------------------------------------------------------------- 
      }
    }

    // clearInterval(initInterval);
  }

  const ModalVersionMovileView = () => {
    // es dispositivo movil
    const [isDispositivoMovil] = useState(/Mobi/.test(navigator.userAgent));
    if (isDispositivoMovil) {
      toggleFullScreen(document.body, true);
      var restarPorcentaje = 4;
      return <div>
        <Tv isPlay={false} isMovilVersion={true} preview={true} template={itemPostScreen.option_style} elements={elementsPost} itemPostScreen={itemPostScreen} widthPreview={window.screen.width} heightPreview={window.screen.height - ((window.screen.height * restarPorcentaje) / 100)}></Tv>
      </div>
    } else {
      return <div style={{ position: 'absolute', display: 'flex', background: '#eaeaea', justifyContent: 'center', justifyItems: 'center', padding: 5, width: '100%' }}>
        <div style={{ margin: 'auto', background: '#000', borderRadius: 8, padding: 5 }}>
          <Tv isPlay={false} isMovilVersion={true} preview={true} template={itemPostScreen.option_style} elements={elementsPost} itemPostScreen={itemPostScreen} widthPreview={300} heightPreview={600}></Tv>
        </div>
      </div>
    }
  }

  // inicializar pago para publicar
  const publishPautaPaymentWompi = () => {
    var dateReference = new Date();
    var idReference = "1-" + screen + "-" + post + "-" + dateReference.getFullYear() + "" + dateReference.getMonth() + "" + dateReference.getDay() + "" + dateReference.getHours() + "" + dateReference.getMinutes() + "" + dateReference.getSeconds() + "" + dateReference.getMilliseconds()
    setReferencePaymentPauta(idReference);
    const montoFinal = itemScreen.price + "00";
    const montoMoneda = 'COP';
    setShowPaymentPauta(true);
    fetch(config.urlApi + '/api/v1/hash/generate', {
      method: "POST",
      body: JSON.stringify({
        "id_reference": idReference,
        "monto_final": montoFinal,
        "monto_moneda": montoMoneda
      })
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status === 200) {
          const scriptWompi = document.createElement("script");
          scriptWompi.async = true;
          scriptWompi.src = "https://checkout.wompi.co/widget.js";
          scriptWompi.id = "scriptIdWompi_pauta_" + screen + "-" + post;
          scriptWompi.type = 'text/javascript';
          scriptWompi.setAttribute('data-render', 'button');
          scriptWompi.setAttribute('data-signature:integrity', data.message);
          scriptWompi.setAttribute('data-public-key', process.env.REACT_APP_PUBLIC_KEY_WOMPI);
          scriptWompi.setAttribute('data-currency', montoMoneda);
          scriptWompi.setAttribute('data-amount-in-cents', montoFinal);
          scriptWompi.setAttribute('data-reference', idReference);
          document.getElementById("button_payment_pauta_studio").appendChild(scriptWompi);
        }
      })
      .catch(e => {
        showToastError("No se pudo generar la firma del botón de pago.");
        return e;
      });

  }

  // inicializar pago para publicar promocion
  const publishPautaPaymentWompi2 = () => {
    var dateReference = new Date();
    var idReference = "2-" + screen + "-" + post + "-" + dateReference.getFullYear() + "" + dateReference.getMonth() + "" + dateReference.getDay() + "" + dateReference.getHours() + "" + dateReference.getMinutes() + "" + dateReference.getSeconds() + "" + dateReference.getMilliseconds()
    setReferencePaymentPauta(idReference);
    const montoFinal = itemScreen.price_2 + "00";
    const montoMoneda = 'COP';
    setShowPaymentPauta(true);
    fetch(config.urlApi + '/api/v1/hash/generate', {
      method: "POST",
      body: JSON.stringify({
        "id_reference": idReference,
        "monto_final": montoFinal,
        "monto_moneda": montoMoneda
      })
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status === 200) {
          const scriptWompi = document.createElement("script");
          scriptWompi.async = true;
          scriptWompi.src = "https://checkout.wompi.co/widget.js";
          scriptWompi.id = "scriptIdWompi_pauta_" + screen + "-" + post;
          scriptWompi.type = 'text/javascript';
          scriptWompi.setAttribute('data-render', 'button');
          scriptWompi.setAttribute('data-signature:integrity', data.message);
          scriptWompi.setAttribute('data-public-key', process.env.REACT_APP_PUBLIC_KEY_WOMPI);
          scriptWompi.setAttribute('data-currency', montoMoneda);
          scriptWompi.setAttribute('data-amount-in-cents', montoFinal);
          scriptWompi.setAttribute('data-reference', idReference);
          document.getElementById("button_payment_pauta_studio_2").appendChild(scriptWompi);
        }
      })
      .catch(e => {
        showToastError("No se pudo generar la firma del botón de pago.");
        return e;
      });

  }



  return (
    <>
      {loading &&
        <SpinnerLoadingFixed />
      }
      {!itemPostScreen ?
        <SpinnerLoadingFixed />
        :
        <div className='body-studio-pautty'>

          <div className='content-1-studio-pautty'>

            {itemPostScreen &&
              <div style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0.5 }}></div>
            }

            <div className='content-styles-tv'>

              <div style={{ transformOrigin: "0 0", transform: `translate(${scaleViewPauta.x}px, ${scaleViewPauta.y}px) scale(${scaleViewPauta.scale})`, margin: 'auto' }}>

                <div style={{ position: 'relative', height: '100%', paddingBottom: 70 }}>
                  <div style={{ cursor: 'pointer' }} title='Editar contenido' id='htmlContent' onClick={() => { setShowModalTextContenido(true); setTabsOptionsModalDesign('components'); }}>
                    {itemScreen &&
                      <>
                        <Tv isPlay={false} preview={true} template={itemPostScreen.option_style} elements={itemPostScreen.elements_data} itemPostScreen={itemPostScreen} widthPreview={widthCurrent} heightPreview={heightCurrent} isMovilView={isMovilView} ></Tv>
                      </>
                    }
                  </div>
                </div>

                {/* <div>
                    <img alt='Descargar imagen de pautty' src={imageBase64Download} />
                  </div> */}

                {/* {itemScreen&&
                    <p style={{ color: '#000', paddingTop: 10 }}>{itemScreen.width_screen_active}m <b>X</b> {itemScreen.height_screen_active}m</p>
                  } */}

              </div>



              {/* {itemPostScreen.type_service_app!=="radio"&&
              <div style={{ width: '60%', position: 'fixed', bottom: 5, paddingTop: 5 }}>
                <Form.Range min={100} max={3000} onChange={(e) => { changeRangeZoomTemp(e) }} />
                <label>Zoom</label>
              </div>
            } */}

              {(!showModalOptionsDesign) &&

                <div>

                  <div className='content-editor-options' style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>

                    <button style={{ zIndex: 2 }} className='animate__animated animate__backInUp' onClick={() => { setShowModalTextContenido(true); setTabsOptionsModalDesign('designs'); }}>
                      <BsPaletteFill size={30} color='#292929' />{''}<span style={{ color: '#292929', fontSize: 13 }}>Plantillas</span>
                    </button>

{/* 
                    {isMovilView?
                      <button style={{ zIndex: 2 }} className='animate__animated animate__backInUp' onClick={() => { handleChangeDimensionsView(false) }}>
                        <MdPhoneIphone size={30} color='#0d6efd' /> 
                      </button>
                      :
                      <button style={{ zIndex: 2 }} className='animate__animated animate__backInUp' onClick={() => { handleChangeDimensionsView(true) }}>
                        <MdPhoneIphone size={30} color='#292929' /> 
                      </button>
                    } */}
                    

                    <button style={{ zIndex: 2 }} className='animate__animated animate__backInUp' onClick={() => { setShowModalTextContenido(true); setTabsOptionsModalDesign('components'); }}>
                      <BsLayoutWtf size={30} color='#292929' />{''}<span style={{ color: '#292929', fontSize: 13 }}>Componentes</span>
                    </button>

                    <button style={{ zIndex: 2 }} className='animate__animated animate__backInUp' onClick={() => { setShowModalTextContenido(true); setTabsOptionsModalDesign('views_dimensions'); }}>
                      <MdOutlinePhonelink size={30} color='#292929' />{''}<span style={{ color: '#292929', fontSize: 13 }}>{nameDimensionsCurrent}</span>
                    </button>
                    

                    {/* {(itemPostScreen.type_service_app === 'television' || itemPostScreen.type_service_app === 'valla-digital') && */}
                    {/* <button style={{ zIndex: 2 }} className='animate__animated animate__backInUp' onClick={() => setShowModalVideoSelected(true)}>
                        <FaVideo size={30} color='#292929' />{''}<span style={{ color: '#292929', fontSize: 13 }}>Vídeo</span>
                      </button> */}
                    {/* } */}


                    {/* <button style={{ zIndex: 2 }} className='animate__animated animate__backInUp' onClick={() => {setShowModalTextContenido(true);setTabsOptionsModalDesign('links');}}>
                      <FaFacebook size={12} color='#292929' />
                      <FaWhatsapp size={12} color='#292929' />
                      {''}<span style={{ color: '#292929', fontSize: 13 }}>Enlaces</span>
                    </button> */}

                    {/* <button style={{ zIndex: 2 }} className='animate__animated animate__backInUp' onClick={() => {setShowModalTextContenido(true);setTabsOptionsModalDesign('qr_code');}}>
                      <FaQrcode size={30} color='#292929' />{''}<span style={{ color: '#292929', fontSize: 13 }}>QR Code</span>
                    </button>                   */}

                  </div>
                </div>
              }


            </div>


          </div>





          <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 999 }}>
            <div style={{ textAlign: 'center', maxWidth: '100%' }}>
              <div className='content-editor-option-top' style={{ background: '#fff', margin: 0, padding: 5, height: 60 }}>

                <div style={{ float: 'left', paddingTop: 5 }}>
                  <button className='btn btn-default' onClick={() => { window.history.back(); }}>
                    <BsArrowLeftShort size={25} />
                  </button>
                </div>


                <div style={{ float: 'right' }}>
                  {/* <button onClick={()=>navigate('/chat/'+post)} style={{ color: '#fff', background: '#dc3545' }} className='btn btn-default button-top-editor'> <BsChatFill color='#fff' size={25} />{' '}Contactar </button> */}


                  <div>

                   
                   {loadingSaving?
                    <span style={{ paddingRight: 10, color: '#a9a9a9' }}>Guardando...</span>
                    :
                    <span style={{ paddingRight: 10, color: '#a9a9a9' }}> <BsCheck /></span>
                   }

                    {(itemPostScreen.status_pauta === '0' || itemPostScreen.status_pauta === '1' || itemPostScreen.status_pauta === '2') &&
                      <button onClick={() => setShowModalInformationPrePublish(true)} style={{ color: '#ffffff', background: '#dc3545' }} className='btn btn-default button-top-editor'>
                        Publicar
                      </button>
                    }
                    {(itemPostScreen.status_pauta === '7') &&
                      <button onClick={() => updatePostScreens('repush')} style={{ color: '#ffffff', background: '#dc3545' }} className='btn btn-default button-top-editor'>
                        {loading ?
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                          :
                          <span>Publicar {' '}</span>
                        }
                      </button>
                    }

                    {/* {itemPostScreen.number_whatsapp!==""&&
                        <a target='_blank' rel='noreferrer' href={"https://api.whatsapp.com/send?phone="+itemPostScreen.number_whatsapp+"&text=Hola! Te encontré en Pautty.com y me gustaría pautar aquí. https://pautty.com/"+itemPostScreen.code_checksum+". Mi Pauta es https://pautty.com/pauta/"+itemPostScreen.post_screen_id} style={{ color: '#ffffff', background: '#25d366' }} className='btn btn-default button-top-editor'>Contactar {' '} <BsWhatsapp color='#ffffff' size={25} /> </a>
                      } */}


                  </div>


                </div>

              </div>
            </div>
          </div>



        </div>
      }


















      {/*----------------------------------------------------------- Generar texto con IA*/}

      <Modal
        show={showGenerateTextDescriptionIA}
        onHide={handleCloseGenerateTextIA}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
          <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Generar Texto con IA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {itemPostScreen &&
            <div>
              <div>
                <div className='row' style={{ padding: 5 }}>
                  <div className='col-xl-9 col-lg-8 col-md-8 col-sm-12' style={{ position: 'relative', paddingTop: 5 }}>
                    <input value={textGenerateIA} className='input-form-pautty input-editor-text' onChange={(event) => handleChangeGenerateIATitle(event)} type="text" placeholder='Describe el texto...' name="generate_text_ia" />
                  </div>
                  <div className='col-xl-3 col-lg-4 col-md-4 col-sm-12 content-text-modal'>
                    {userPremiumCountAi &&
                      <span style={{ position: 'relative' }}>
                        {(parseInt(userPremiumCountAi.text_ia_count_x40) === 0 && parseInt(userPremiumCountAi.text_ia_count_x20) === 0 && parseInt(userPremiumCountAi.text_ia_count_free) === 0) ?
                          <button onClick={() => { handleShowPayment(); repetirCadaSegundo(); }} className='button-increase-payment'>
                            <span style={{ fontSize: 13 }}>
                              Generar con IA
                            </span>
                          </button>
                          :
                          <>
                            {!loading &&
                              <button disabled={loading} onClick={() => { handleNewDescriptionStartOpenIA(itemIndexCurrent, textGenerateIA); }} className='btn btn-default color-bottons'>
                                <span style={{ fontSize: 13 }}>
                                  Generar Texto con IA
                                </span>
                              </button>
                            }
                          </>
                        }

                        {!loading &&
                          <div>
                            {parseInt(userPremiumCountAi.text_ia_count_x40) > 0 &&
                              <span className='button-number-ia-count'>
                                {userPremiumCountAi.text_ia_count_x40}/40
                              </span>
                            }
                            {parseInt(userPremiumCountAi.text_ia_count_x20) > 0 &&
                              <span className='button-number-ia-count'>
                                {userPremiumCountAi.text_ia_count_x20}/20
                              </span>
                            }

                            {parseInt(userPremiumCountAi.text_ia_count_free) > 0 &&
                              <span className='button-number-ia-count'>
                                {userPremiumCountAi.text_ia_count_free} gratis
                              </span>
                            }

                          </div>
                        }

                      </span>
                    }

                    {loading &&
                      <div>
                        <img alt='Pautty.com' className='animate__animated animate__flip animate__infinite animate__slow' src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/logopauttycircle.png' width={20} />
                        <p className='animate__animated animate__flash animate__infinite animate__slow' style={{ paddingTop: 3, fontSize: 8 }}>Utilizando tecnología de OpenAI...</p>
                      </div>
                    }
                  </div>
                </div>
              </div>

            </div>
          }
        </Modal.Body>
      </Modal>






















      {/*----------------------------------------------------------- Explicación antes de publicar*/}

      <Modal
        show={showModalInformationPrePublish}
        onHide={handleCloseSetShowModalInformationPublish}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
          <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Publicar Pauta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {itemScreen&&
            <>
            {itemScreen.is_publicity ? 
              <div className='row'>
                <div className='col-10' style={{ textAlign: 'justify' }}>
                  {itemScreen.is_approval_automatic&&
                    <span>Para que tu Pauta quede publicada, tendrá que ser validada por el administrador del sitio donde quieres publicar. 
                    <br></br>Si tu Pauta es rechazada por el administrador, tendrás que realizar los ajustes necesarios y volver a publicarla.<br></br>
                    </span>   
                  }
                  {itemScreen.is_payment&&
                      <span>Tu Pauta quedará publicada después de recibir el pago y ser validado por el administrador del sitio.<br></br></span>
                    }
                  <span>Te enviaremos un correo informándote el estado de tu Pauta.<br /></span>
                  
                </div>
                <div className='col-2'>
                  <FcInspection size={'100%'} />
                </div>
                <hr style={{ marginTop: 10 }}></hr>
              </div>
              :
              <span>El sitio no permite Pautas</span>
            }
            </>
            }
            
            {loading ?
                <Spinner animation="border" role="status" style={{ float: 'right' }}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                :
                <>
                {itemScreen&&
                  <>
                      {itemScreen.is_publicity &&
                        <button onClick={()=>{updatePostScreens('push')}} style={{ color: '#ffffff', background: '#dc3545', float: 'right' }} className='btn btn-default button-top-editor'>
                          <span>Publicar {' '}</span>
                        </button>
                      }
                      
                  </>
                }
                </>
            }
          </div>
        </Modal.Body>
      </Modal>











      {/*----------------------------------------------------------- Fuentes para título*/}

      <Modal
        show={showModalFontsListText}
        onHide={handleCloseSetShowModalFontsListText}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
          <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Selecciona la fuente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {elementsPost &&
            <div style={{ maxHeight: 600, overflow: 'scroll' }}>
              {config.font_family_edit.map((item) => {
                return (<button className='button-fonts-list' onClick={() => { changeItemFontFamilyText(item.name, itemIndexCurrent); handleCloseSetShowModalFontsListText() }} key={item.name} style={{ position: 'relative', padding: 5 }}>
                  {/* <span style={{ fontSize: 13, color: 'rgb(168 168 168)' }}>{item.name}</span> */}
                  <p style={{ fontSize: 25, fontFamily: item.name, color: "#000000" }}>{item.name}</p>
                </button>)
              })}
            </div>
          }

        </Modal.Body>
      </Modal>



































      {/* Modal para pagos Wompi ------------------------------ WOMPI */}

      {showPayment &&
        <div className='capa-pagos'>
          <div className='row content-capa-pagos'>
            {/* <h2>Total a pagar <strong style={{ fontSize: 40 }}>$ 45.000</strong></h2>
            <span>No. Referencia: {referencePayment}</span> */}

            {config.list_code_plan_payments_pautty.map((item) => {
              if (item.type === 'ia') {
                return (
                  <div className='col-lg-6 col-sm-12' key={item.id}>
                    <div className='priceContent'>
                      <h4>{item.name}</h4>
                      <hr></hr>
                      <span>{item.description}</span>
                      <h1><strong>${dollarIndianLocale.format(item.value)} <b>COP</b></strong></h1>
                      <hr></hr>
                      <div onClick={() => changeAddButtonWompi(item)} id={'button_payment_ia_' + item.id}></div>
                      <p className='text-terms-payment'>Al dar click en el botón "Paga con Wompi", aceptas nuestros <Link style={{ textDecorationLine: 'blink' }} to="/terms">Términos y condiciones</Link>.</p>
                    </div>
                  </div>
                )
              }
            })}

          </div>
          <button className='closeModalPaymentWompi' onClick={handleClosePayment} ><AiFillCloseCircle color='#dc3545' size={50} /></button>

        </div>
      }


      {/* Modal para pagos Wompi ------------------------------ WOMPI */}

      {showPaymentPauta &&
        <div className='capa-pagos'>
          <div className='row content-capa-pagos'>
            <div className='col-12' style={{ marginBottom: 10 }}>
              <div className='priceContent'>
                <h4>Pauta Aquí</h4>
                <hr></hr>
                <span>{config.list_code_plan_payments_pautty[parseInt(itemScreen.time_price)].description}</span>
                <h1><strong>${dollarIndianLocale.format(itemScreen.price)} <b>COP</b></strong></h1>
                <hr></hr>

                {validatingPayment === 'P' &&
                  <div onClick={() => {
                    changeAddButtonWompi(config.list_code_plan_payments_pautty[parseInt(itemScreen.time_price)]);
                  }} id='button_payment_pauta_studio'></div>
                }

                {validatingPayment === 'C' &&
                  <div>
                    <h3>Pago Confirmado</h3>
                    <button className='btn color-bottons' onClick={() => { navigate('/pauta/' + post + '?status=true'); }}>Hacer seguimiento a la Pauta</button>
                  </div>
                }
                {validatingPayment === 'V' &&
                  <div>
                    <h3 className='animate__animated animate__heartBeat animate__infinite'>Validando Pago...</h3>
                  </div>
                }


              </div>


            </div>


            {(itemScreen.time_price_2 !== '' && itemScreen.time_price_2 !== undefined && itemScreen.price_2 !== "" && itemScreen.price_2 !== "0") &&
            <div className='col-12'>
              <div className='priceContent'>
                <h4>Pauta Aquí</h4>
                <hr></hr>
                <span>{config.list_code_plan_payments_pautty[parseInt(itemScreen.time_price_2)].description}</span>
                <h1><strong>${dollarIndianLocale.format(itemScreen.price_2)} <b>COP</b></strong></h1>
                <hr></hr>

                {validatingPayment === 'P' &&
                  <div onClick={() => {
                    changeAddButtonWompi(config.list_code_plan_payments_pautty[parseInt(itemScreen.time_price_2)]);
                  }} id='button_payment_pauta_studio_2'></div>
                }

                {validatingPayment === 'C' &&
                  <div>
                    <h3>Pago Confirmado</h3>
                    <button className='btn color-bottons' onClick={() => { navigate('/pauta/' + post + '?status=true'); }}>Hacer seguimiento a la Pauta</button>
                  </div>
                }
                {validatingPayment === 'V' &&
                  <div>
                    <h3 className='animate__animated animate__heartBeat animate__infinite'>Validando Pago...</h3>
                  </div>
                }


              </div>


            </div>
            }

            <p className='text-terms-payment' style={{ marginTop: 10 }}>Al dar click en el botón "Paga con Wompi", aceptas nuestros <Link style={{ textDecorationLine: 'blink' }} to="/terms">Términos y condiciones</Link>.</p>

          </div>
          <button className='closeModalPaymentWompi' onClick={handleClosePaymentPauta} ><AiFillCloseCircle color='#dc3545' size={50} /></button>

        </div>
      }



      {/* Modal para Subir imagen a servidor preview                    PREVIEW UPLOAD IMAGE*/}
      <Modal
        show={showModalImageBase64Temp}
        onHide={() => setShowModalImageBase64Temp(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
          <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Vista Previa</Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <div style={{ textAlign: 'center' }}>
            <img alt='Subir imagen a pautty' src={imageBase64Current} />
          </div>

          <div style={{ width: '100%', textAlign: 'center', marginTop: 15 }}>
            <button onClick={() => setShowModalImageBase64Temp(false)} className='btn btn-default btn-lg w-50' >
              Cancelar
            </button>
            <button onClick={() => uploadFileToServer(imageBase64Current)} className='btn btn-default color-bottons btn-lg w-50' >
              Cargar Imagen
            </button>
          </div>

        </Modal.Body>
      </Modal>















      {/* Modal para contenido ------------------------------- Versión móvil*/}
      <Modal
        show={showModalVersionMovile}
        onHide={() => setShowModalVersionMovile(false)}
        fullscreen={true}
        style={{ padding: 0, background: '#eaeaea' }}
      >
        <div style={{ margin: 0 }}>
          <div>
            <button className="top-to-breadcrumb-show" style={{ zIndex: 9999 }} onClick={() => {
              toggleFullScreen(document.body, false);
              setShowModalVersionMovile(false);
            }}>
              <BsArrowLeftShort size={25} />
            </button>
            {itemPostScreen &&
              <ModalVersionMovileView />
            }
          </div>
        </div>
      </Modal>


































      {/* Modal para contenido ------------------------------- FECHA DE PUBLICACION*/}
      <Modal
        show={showModalTextDateToPublish}
        onHide={() => setShowModalTextDateToPublish(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>

          {itemPostScreen &&
            <div className=''>
              <div className='item-text-editor' style={{ background: '#f3f3f3' }}>
                <div style={{ position: 'relative' }}>
                  <label style={{ paddingBottom: 10 }}>¿Cuándo quieres publicar?</label>
                  <input value={itemPostScreen.date_to_publish.replace(':00Z', '')} className='input-form-pautty input-editor-text' onChange={(event) => handleChangeDateToPublish(event)} type="datetime-local" placeholder='Fecha de publicación' name="date_to_publish" />
                </div>
              </div>
            </div>
          }


          <div style={{ width: '100%', textAlign: 'center', marginTop: 15 }}>
            <button onClick={() => setShowModalTextDateToPublish(false)} className='btn btn-default btn-lg w-50' >
              Cancelar
            </button>
            <button onClick={() => { setShowModalTextDateToPublish(false); updatePostScreens('save'); }} className='btn btn-default color-bottons btn-lg w-50' >
              Guardar
            </button>
          </div>

        </Modal.Body>
      </Modal>















      <Offcanvas show={showModalTextContenido} 
          onHide={() => {
            updatePostScreens('save');
            setShowModalTextContenido(false);
            }} placement={'top'}>
        <Offcanvas.Body>

          <div>

            {tabsOptionsModalDesign === 'designs' &&
              <div>

                <div className=''>
                  {(itemPostScreen && itemScreen) &&
                    <div>

                      <div className='row'>
                        {config.list_options_templates_tabs.map((item) => {
                          return <SelectedComponentTemplate item={item} key={item.id} />
                        })}
                      </div>


                    </div>
                  }
                </div>

              </div>
            }
            {tabsOptionsModalDesign === 'components' &&
              <div>

                <div className='row content-options-generic-studio-componentes'>
                  <div className='col-4'>
                    <button style={{ zIndex: 2 }} className='animate__animated animate__backInUp' onClick={() => { setShowModalColorBackground(true); }}>
                      <BsPaintBucket size={30} color='#292929' />{''}<span style={{ color: '#292929', fontSize: 13 }}> <BsFillSquareFill size={30} color={itemPostScreen.color} /></span>
                    </button>
                  </div>
                  <div className='col-8'>
                    {itemPostScreen.option_style === 1 &&
                      <div>
                        <span>Opacidad</span>
                        <Form.Range min={0} max={100} onChange={(e) => { changeRangeOpaque(e) }} value={itemPostScreen.opaque / 0.01} />
                      </div>
                    }
                  </div>
                </div>

                <Accordion>


                  {elementsPost.map((item, i) =>
                    <Accordion.Item eventKey={item.element_post_id} key={i}>
                      <Accordion.Header>
                        {item.type_element === 'text' &&
                          <AiOutlineFontSize size={30} color='#292929' />
                        }
                        {item.type_element === 'image' &&
                          <img src={item.url_image} width={60} alt='' />
                        }
                        {item.type_element === 'phone' &&
                          <BsFillTelephoneFill size={30} color='#292929' />
                        }
                        {item.type_element === 'mail' &&
                          <AiTwotoneMail size={30} color='#292929' />
                        }
                        {item.type_element === 'video' &&
                          <AiFillVideoCamera size={30} color='#292929' />
                        }
                        <span style={{ paddingLeft: 10 }}>{item.text_content}</span>
                      </Accordion.Header>
                      <Accordion.Body style={{ background: '#eaeaea' }}>
                        <div>
                          {(item.type_element === 'text' || item.type_element === 'phone' || item.type_element === 'mail') &&
                            <>
                              <div className='content-icons-options-styles-scroll animate__animated animate__fadeInRight'>
                                {/* <div className='option-style-item'>
                                  <button className="change-font-text-edit" onClick={() => { GetUserPremiumCountAi(); setItemIndexCurrent(i); setShowGenerateTextDescriptionIA(true); }}>Texto IA <BsMagic size={15} color='#000' className='animate__animated animate__heartBeat animate__infinite' /></button>
                                </div> */}
                                <div className='option-style-item'>
                                  <button className="change-font-text-edit" onClick={() => { setShowModalFontsListText(true); setItemIndexCurrent(i); setFontsListTextCurrentElement(item.font_family); }}>Font<BsCaretDown size={13} /></button>
                                </div>
                                <div className='option-style-item'>
                                  <select value={item.size_element} onChange={(e) => { handleChangeSizeTextElementPost(e, i) }} className="change-font-text-edit" name="size_element" aria-label="Size">
                                    {config.list_size_text_editar_pauta.map((size) => <option key={size.id} value={size.value}>{size.name}</option>)}
                                  </select>
                                </div>
                                <div className='option-style-item' style={{ position: 'relative' }}>
                                  <button className="change-font-text-edit" onClick={() => { setColoCurrentrTextElement(item.color_element); setItemIndexCurrent(i); setShowColorTextElement(true); }}>Color <BsCircleFill size={15} color={item.color_element} /></button>
                                </div>


                              </div>

                              <div className='row edit-pautty-form' style={{ marginTop: 10 }}>
                                <div className='col-12'>
                                  <input
                                    onChange={(e) => { changeTextElementPost(e, i) }}
                                    placeholder='Ingresa el texto'
                                    value={item.text_content}
                                    className='input-form-pautty'
                                  />
                                </div>
                                {/* <div className='col-12'>
                                      <button 
                                        onClick={()=>{updateElementPost(item, i)}}
                                        className='btn btn-default'
                                        >Guardar
                                      </button>
                                    </div> */}
                              </div>
                            </>
                          }

                          {item.type_element === 'image' &&
                            <div className='edit-pautty-form'>
                              <div className='row content-items-images-components'>
                                <div className='col-4'>
                                  <button onClick={() => { GetUserPremiumCountAi(); setItemIndexCurrent(i); setTabImageSelected('IA'); setShowModalImageSelected(true); }}>Crear con IA{' '} <BsMagic size={20} color='#000' className='animate__animated animate__heartBeat animate__infinite' /></button>
                                </div>
                                <div className='col-4'>
                                  <button onClick={() => { setItemIndexCurrent(i); setTabImageSelected('UPLOAD'); setShowModalImageSelected(true); }}>Subir Imagen</button>
                                </div>
                                <div className='col-4'>
                                  <button onClick={() => { setItemIndexCurrent(i); setTabImageSelected('UNSPLASH'); setShowModalImageSelected(true); }}>Buscar Unsplash</button>
                                </div>
                              </div>
                              {/* <div>
                                  <button 
                                      onClick={()=>{updateElementPost(item, i)}}
                                      className='btn btn-default'
                                      style={{ marginTop: 10 }}
                                      >Guardar
                                    </button>
                                </div> */}
                            </div>
                          }

                          {item.type_element === 'video' &&
                            <div className='edit-pautty-form'>
                              <div className='row content-items-images-components'>
                                <div className='col-4'>
                                  <button onClick={() => { setItemIndexCurrent(i); setShowModalVideoSelected(true); }}>Subir Video</button>
                                </div>
                              </div>
                              {/* <div>
                                  <button 
                                      onClick={()=>{updateElementPost(item, i)}}
                                      className='btn btn-default'
                                      style={{ marginTop: 10 }}
                                      >Guardar
                                    </button>
                                </div> */}
                            </div>
                          }

                        </div>

                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>

              </div>
            }
            {tabsOptionsModalDesign === 'qr_code' &&
              <div className=''>
                {itemPostScreen &&
                  <div className=''>
                    <div style={{ height: '100%', background: 'transparent', textAlign: 'center' }}>
                      <button style={{ height: 40, border: 'none', background: 'transparent', color: 'blue', textDecoration: 'underline' }} onClick={() => {
                        var link = document.createElement('a');
                        link.download = 'pauta_' + itemPostScreen.post_screen_id + '.png';
                        link.href = document.getElementById('canvaQRCodePauta').toDataURL()
                        link.click();
                      }
                      }>
                        Descargar
                      </button>
                    </div>
                    <div className=' row'>
                      {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' style={{ position: 'relative' }}>
                              <input value={itemPostScreen.qr_text} className='input-form-pautty input-editor-text' onChange={(event) => handleChangeQrText(event)} type="text" name="qr" placeholder='Ingresa tu enlace: example.com' />
                            </div> */}

                      <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                        <div style={{ justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>
                          {itemPostScreen.qr_text !== "" &&
                            <QRCodeCanvas id='canvaQRCodePauta' value={itemPostScreen.qr_text} height={250} width={250} size={250} includeMargin={true} />
                          }
                        </div>

                        {/* <div style={{ height: '100%', background: 'transparent', textAlign: 'center' }}>
                                  <button style={{ height: 40, border: 'none', background: 'transparent', color: 'blue', textDecoration: 'underline' }} onClick={() =>
                                    {setItemPostScreen(itemPostScreen => ({
                                      ...itemPostScreen,
                                      qr_text: ""
                                    }));
                                    
                                  }
                                  }>
                                    Borrar código
                                  </button>
                                </div> */}

                      </div>

                    </div>
                  </div>
                }
              </div>
            }

                {tabsOptionsModalDesign === 'views_dimensions' &&
                  <div>
                    <h3>Dimensión (metros)</h3>
                      <div className='content-dimensiones-form' style={{ padding: 10 }}>
                              {config.list_diomensions_screen.map((item) => { 
                                if (item.type==='metro') {
                                    return <div onClick={()=>{handleChangeDimensionsView(item.id, item.type);setNameDimensionsCurrent(item.name)}} key={item.id} className='dimensiones-form'>
                                            {/* {item.id===widthtValla&&
                                                <div className='item-selected-dimensions' style={{ width: item.widthCss, height: item.heightCss }}>
                                                    <AiFillCheckCircle size={'70%'} color='#dc3545' style={{ margin: 'auto' }} />
                                                </div>
                                            } */}
                                            <button style={{ width: item.widthCss, height: item.heightCss }} className='button-valla-dimension'>
                                                <span>{item.name}</span>
                                            </button>
                                        </div>
                                  }
                                })
                            }
                  </div>
                  <h3>Dimensión (Pulgadas)</h3>
                      <div className='content-dimensiones-form' style={{ padding: 10 }}>
                              {config.list_diomensions_screen.map((item) => { 
                                if (item.type==='pulgada') {
                                    return <div onClick={()=>{handleChangeDimensionsView(item.id, item.type);setNameDimensionsCurrent(item.name)}} key={item.id} className='dimensiones-form'>
                                            {/* {item.id===widthtValla&&
                                                <div className='item-selected-dimensions' style={{ width: item.widthCss, height: item.heightCss }}>
                                                    <AiFillCheckCircle size={'70%'} color='#dc3545' style={{ margin: 'auto' }} />
                                                </div>
                                            } */}
                                            <button style={{ width: item.widthCss, height: item.heightCss }} className='button-valla-dimension'>
                                                <span>{item.name}</span>
                                            </button>
                                        </div>
                                  }
                                })
                            }
                  </div>
                  <h3>Dimensión (Pixeles)</h3>
                      <div className='content-dimensiones-form' style={{ padding: 10 }}>
                              {config.list_diomensions_screen.map((item) => { 
                                if (item.type==='pixel') {
                                    return <div onClick={()=>{handleChangeDimensionsView(item.id, item.type);setNameDimensionsCurrent(item.name)}} key={item.id} className='dimensiones-form'>
                                            {/* {item.id===widthtValla&&
                                                <div className='item-selected-dimensions' style={{ width: item.widthCss, height: item.heightCss }}>
                                                    <AiFillCheckCircle size={'70%'} color='#dc3545' style={{ margin: 'auto' }} />
                                                </div>
                                            } */}
                                            <button style={{ width: item.widthCss, height: item.heightCss }} className='button-valla-dimension'>
                                                <span>{item.name}</span>
                                            </button>
                                        </div>
                                  }
                                })
                            }
                  </div>
                  </div>
                }


          <div className='button-close-modal-components'>
            <button onClick={()=>{
              updatePostScreens('save');
              setShowModalTextContenido(false);
            }}>
              Salir
            </button>
          </div>


          </div>

        </Offcanvas.Body>
      </Offcanvas>












      {/* Modal para cambiar color de tus textos ------------------------------------------- Color de textos*/}

      <Modal
        show={showColorTextElement}
        onHide={() => setShowColorTextElement(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
          <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Escoge color</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {colorCurrentTextElement &&
            <div className='row'>
              <div className='col-12' style={{ justifyContent: 'center', justifyItems: 'center', textAlign: 'center', display: 'flex' }}>
                <SketchPicker width='40%' disableAlpha={true} color={colorCurrentTextElement} onChangeComplete={(color) => onChangeCompleteColorText(color, itemIndexCurrent)} />
              </div>
            </div>
          }


          <div style={{ width: '100%', textAlign: 'center', marginTop: 15 }}>
            <button onClick={() => setShowColorTextElement(false)} className='btn btn-default btn-lg w-50' >
              Cancelar
            </button>
            <button onClick={() => { setShowColorTextElement(false); }} className='btn btn-default color-bottons btn-lg w-50' >
              Aceptar
            </button>
          </div>

        </Modal.Body>
      </Modal>








      {/* Modal para cambiar color de fondo ------------------------------------------- Color Fondo*/}

      <Modal
        show={showModalColorBackground}
        onHide={() => setShowModalColorBackground(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
          <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Color de Fondo</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {itemPostScreen &&
            <div className='row'>
              <div className='col-12' style={{ justifyContent: 'center', justifyItems: 'center', textAlign: 'center', display: 'flex' }}>
                <SketchPicker disableAlpha={true} color={itemPostScreen.color} width='40%' onChangeComplete={(color) => { onChangeCompleteColorBackground(color); }} />
              </div>
            </div>
          }


          <div style={{ width: '100%', textAlign: 'center', marginTop: 15 }}>
            <button onClick={() => setShowModalColorBackground(false)} className='btn btn-default btn-lg w-50' >
              Cancelar
            </button>
            <button onClick={() => { setShowModalColorBackground(false); }} className='btn btn-default color-bottons btn-lg w-50' >
              Aceptar
            </button>
          </div>

        </Modal.Body>
      </Modal>





      {/* Modal para ver las imágenes y seleccionar ------------------------------------- IMAGENES */}

      <Modal
        show={showModalImageSelected}
        onHide={() => setShowModalImageSelected(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
          <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Selecciona tu imagen</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {tabImageSelected === 'IA' &&
            <div className='item-text-editor' style={{ margin: 'auto', height: 300 }}>
              <div className='row content-text-modal' style={{ height: '100%', margin: 'auto' }}>
                <div className='col-12' style={{ position: 'relative' }}>
                  <input value={textImageAI} className='input-form-pautty input-editor-text' onChange={(event) => changeTextImageIA(event)} type="text" placeholder='Describe tu imagen...' name="textImageAI" />
                </div>
                <div className='col-12'>
                  {userPremiumCountAi &&
                    <span>

                      {!loading &&
                        <span style={{ position: 'relative' }}>
                          {(parseInt(userPremiumCountAi.image_ia_count_x40) === 0 && parseInt(userPremiumCountAi.image_ia_count_x20) === 0 && parseInt(userPremiumCountAi.image_ia_count_free) === 0) ?
                            <button onClick={() => { handleShowPayment(); repetirCadaSegundo(); }} className='button-increase-payment'>
                              <span style={{ fontSize: 13 }}>
                                Generar con IA
                              </span>
                            </button>
                            :
                            <button disabled={loading} onClick={() => { handleImageStartOpenIA(textImageAI); }} className='btn btn-default color-bottons'>
                              <span style={{ fontSize: 13 }}>
                                Generar Imagen con IA
                              </span>
                            </button>
                          }
                          {parseInt(userPremiumCountAi.image_ia_count_x40) > 0 &&
                            <span className='button-number-ia-count'>
                              {userPremiumCountAi.image_ia_count_x40}/40
                            </span>
                          }
                          {parseInt(userPremiumCountAi.image_ia_count_x20) > 0 &&
                            <span className='button-number-ia-count'>
                              {userPremiumCountAi.image_ia_count_x20}/20
                            </span>
                          }

                          {parseInt(userPremiumCountAi.image_ia_count_free) > 0 &&
                            <span className='button-number-ia-count'>
                              {userPremiumCountAi.image_ia_count_free} gratis
                            </span>
                          }

                        </span>
                      }
                    </span>
                  }

                  {loading &&
                    <div style={{ paddingTop: 10 }}>
                      <img alt='' className='animate__animated animate__flip animate__infinite animate__slow' src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/logopauttycircle.png' width={70} />
                      <p className='animate__animated animate__flash animate__infinite animate__slow' style={{ paddingTop: 30 }}>Utilizando tecnología de OpenAI...</p>
                    </div>
                  }
                </div>
              </div>

            </div>
          }

          {tabImageSelected === 'UPLOAD' &&
            <div>
              <div style={{ maxHeight: 300, overflowY: 'scroll' }}>

                {(listMediaPostProfile) &&
                  <>
                    {listMediaPostProfile.map((item) => {                      
                      if (item.type_file==='image') {
                          return <button onClick={() => {
                            changeImageElementPost(itemIndexCurrent, "https://statics-gridwel.s3.us-east-2.amazonaws.com/" + item.name);
                            setShowModalImageSelected(false);
                          }} key={item.name} style={{ margin: 2, border: '1px solid #eaeaea', height: 60, borderRadius: 3 }} ><img alt='Pauta en Pautty' src={"https://statics-gridwel.s3.us-east-2.amazonaws.com/" + item.name} height={60} /></button> 
                      }
                    })}
                    <hr></hr>
                  </>
                }
              </div>

              <label htmlFor='images' className="drop-container">
                <span className='drop-title'>Subir Imagen <FcUpload size={30} /> </span>
                <input style={{ margin: 2, border: 'none', background: "#f52d3f", color: "#fff", borderRadius: 5, display: 'none' }} id='images' accept={dataAcceptFilePhoto} type="file" className="form-control" onChange={(e) => converterImagePost(e)} />
              </label>
            </div>
          }

          {tabImageSelected === 'UNSPLASH' &&
            <div>
              <div className='row' style={{ marginTop: 10 }}>
                <span style={{ fontSize: 14, color: '#808080' }}>Encuentra más en https://unsplash.com</span>
                <div className='col-10' style={{ position: 'relative' }}>
                  <input className='form-control'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        getImagesUnspash();
                      }
                    }}
                    onChange={(e) => changeQueryUnsplas(e)} value={queryUnsplas} type="text"
                    placeholder="Eje: 'Restaurante', 'Bar'..." maxLength={300} />
                  <button style={{ border: 'none', zIndex: 999, position: 'absolute', right: 15, top: 6 }} onClick={() => setQueryUnsplas("")}>X</button>
                </div>
                <div className='col-2'>
                  <button className='btn btn-default color-bottons'
                    onClick={() => {
                      getImagesUnspash();
                    }
                    }><BsSearch /></button>
                </div>
              </div>
              <br></br>

              <div style={{ maxHeight: 300, overflowY: 'scroll' }}>

                {imagesUnsplas &&
                  <>
                    {imagesUnsplas.map((image) => <button onClick={() => {
                      changeImageElementPost(itemIndexCurrent, image.urls.full);
                      setShowModalImageSelected(false);
                    }} key={image.id + (Math.random() * 24)} style={{ margin: 2, border: '1px solid #eaeaea', height: 60, borderRadius: 3 }} ><img alt='Pauta en Pautty' src={image.urls.small} height={60} /></button>)}
                  </>
                }


                <div style={{ width: '100%' }}>
                  <button className='btn btn-default color-bottons' style={{ margin: 7 }} onClick={() => {
                    getImagesUnspash();
                  }
                  }>
                    <BsPlus />{' '}
                    Ver más</button>
                </div>

              </div>
            </div>
          }

        </Modal.Body>
      </Modal>


















      {/* Modal para Subir video a servidor preview                    PREVIEW UPLOAD VIDEO*/}
      <Modal
        show={showModalVideoBase64Temp}
        onHide={() => setShowModalVideoBase64Temp(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><h2 style={{ textAlign: 'center' }}>Vista previa</h2></Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <div style={{ textAlign: 'center' }}>
            <video width={'auto'} height={'auto'} autoPlay muted type={typeFileVideo}>
              <source src={videoBase64Current} type={typeFileVideo} />
              Your browser does not support the video tag.
            </video>
          </div>

          <div style={{ width: '100%', textAlign: 'center', marginTop: 15 }}>
            <button onClick={() => setShowModalVideoBase64Temp(false)} className='btn btn-default btn-lg w-50' >
              Cancelar
            </button>
            <button onClick={() => uploadFileVideoToServer(videoBase64Current)} className='btn btn-default color-bottons btn-lg w-50' >
              Cargar Vídeo
            </button>
          </div>

        </Modal.Body>
      </Modal>




































      {/* Modal video fondo ------------------------------------- VIDEO */}

      <Modal
        show={showModalVideoSelected}
        onHide={() => setShowModalVideoSelected(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
          <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Selecciona vídeo</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div style={{ maxHeight: 300, overflowY: 'scroll' }}>

            {(listMediaPostProfile) &&
              <>
                {listMediaPostProfile.map((item) => {
                  if (item.type_file==='video') {
                    return <button onClick={() => {
                      changeVideoUrl(itemIndexCurrent, "https://statics-gridwel.s3.us-east-2.amazonaws.com/" + item.name);
                      setShowModalVideoSelected(false);
                      changeOptionStyle(100);
                    }} key={item.name} style={{ margin: 2, border: '1px solid #eaeaea', height: 'auto', borderRadius: 3 }} >
                      <video height={150} controls muted><source alt='Pauta en Pautty' type="video/mp4" src={"https://statics-gridwel.s3.us-east-2.amazonaws.com/" + item.name} /></video></button> 
                  }
                }
                )}
                <hr></hr>
              </>
            }

          </div>


          <label htmlFor='video-upload' className="drop-container">
            <span className='drop-title'>Subir Vídeo <FcUpload size={30} /> </span>
            <input style={{ margin: 2, border: 'none', background: "#f52d3f", color: "#fff", borderRadius: 5, display: 'none' }} id='video-upload' accept="video/*" type="file" className="form-control" onChange={(e) => converterVideoPost(e)} />
          </label>

          <div style={{ width: '100%', textAlign: 'center', marginTop: 15 }}>
            <button onClick={() => setShowModalVideoSelected(false)} className='btn btn-default btn-lg w-50' >
              Cancelar
            </button>
            <button onClick={() => { setShowModalVideoSelected(false); updatePostScreens('save'); }} className='btn btn-default color-bottons btn-lg w-50' >
              Guardar
            </button>
          </div>

        </Modal.Body>
      </Modal>
























      <ToastContainer />
    </>
  )
}

export default Studio;