import React, { useEffect, useRef, useState } from 'react';

import { config } from '../configPautty';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import Tv from '../studio-pautty/Tv';
import { decryptData, encryptData } from '../crypto/pauttyCrypro';



const Play = () => {
    const [cookies] = useCookies(['user']);
    let { code_checksum } = useParams();

    const intervalId = useRef(null);
    const [count, setCount] = useState(0);

    const [postScreen, setPostScreen] = useState([]);
    const [postScreenCurrent, setPostScreenCurrent] = useState();

    useEffect(() => {
        if (postScreen.length===0) {
            getListPostScreen();
        }
        intervalId.current = setInterval(() => {
            if (count < postScreen.length) {
                setCount((count) => count + 1);
                if (postScreen) {
                    setPostScreenCurrent(postScreen[count]);
                }
            } else {
                setCount((count) => 0);
                setPostScreenCurrent(postScreen[0]);
                getListPostScreen();
            }
            }, 15000);
            return () => {
            clearInterval(intervalId.current);
            }       
    }, [count, postScreen]);

    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const toggleFullScreen = (elem, status) => {

        if (status) {
          if (elem.requestFullScreen) {
            elem.requestFullScreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      }

    const getListPostScreen = () => {
      let dataHeaderCrypto = encryptData('/api/v1/post-by-screen/');
        fetch(config.urlApi + '/api/v1/post-by-screen/?code_checksum='+code_checksum, {
            method: "GET",
            headers: {
              'X-Api-Pautty-0': dataHeaderCrypto[0],
              'X-Api-Pautty-1': dataHeaderCrypto[1]
            }
        })
            .then((response) => response.json())
            .then(data => {
                var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
                dataPayload = JSON.parse(dataPayload);
                if (dataPayload.status) {
                    if (dataPayload.status===404) {
                        showToastError("No existen Pautas pendientes por mostrar.");
                    }
                }else{
                    setPostScreen(dataPayload);
                    setPostScreenCurrent(dataPayload[0]);
                }
            })
            .catch(e => {
                return e;
            });
    }

    
    return (
        <>
            
            {postScreenCurrent&&
                <div style={{ cursor: 'none' }} onClick={()=>toggleFullScreen(document.body, true)}>
                    {/* <span>count:: {count}</span> */}
                   <Tv preview={true} template={postScreenCurrent.option_style} elements={postScreenCurrent.elements_data} itemPostScreen={postScreenCurrent} widthPreview={window.screen.width} heightPreview={window.screen.height} isPlay={true} isMovilView={/Mobi/.test(navigator.userAgent)}></Tv>
                </div>
            }

        <ToastContainer />
        </>
    )
}

export default Play;