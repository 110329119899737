export function tiempoDesdePublicacion(fechaPublicacion) {
    fechaPublicacion = fechaPublicacion.replace("Z","");
    const ahora = new Date();
    const fechaPost = new Date(fechaPublicacion);

    const diferenciaTiempo = ahora - fechaPost;
  
    const segundos = Math.floor(diferenciaTiempo / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);
    const semanas = Math.floor(dias / 7);
    const meses = Math.floor(dias / 30);
    const años = Math.floor(dias / 365);
  
    if (años > 0) {
      return `Hace ${años} años`;
    } else if (meses > 0) {
      return `Hace ${meses} meses`;
    } else if (semanas > 0) {
      return `Hace ${semanas} semanas`;
    } else if (dias > 0) {
      return `Hace ${dias} días`;
    } else if (horas > 0) {
      return `Hace ${horas} horas`;
    } else if (minutos > 0) {
      return `Hace ${minutos} minutos`;
    } else {
      return `Hace unos segundos`;
    }
  }