import React, { useEffect, useState } from 'react';
import { config } from '../../configPautty';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Offcanvas } from 'react-bootstrap';
import { BsFillPatchCheckFill, BsFacebook, BsInstagram, BsYoutube, BsWhatsapp, BsLinkedin, BsTelegram, BsPinterest, BsLine, BsSnapchat, BsTwitterX, BsLink, BsSpotify, BsPhone } from 'react-icons/bs';
import { FaExternalLinkAlt, FaTiktok } from 'react-icons/fa';
import { decryptData, encryptData } from '../../crypto/pauttyCrypro';
let dollarIndianLocale = Intl.NumberFormat('es-CO');


const ShowInfluencer = ({ valla }) => {

    const [showModalImage, setShowwModalImage] = useState(false);

    const [urlSocialTemp, setUrlSocialTemp] = useState('');
    const [nameSocialTemp, setNameSocialTemp] = useState('');

    const handlewModalImageClose = () => setShowwModalImage(false);
    const handlewModalImageShow = () => setShowwModalImage(true);

    const [showOptionOpenAPP, setShowOptionOpenAPP] = useState(false);

    const handleCloseOptionOpenAPP = () => setShowOptionOpenAPP(false);
    const handleShowOptionOpenAPP = (url, name) => {
        setShowOptionOpenAPP(true);
        setUrlSocialTemp(url);
        setNameSocialTemp(name);
    };

    const converterToFollowers = (followers) => {
        if (parseInt(followers) < 1000) {
            if (followers==='0') {
                return '';   
            }else{
                return followers;
            }
        } else if (parseInt(followers) >= 1000 && parseInt(followers) < 1000000) {
            return followers.substring(0, followers.length - 3) + " K";
        } else if (parseInt(followers) >= 1000000) {
            return followers.substring(0, followers.length - 6) + " mill.";
        }
    }

    const [isDispositivoMovil] = useState(/Mobi/.test(navigator.userAgent));
    const [widthImageProfile] = useState(window.screen.width/(isDispositivoMovil?3:5));

    useEffect(() => {
        getElementScreen(valla.screen_id);
        return () => null;
    }, []);

    const [listElementsScreen, setListElementsScreen] = useState([]);

    const getElementScreen = (idScreen) => {
        let dataHeaderCrypto = encryptData('/api/v1/element-screen/'+idScreen);
        fetch(config.urlApi + '/api/v1/element-screen/'+idScreen, {
            method: 'GET',
            headers: {
                'X-Api-Pautty-0': dataHeaderCrypto[0],
                'X-Api-Pautty-1': dataHeaderCrypto[1]
            }
        })
            .then((response) => response.json())
            .then(data => {
                var dataPayload = decryptData(data.payload, dataHeaderCrypto[0]);
                dataPayload = JSON.parse(dataPayload);
                if (dataPayload) {
                    setListElementsScreen(dataPayload);
                }
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    
    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const GetIconSocialMedia = ({nameSocial}) => {
        
        // if (nameSocial.description==="Facebook") {
        //     return  <div className='links-container' style={{ background: '#3b5998' }}>
        //                 <span > <button style={{ color: '#ffffff' }} className='btn_enlace' onClick={()=>getUrlSocialMedia(nameSocial.url, nameSocial.name)} ><BsFacebook color='#fff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
        //             </div>
        // }
        if (nameSocial.description==="Facebook") {
            return  <div className='links-container' style={{ background: '#3b5998' }}>
                        <span > <button style={{ color: '#ffffff' }} className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsFacebook color='#fff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
                    </div>
        }
        if (nameSocial.description==="Twitter") {
            return  <div className='links-container' style={{ background: '#000000' }}>
                        <span > <button style={{ color: '#ffffff' }} className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsTwitterX color='#ffffff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
                    </div>
        }
        if (nameSocial.description==="Instagram") {
            return  <div className='links-container' style={{ background: 'radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)' }}>
                        <span > <button className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsInstagram color='#893dc2' size={28} style={{ borderRadius: 5 }} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)} </button></span>
                    </div>
        }
        if (nameSocial.description==="Tiktok") {
            return  <div className='links-container' style={{ background: '#000000' }}>
                        <span > <button style={{ color: '#ffffff' }} className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><FaTiktok color='#ffffff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
                    </div>
        }
        if (nameSocial.description==="Youtube") {
            return  <div className='links-container' style={{ background: '#c4302b' }}>
                        <span > <button style={{ color: '#ffffff' }} className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsYoutube color='#ffffff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
                    </div>
        }
        if (nameSocial.description==="Whatsapp") {
            return  <div className='links-container' style={{ background: '#25d366' }}>
                        <span > <button style={{ color: '#292929' }} className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsWhatsapp color='#292929' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)} </button></span>
                    </div>
        }
        if (nameSocial.description==="Linkedin") {
            return  <div className='links-container' style={{ background: '#0e76a8' }}>
                        <span > <button style={{ color: '#ffffff' }} className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsLinkedin color='#ffffff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
                    </div>
        }    
        if (nameSocial.description==="Telegram") {
            return  <div className='links-container' style={{ background: '#0088cc' }}>
                        <span > <button style={{ color: '#ffffff' }} className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsTelegram color='#ffffff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)} </button></span>
                    </div>
        }  
        if (nameSocial.description==="Pinterest") {
            return  <div className='links-container' style={{ background: '#C8232C' }}>
                        <span > <button className='btn_enlace' style={{ color: '#ffffff' }} onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsPinterest color='#ffffff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
                    </div>
        } 
        if (nameSocial.description==="Line") {
            return  <div className='links-container' style={{ background: '#00b900' }}>
                        <span > <button className='btn_enlace' style={{ color: '#ffffff' }} onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsLine color='#ffffff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
                    </div>
        }    
        if (nameSocial.description==="Snapchat") {
            return  <div className='links-container' style={{ background: '#FFFC00' }}>
                        <span > <button className='btn_enlace' style={{ color: '#292929' }} onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsSnapchat color='#292929' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
                    </div>
        }
        if (nameSocial.description==="Spotify") {
            return  <div className='links-container' style={{ background: '#1ED760' }}>
                        <span > <button style={{ color: '#ffffff' }} className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsSpotify color='#ffffff' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)} </button></span>
                    </div>
        }
        if (nameSocial.description==="Web") {
            return  <div className='links-container' style={{ background: '#ffffff' }}>
                        <span > <button style={{ color: '#0e76a8' }} className='btn_enlace' onClick={()=>{handleShowOptionOpenAPP(nameSocial.url, nameSocial.name)}} ><BsLink color='#0e76a8' size={28} /> {nameSocial.name} &#8226; {converterToFollowers(nameSocial.followers)}</button></span>
                    </div>
        }       
        return                                                  
    }

    const getUrlSocialMedia = (url_social, username) => {

        if (url_social==="Facebook") {
            window.location.href = "fb://facewebmodal/f?href=https://www.facebook.com/"+username;            
        } else if (url_social==="Twitter") {
            window.location.href = "twitter://user?screen_name="+username;
        } else if (url_social==="Instagram") {
            window.location.href = "instagram://user?username="+username;
        } else if (url_social==="Tiktok") {
            window.location.href = "snssdk1233://user/profile/"+username;
        } else if (url_social==="Youtube") {
            window.location.href = "https://www.youtube.com/"+username;
        } else if (url_social==="Whatsapp") {
            window.location.href = "https://wa.me/"+username+"?text=Hola, te encontré en Pautty.com"; 
        } else if (url_social==="Linkedin") {
            window.location.href = "intent://linkedin.com/in/"+username+"#Intent;package=com.linkedin.android;scheme=https;end;";
        } else if (url_social==="Telegram") {
            window.location.href = "tg://resolve?domain="+username;
        } else if (url_social==="Pinterest") {
            window.location.href = "pinterest://user/"+username;
        } else if (url_social==="Line") {
            window.location.href = "https://line.me/"+username;
        } else if (url_social==="Snapchat") {
            window.location.href = "snapchat://add/"+username;
        } else if (url_social==="Spotify") {
            window.location.href = "https://open.spotify.com/"+username;
        } else if (url_social==="Web") {
            window.location.href = "https://"+username;
        }
        // showToastError("No tienes la aplicación instalada");    
        return 
    }

    const getUrlSocialMediaNavegador = (url_social, username) => {
        if (url_social==="Facebook") {
            window.open("https://www.facebook.com/"+username, '_blank')
        } else if (url_social==="Twitter") {
            window.open("https://www.twitter.com/"+username, '_blank')
        } else if (url_social==="Instagram") {
            window.open("https://www.instagram.com/"+username+"/", '_blank')
        } else if (url_social==="Tiktok") {
            window.open("https://www.tiktok.com/@"+username, '_blank')
        } else if (url_social==="Youtube") {
            window.open("https://www.youtube.com/"+username, '_blank')
        } else if (url_social==="Whatsapp") {
            window.open("https://wa.me/"+username+"?text=Hola, te encontré en Pautty.com", '_blank')
        } else if (url_social==="Linkedin") {
            window.open("https://www.linkedin.com/in/"+username, '_blank')
        } else if (url_social==="Telegram") {
            window.open("https://t.me/"+username, '_blank')
        } else if (url_social==="Pinterest") {
            window.open("https://pinterest.com/"+username, '_blank')
        } else if (url_social==="Line") {
            window.open("https://line.me/"+username, '_blank')
        } else  if (url_social==="Snapchat") {
            window.open("https://www.snapchat.com/add/"+username, '_blank')
        } else if (url_social==="Spotify") {
            window.open("https://open.spotify.com/"+username, '_blank')
        } else if (url_social==="Web") {
            window.open("https://"+username, '_blank')
        }    
        return 
    }

    return (
        <div className='filtro-fondo-influencer' style={{ width: '100%', height: '100vh', paddingBottom: '10%', background: 'url('+valla.photo_tv+')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backdropFilter: 'blur(50px)', overflow: 'hidden' }}>

        <div className='row' style={{ height: '100vh', margin: 0 }}>

            <div style={{ margin: 0, padding: 0, zIndex: 99, textAlign: 'center', display: 'flex' }} className='col-xl-6 col-lg-6 col-md-6 col-sm-12' onClick={handlewModalImageShow}>
                <img 
                    height={widthImageProfile} 
                    width={widthImageProfile} 
                    src={valla.photo_tv} 
                    style={{ borderRadius: '50%', objectFit: 'cover', margin: 'auto' }}
                    alt={'Imagen pautty ' + valla.name} 
                    onError={(e) => {
                        e.target.src = valla.photo_tv+"?new="+Math.random();
                        e.target.alt = 'Imagen no encontrada';
                      }}
                    />
            </div>

            <div style={{ maxWidth: 800, zIndex: 99, display: isDispositivoMovil?'':'grid', placeItems: 'center' }} className='col-xl-6 col-lg-6 col-md-6 col-sm-12 content-text-influencer' >
            
                <div style={{ paddingLeft: 30, paddingRight: 30, height: 'auto', margin: 'auto', width: '100%' }}>
                    
                  <div style={{ margin: 'auto',  width: '100%' }}>
                  <div style={{ paddingTop: 15, paddingBottom: 10, textAlign: 'center', margin: 'auto' }}>
                        {valla.valid_screen ?
                            <h2>{valla.name} {' '}
                                <span title='Verificado por el equipo de Pautty'>
                                    <BsFillPatchCheckFill color='#49ADF4' />
                                </span>
                            </h2>
                            :
                            <h2>{valla.name}</h2>
                        }
                        {valla.username!==''&&
                            <p style={{ color: '#292929' }}>@{valla.username} </p>
                        }

                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: 13 }}>
                        {(valla.time_price !== '' && valla.time_price !== undefined && valla.price !== "" && valla.price !== "0") &&
                            <div style={{ border: '1px solid #c0c0c0', borderRadius: 5, padding: 5, marginRight: 5, background: '#c0c0c0' }}>
                                <span className='text-price-extra-home' style={{ color: '#292929' }}>{config.list_code_plan_payments_pautty[parseInt(valla.time_price)].name}</span><br></br>
                                <span className='price-card-home' style={{ color: '#000000', fontWeight: 'bold' }}>${dollarIndianLocale.format(valla.price)}</span>
                            </div>
                        }

                        {(valla.time_price_2 !== '' && valla.time_price_2 !== undefined && valla.price_2 !== "" && valla.price_2 !== "0") &&
                            <div style={{ border: '1px solid #c0c0c0', borderRadius: 5, padding: 5, background: '#c0c0c0' }}>
                                <span className='text-price-extra-home' style={{ color: '#292929' }}>{config.list_code_plan_payments_pautty[parseInt(valla.time_price_2)].name}</span><br></br>
                                <span className='price-card-home' style={{ color: '#000000', fontWeight: 'bold' }}>${dollarIndianLocale.format(valla.price_2)}</span>
                            </div>
                        }
                    </div>

                    </div>

                    <div className='row'>

                        <Modal
                            show={showModalImage}
                            dialogClassName="modal-90w"
                            centered
                            onHide={handlewModalImageClose}>
                            <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                                <Modal.Title style={{ width: '100%', textAlign: 'center' }}>{valla.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img src={valla.photo_tv} alt={valla.name} width={'100%'} />
                            </Modal.Body>
                        </Modal>

                       
                        <div className='col-12' style={{ marginBottom: '10%', maxHeight: '50vh', overflowY: 'auto' }}>
                                {listElementsScreen.length >0 ?
                                    <div style={{ margin: 'auto'}}>
                                        {listElementsScreen.map((item, index)=>{
                                            return <GetIconSocialMedia key={index} nameSocial={item} />
                                        })} 
                                    </div>
                                    :
                                    <div style={{textAlign:'center'}}> <span>Sin información registrada</span> </div>
                                }

                        </div>
                    </div>
                  </div>


                </div>

            </div>

            <Offcanvas show={showOptionOpenAPP} onHide={handleCloseOptionOpenAPP} placement='bottom'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><b>¿Desde dónde quieres acceder?</b></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='buttons-modal-options-link'>
                        <div>
                            <button onClick={()=>{
                                getUrlSocialMedia(urlSocialTemp, nameSocialTemp);
                            }}><BsPhone size={20} /> Abrir en la Aplicación</button>
                        </div>
                        <div onClick={()=>{
                                getUrlSocialMediaNavegador(urlSocialTemp, nameSocialTemp);
                            }}>
                            <button><FaExternalLinkAlt size={20} /> Abrir en el Navegador</button>
                        </div>
                    </div>
                </Offcanvas.Body>
                </Offcanvas>
                
        </div>

            <ToastContainer />
        </div>
    )

}

export default ShowInfluencer;